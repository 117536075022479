/* CLIENTs */

export const GET_CLIENTS_DETAIL = "GET_CLIENTS_DETAIL"
export const GET_CLIENTS_DETAIL_SUCCESS = "GET_CLIENTS_DETAIL_SUCCESS"
export const GET_CLIENTS_DETAIL_FAIL = "GET_CLIENTS_DETAIL_FAIL"


export const CREATE_CLIENT_IMPORTANT_DATE = "CREATE_CLIENT_IMPORTANT_DATE"
export const CREATE_CLIENT_IMPORTANT_DATE_SUCCESS = "CREATE_CLIENT_IMPORTANT_DATE_SUCCESS"
export const CREATE_CLIENT_IMPORTANT_DATE_FAIL = "CREATE_CLIENT_IMPORTANT_DATE_FAIL"

export const GET_CLIENT_IMPORTANT_DATE = "GET_CLIENT_IMPORTANT_DATE"
export const GET_CLIENT_IMPORTANT_DATE_SUCCESS = "GET_CLIENT_IMPORTANT_DATE_SUCCESS"
export const GET_CLIENT_IMPORTANT_DATE_FAIL = "GET_CLIENT_IMPORTANT_DATE_FAIL"


export const DELETE_CLIENT_IMPORTANT_DATE = "DELETE_CLIENT_IMPORTANT_DATE"
export const DELETE_CLIENT_IMPORTANT_DATE_SUCCESS = "DELETE_CLIENT_IMPORTANT_DATE_SUCCESS"
export const DELETE_CLIENT_IMPORTANT_DATE_FAIL = "DELETE_CLIENT_IMPORTANT_DATE_FAIL"


export const CREATE_CLIENT_CHILD = "CREATE_CLIENT_CHILD"
export const CREATE_CLIENT_CHILD_SUCCESS = "CREATE_CLIENT_CHILD_SUCCESS"
export const CREATE_CLIENT_CHILD_FAIL = "CREATE_CLIENT_CHILD_FAIL"

export const GET_CLIENT_CHILD = "GET_CLIENT_CHILD"
export const GET_CLIENT_CHILD_SUCCESS = "GET_CLIENT_CHILD_SUCCESS"
export const GET_CLIENT_CHILD_FAIL = "GET_CLIENT_CHILD_FAIL"

export const DELETE_CLIENT_CHILD = "DELETE_CLIENT_CHILD"
export const DELETE_CLIENT_CHILD_SUCCESS = "DELETE_CLIENT_CHILD_SUCCESS"
export const DELETE_CLIENT_CHILD_FAIL = "DELETE_CLIENT_CHILD_FAIL"


export const UPDATE_CLIENT_PROFILE = "UPDATE_CLIENT_PROFILE"
export const UPDATE_CLIENT_PROFILE_SUCCESS = "UPDATE_CLIENT_PROFILE_SUCCESS"
export const UPDATE_CLIENT_PROFILE_FAIL = "UPDATE_CLIENT_PROFILE_FAIL"



export const UPDATE_CLIENT_PRIMARY_CONTACT = "UPDATE_CLIENT_PRIMARY_CONTACT"
export const UPDATE_CLIENT_PRIMARY_CONTACT_SUCCESS = "UPDATE_CLIENT_PRIMARY_CONTACT_SUCCESS"
export const UPDATE_CLIENT_PRIMARY_CONTACT_FAIL = "UPDATE_CLIENT_PRIMARY_CONTACT_FAIL"


export const UPDATE_CLIENT_SECONDARY_CONTACT = "UPDATE_CLIENT_SECONDARY_CONTACT"
export const UPDATE_CLIENT_SECONDARY_CONTACT_SUCCESS = "UPDATE_CLIENT_SECONDARY_CONTACT_SUCCESS"
export const UPDATE_CLIENT_SECONDARY_CONTACT_FAIL = "UPDATE_CLIENT_SECONDARY_CONTACT_FAIL"