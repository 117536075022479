import { all, fork } from "redux-saga/effects"

//public
//import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
//import ForgetSaga from "./auth/forgetpwd/saga"
//import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import projectsSaga from "./projects/saga"
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import messageSaga from "./message/saga"
import documentSaga from "./document/saga"
import prefrencesSaga from "./prefrences/saga"
import showingSaga from "./showing/saga"
import appointmentaga from "./appointment/saga"
import addListingSaga from "./addlisting/saga"
import clientSaga from "./profile/saga"

export default function* rootSaga() {
  yield all([
    //public
    //fork(AccountSaga),
    fork(AuthSaga),
    //fork(ForgetSaga),
    
    fork(LayoutSaga),   
    fork(dashboardSaga),
    fork(messageSaga),
    fork(documentSaga),
    fork(prefrencesSaga),
    fork(projectsSaga),
    fork(showingSaga),
    fork(appointmentaga),
    fork(addListingSaga),
    fork(clientSaga)
  ])
}
