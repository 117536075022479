import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify";

import { GET_CLIENTS_MESSAGE, POST_CLIENT_MESSAGE } from "./actionTypes"

import { getClientMessageAPI, postClientMessageAPI } from '../../helpers/backend_helper'

import { getClientMessageFail, getClientMessageSuccess, postClientMessageFail, postClientMessageSuccess } from "./actions"
import { take } from "lodash";


function* submitClientMessage({ payload: { message, history } }) {
  try {
    //console.log(message);
    toast.loading("Please wait...")
    const response = yield call(postClientMessageAPI, {
      messageFromId: parseInt(message.messageFromId),
      messageToId: parseInt(message.messageToId),
      messageFrom: message.messageFrom,
      messageTo: message.messageTo,
      messageText: message.addMessage,
      agentId: parseInt(message.agentId)

    })
    yield put(postClientMessageSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Message Added");
      const response = yield call(getClientMessageAPI, parseInt(message.messageFromId))
      yield put(getClientMessageSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(postClientMessageFail(error))
  }
}

function* fetchClientMessage({ clientId }) {
  try {
    const response = yield call(getClientMessageAPI, clientId)
    yield put(getClientMessageSuccess(response))
  } catch (error) {
    yield put(getClientMessageFail(error))
  }
}





function* messageSaga() {

  yield takeEvery(POST_CLIENT_MESSAGE, submitClientMessage)
  yield takeEvery(GET_CLIENTS_MESSAGE, fetchClientMessage)
 

}


export default messageSaga
