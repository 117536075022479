import { GET_CLIENT_RECENT_ACTIVITIES_FAIL, GET_CLIENT_RECENT_ACTIVITIES_SUCCESS, GET_CLIENT_UPCOMING_ACTIVITIES_FAIL, GET_CLIENT_UPCOMING_ACTIVITIES_SUCCESS } from "./actionTypes";


const INIT_STATE = {
    ractivities: [],
    uactivities:[]
};

const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CLIENT_RECENT_ACTIVITIES_SUCCESS:
            return {
                ...state,
                ractivities: action.payload,
            }
        case GET_CLIENT_RECENT_ACTIVITIES_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_CLIENT_UPCOMING_ACTIVITIES_SUCCESS:
            return {
                ...state,
                uactivities: action.payload,
            }
        case GET_CLIENT_UPCOMING_ACTIVITIES_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state;
    }
}


export default Dashboard;