//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_CLIENT_LOGIN = "https://rscoreapi.azurewebsites.net/api/user/login"

export const POST_CLIENT_MESSAGE_API_URL = "https://rscoreapi.azurewebsites.net/api/ClientMessage"

export const GET_CLIENT_MESSAGE_API_URL = "https://rscoreapi.azurewebsites.net/api/ClientMessage/allClient"

export const POST_CLIENT_DOCUMENT_API_URL = "https://rscoreapi.azurewebsites.net/api/ClientDocument"

export const GET_CLIENT_DOCUMENT_API_URL = "https://rscoreapi.azurewebsites.net/api/ClientDocument/allClient"

export const GET_CLIENT_PREF_QUEST_API_URL  ="https://rscoreapi.azurewebsites.net/api/HomePreferenceAnswer/allforagenttype"

export const GET_ALL_PROJECT_API_URL  ="https://rscoreapi.azurewebsites.net/api/ProjectExt/allforagent"

export const GET_PROJECT_DETAIL_API_URL = "https://rscoreapi.azurewebsites.net/api/ProjectExt/allfulldetailforagent"

export const POST_PROJECT_INTEREST_API_URL = "https://rscoreapi.azurewebsites.net/api/ContactProjectInterest"

export const GET_PROJECT_INTEREST_API_URL = "https://rscoreapi.azurewebsites.net/api/ContactProjectInterest/allforcontact"

export const POST_CLIENT_SELLER_SHOWING_API_URL = "https://rscoreapi.azurewebsites.net/api/ClientSellerShowing"
export const GET_CLIENT_SELLER_SHOWING_API = "https://rscoreapi.azurewebsites.net/api/ClientSellerShowing/all"
export const DELETE_CLIENT_SHOWING_API = "https://rscoreapi.azurewebsites.net/api/ClientSellerShowing/"
export const UPDATE_CLIENT_SELLER_SHOWING_API_URL = "https://rscoreapi.azurewebsites.net/api/ClientSellerShowing"

export const GET_CLIENT_SELLER_LISTING_API = "https://rscoreapi.azurewebsites.net/api/ClientListing/all"
export const POST_CLIENT_APPOINTMENT_API =  "https://rscoreapi.azurewebsites.net/api/ClientAppointment"
export const GET_CLIENT_APPOINTMENT_API = "https://rscoreapi.azurewebsites.net/api/ClientAppointment/all"
export const DELETE_CLIENT_APPOINTMENT_API = "https://rscoreapi.azurewebsites.net/api/ClientAppointment/"


export const POST_BUYER_DESTINATION_API = "https://rscoreapi.azurewebsites.net/api/ClientBuyerImportantDestinationExt/bulkadd"


export const GET_PROJECT_FEATURE_API_URL ="https://rscoreapi.azurewebsites.net/api/ProjectFeature/all"
export const GET_PROJECT_LINK_API_URL       = "https://rscoreapi.azurewebsites.net/api/ProjectLink/all"
export const GET_PROJECT_GALLERY_API_URL = "https://rscoreapi.azurewebsites.net/api/ProjectGallery/allproject"




export const POST_CLIENT_ADD_LISTING_API = "https://rscoreapi.azurewebsites.net/api/AgentClientPotentialListing"
export const GET_CLIENT_ADD_LISTING_API = "https://rscoreapi.azurewebsites.net/api/AgentClientPotentialListing/all"


export const DELETE_CLIENT_DOCUMENT_API ="https://rscoreapi.azurewebsites.net/api/ClientDocument/"
export const UPDATE_CLIENT_DOCUMENT_API ="https://rscoreapi.azurewebsites.net/api/ClientDocument"

export const UPDATE_CLIENT_APPOINTMENT_API = "https://rscoreapi.azurewebsites.net/api/ClientAppointment"

export const GET_CLIENT_DETAIL_API =        "https://rscoreapi.azurewebsites.net/api/ClientExt/"

export const  CREATE_CLIENT_IMPORTANT_DATE_URL ="https://rscoreapi.azurewebsites.net/api/ClientImportantDate"
export const  GET_CLIENT_IMPORTANT_DATE_URL ="https://rscoreapi.azurewebsites.net/api/ClientImportantDate/all"
export const DELETE_CLIENT_IMPORTANT_DATE_URL = "https://rscoreapi.azurewebsites.net/api/ClientImportantDate/"
export const  CREATE_CLIENT_CHILD_URL ="https://rscoreapi.azurewebsites.net/api/ClientChild"
export const  GET_CLIENT_CHILD_URL ="https://rscoreapi.azurewebsites.net/api/ClientChild/all"
export const UPDATE_CLIENT_PROFILE_API = "https://rscoreapi.azurewebsites.net/api/ClientExt"
export const UPDATE_CLIENT_PRIMARY_CONTACT_API = "https://rscoreapi.azurewebsites.net/api/ClientContact/update_client_primary_contact"
export const UPDATE_CLIENT_SECONDARY_CONTACT_API = "https://rscoreapi.azurewebsites.net/api/ClientContact/update_client_secondary_contact"

export const CREATE_NEW_CLIENT_URL = "https://rscoreapi.azurewebsites.net/api/ClientExt"

export const GET_CLIENT_UPCOMING_ACTIVITIES_API = "https://rscoreapi.azurewebsites.net/api/AgentClient/allagentclientupcomingactivities"
export const GET_CLIENT_RECENT_ACTIVITIES_API = "https://rscoreapi.azurewebsites.net/api/AgentClient/allagentclientrecentactivities"


export const POST_HOME_DESTINATION_API = "https://rscoreapi.azurewebsites.net/api/ClientBuyerHomePreferenceAnswer"
export const  GET_HOME_DESTINATION_API = "https://rscoreapi.azurewebsites.net/api/ClientBuyerHomePreferenceAnswer/all"
export const UPDATE_HOME_DESTINATION_API = "https://rscoreapi.azurewebsites.net/api/ClientBuyerHomePreferenceAnswer"
export const  DELETE_HOME_DESTINATION_API = "https://rscoreapi.azurewebsites.net/api/ClientBuyerHomePreferenceAnswer/"


export const POST_IMP_DESTINATION_API = "https://rscoreapi.azurewebsites.net/api/ClientBuyerImportantDestination"
export const GET_IMP_DESTINATION_API = "https://rscoreapi.azurewebsites.net/api/ClientBuyerImportantDestination/all"


export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"
export const ADD_NEW_PROJECT = "/add/product"
export const UPDATE_PROJECT = "/update/product"
export const DELETE_PROJECT = "/delete/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"