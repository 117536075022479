export const GET_BUY_PREFRENCES_QUEST = "GET_BUY_PREFRENCES_QUEST"
export const GET_BUY_PREFRENCES_QUEST_SUCCESS = "GET_BUY_PREFRENCES_QUEST_SUCCESS"
export const GET_BUY_PREFRENCES_QUEST_FAIL = "GET_BUY_PREFRENCES_QUEST_FAIL"

export const CREATE_BUY_DESTINATION = "CREATE_BUY_DESTINATION"
export const CREATE_BUY_DESTINATION_SUCCESS = "GET_BUY_DESTINATION_SUCCESS"
export const CREATE_BUY_DESTINATION_FAIL = "GET_BUY_DESTINATION_FAIL"



export const CREATE_HOME_DESTINATION = "CREATE_HOME_DESTINATION"
export const CREATE_HOME_DESTINATION_SUCCESS = "GET_BUY_DESTINATION_SUCCESS"
export const CREATE_HOME_DESTINATION_FAIL = "GET_BUY_DESTINATION_FAIL"

export const GET_HOME_DESTINATION = "GET_HOME_DESTINATION"
export const GET_HOME_DESTINATION_SUCCESS = "GET_HOME_DESTINATION_SUCCESS"
export const GET_HOME_DESTINATION_FAIL = "GET_HOME_DESTINATION_FAIL"

export const UPDATE_HOME_DESTINATION = "UPDATE_HOME_DESTINATION"
export const UPDATE_HOME_DESTINATION_SUCCESS = "UPDATE_HOME_DESTINATION_SUCCESS"
export const UPDATE_HOME_DESTINATION_FAIL = "UPDATE_HOME_DESTINATION_FAIL"

export const DELETE_HOME_DESTINATION = "DELETE_HOME_DESTINATION"
export const DELETE_HOME_DESTINATION_SUCCESS = "DELETE_HOME_DESTINATION_SUCCESS"
export const DELETE_HOME_DESTINATION_FAIL = "DELETE_HOME_DESTINATION_FAIL"


export const CREATE_IMP_DESTINATION = "CREATE_IMP_DESTINATION"
export const CREATE_IMP_DESTINATION_SUCCESS = "CREATE_IMP_DESTINATION_SUCCESS"
export const CREATE_IMP_DESTINATION_FAIL = "CREATE_IMP_DESTINATION_FAIL"

export const GET_IMP_DESTINATION = "GET_IMP_DESTINATION"
export const GET_IMP_DESTINATION_SUCCESS = "GET_IMP_DESTINATION_SUCCESS"
export const GET_IMP_DESTINATION_FAIL = "GET_IMP_DESTINATION_FAIL"

export const UPDATE_IMP_DESTINATION = "UPDATE_IMP_DESTINATION"
export const UPDATE_IMP_DESTINATION_SUCCESS = "UPDATE_IMP_DESTINATION_SUCCESS"
export const UPDATE_IMP_DESTINATION_FAIL = "UPDATE_IMP_DESTINATION_FAIL"

export const DELETE_IMP_DESTINATION = "DELETE_IMP_DESTINATION"
export const DELETE_IMP_DESTINATION_SUCCESS = "DELETE_IMP_DESTINATION_SUCCESS"
export const DELETE_IMP_DESTINATION_FAIL = "DELETE_IMP_DESTINATION_FAIL"













