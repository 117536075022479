import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { isEmpty, map, size } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import {postHomePref, getHomePref, updateHomePref } from "../../store/prefrences/actions"
import prefImg from "../../assets/images/rsicons/prefrence.png"
import editIcon from "../../assets/images/icons/edit.png"
import DeleteModal from "../../components/Common/DeleteModal";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Button,
    Collapse,
    Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"


class homePref extends Component {
    constructor(props) {
        super(props)
        this.state = {
          homeprefrence:''
        }

        this.handleHomePrefSubmit = this.handleHomePrefSubmit.bind(this);
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggleBackdrop = this.toggleBackdrop.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }
    toggleBackdrop() {
      this.setState({ isBackdrop: !this.state.isBackdrop });
    }
    toggleDeleteModal = () => {
      this.setState(prevState => ({
        deleteModal: !prevState.deleteModal,
      }));
    };
    onClickDelete = (homeprefrence) => {
      this.setState({ homeprefrence: homeprefrence });
      this.setState({ deleteModal: true });
    };
  
    handleDeleteHomePref = () => {
      const { onDeleteHomePref } = this.props;
      const { homeprefrence } = this.state;
    
      if (homeprefrence.id !== undefined) {
        onDeleteHomePref(homeprefrence);
        this.setState({ deleteModal: false });
      }
    };
    toggle() {
      this.setState(prevState => ({
        isRight: !prevState.isRight
      }));
    }
    handleHomePrefSubmit(value){
      //console.log(value)
      this.props.postHomePref(value, this.props.history);
  
    }
    componentDidMount() {
      const { onGetHomePref } = this.props;
      onGetHomePref(localStorage.getItem('userId'));
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { homeprefrences } = this.props;
    if (!isEmpty(homeprefrences) && size(prevProps.homeprefrences) !== size(homeprefrences)) {
      this.setState({ homeprefrences: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ homeprefrence: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const homeprefrence = arg;
    this.setState({
      homeprefrence: {
        id: homeprefrence.id,
        clientBuyerHomePreferenceAnswerId: homeprefrence.clientBuyerHomePreferenceAnswerId,
        homePreferenceAnswerId: homeprefrence.homePreferenceAnswerId,
        homePreferenceTitle:homeprefrence.homePreferenceTitle,
        answersOption:homeprefrence.answersOption,
        preference:homeprefrence.preference,
        clientId: homeprefrence.clientId,
        isActive:homeprefrence.isActive
      },
      isEdit: true,
    });
    this.toggle();
  };
    render() {
        const {homeprefrences } = this.props;
        const { isEdit, deleteModal } = this.state;
        const homeprefrence = this.state.homeprefrence;
        return (
            <React.Fragment>
              {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null} 
              <ToastContainer autoClose={2000} />
              <DeleteModal
              show={deleteModal}
              onDeleteClick={this.handleDeleteHomePref}
              onCloseClick={() => this.setState({ deleteModal: false })}
            />
                <div className="page-content">
          <MetaTags>
            <title>Preferences | Realty Space</title>
          </MetaTags>
          <Container fluid>
             {/* <Breadcrumbs
              title={this.props.t("Buyer")}
              breadcrumbItem={this.props.t("Home Preferences")}
            /> */}
                {/* <Row>
                    <Col sm="12">
                    <Formik
                  enableReinitialize={true}
                  initialValues={{
                    homePreferenceTitle: (this.state && this.state.homePreferenceTitle) || "",
                    answersOption: (this.state && this.state.answersOption) || "",
                    clientId: localStorage.getItem('userId')

                  }}
                  validationSchema={Yup.object().shape({
                    homePreferenceTitle: Yup.string().required("This is Required"),
                    answersOption: Yup.string().required("This is Required"),
                    
                  })}

                  onSubmit={this.handleHomePrefSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card className="mini-stats-wid"> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                   <Row>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="homePreferenceTitle">
                              {this.props.t("Feature")}
                            </Label>
                            <Field
                              name="homePreferenceTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.homePreferenceTitle && touched.homePreferenceTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="homePreferenceTitle"
                            />
                                <ErrorMessage
                                  name="homePreferenceTitle"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="answersOption">
                              {this.props.t("Feature Value")}
                            </Label>
                            <Field
                              name="answersOption"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.answersOption && touched.answersOption
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                                <ErrorMessage
                                  name="answersOption"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="preference">
                              {this.props.t("Preference")}
                            </Label>
                            <Field as="select" name="preference" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="Required">Required</option>
                                <option value="Nice to have">Nice to have</option>
                                <option value="Not Required">Not Required</option>
                            </Field>
                          </FormGroup>
                        </Col>         
                       <Col sm="2">  <div className="clearfix"><button style={{ marginTop: 27}} type="submit" className="btn btn-primary w-md">Add</button></div></Col>
                    </Row>
                  
             
             </CardBody>
                </Card>
                
                </Form>
                  )}
                </Formik>
                    </Col>
                </Row> */}

                {/* <Row>
				          <Col sm="12">
					          <Card className="mini-stats-wid"> 
                      <CardBody>
                        <button className="btn btn-primary ">Upload Image</button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> */}
                {/* <Row>
                    <Col sm="12">
                    <Formik
                  enableReinitialize={true}
                  initialValues={{
                    homePreferenceTitle: (this.state && this.state.homePreferenceTitle) || "",
                    answersOption: (this.state && this.state.answersOption) || "",
                    clientId: localStorage.getItem('userId')

                  }}
                  validationSchema={Yup.object().shape({
                    // homePreferenceTitle: Yup.string().required("This is Required"),
                    // answersOption: Yup.string().required("This is Required"),
                    
                  })}

                  onSubmit={ console.log('file upload')}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card className="mini-stats-wid"> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                   <Row>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="upload">
                              {this.props.t("Upload Files")}
                            </Label>
                            <Field
                              name="upload"
                              onChange={handleChange}                            
                              type="file"
                              className={
                                "form-control" 
                              }
                              id="upload"
                            />
                          </FormGroup>
                        </Col>

                       <Col sm="2">  <div className="clearfix"><button style={{ marginTop: 27}} type="submit" className="btn btn-primary w-md">Upload</button></div></Col>
                    </Row>
                  
             
             </CardBody>
                </Card>
                
                </Form>
                  )}
                </Formik>
                    </Col>
                </Row> */}



                <Row>
                  <Col sm="12">
                    <Card className="mini-stats-wid"> 
                      <CardBody>
                        <Row>
                          <Col sm="6">
                          <h4 >Manage <small> Property # 11134742sd</small></h4>
                          <hr/>
                        <div className="table-responsive">
                            <table className="table mb-0 table">
                            <thead>
                              <tr>
                              
                              <th>Name</th>
                              <th style={{maxWidth:'50px'}}>Value</th>
                              {/* <th>Preference</th> */}
                              <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                            {map(homeprefrences, (homeprefrence, appoint) => (
                                <tr key={"_app_" + appoint}>
                                  <td>{homeprefrence.homePreferenceTitle}</td>
                                  <td style={{maxWidth:'50px'}}><input className='form-control text-center' defaultValue={homeprefrence.answersOption} /></td>
                                  {/* <td>{homeprefrence.preference}</td> */}
                                  
                                  
                                  <td>
                                  <UncontrolledDropdown direction="up">
                                                    <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                      <img className="tab-img" src={editIcon} /> 
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                      <DropdownItem onClick={() => this.toggleRightCanvas(homeprefrence)} href="#">Edit</DropdownItem>
                                                      <DropdownItem onClick={() => this.onClickDelete(homeprefrence)}  href="#">Delete</DropdownItem>
                                                    
                                                      
                                                    </DropdownMenu>
                                                  </UncontrolledDropdown>
                                  
                                  </td>
                                </tr> 
                                        
                            ))}
                            <tr>
                                  <td></td>
                                  <td><Button className="btn btn-active">Save</Button></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>  
                              
                            </tbody>
                            </table>

                          
                          </div> 
                        </Col>
                      </Row>
                    </CardBody>
                    </Card>
                  </Col>
                </Row>
      <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
                Update
              </OffcanvasHeader>
              <OffcanvasBody>
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: homeprefrence.id,
                    clientBuyerHomePreferenceAnswerId: homeprefrence.clientBuyerHomePreferenceAnswerId,
                    homePreferenceAnswerId: homeprefrence.homePreferenceAnswerId,
                    homePreferenceTitle:homeprefrence.homePreferenceTitle,
                    answersOption:homeprefrence.answersOption,
                    preference:homeprefrence.preference,
                    clientId: homeprefrence.clientId,
                    isActive:homeprefrence.isActive

                  }}
                  validationSchema={Yup.object().shape({
                    homePreferenceTitle: Yup.string().required("This is Required"),
                    answersOption: Yup.string().required("This is Required"),
                    
                  })}

                  onSubmit={values => {

                    if (isEdit) {
                      const updateData = {
                        id: values.id,
                        clientBuyerHomePreferenceAnswerId: values.clientBuyerHomePreferenceAnswerId,
                        homePreferenceAnswerId: values.homePreferenceAnswerId,
                        homePreferenceTitle:values.homePreferenceTitle,
                        answersOption:values.answersOption,
                        preference:values.preference,
                        clientId: values.clientId,
                        isActive:values.isActive
                      };
                      onUpdateHomePref(updateData);
                    } else {

                    }
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             
              
                   <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="homePreferenceTitle">
                              {this.props.t("Feature")}
                            </Label>
                            <Field
                              name="homePreferenceTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.homePreferenceTitle && touched.homePreferenceTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="homePreferenceTitle"
                            />
                                <ErrorMessage
                                  name="homePreferenceTitle"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="answersOption">
                              {this.props.t("Feature Value")}
                            </Label>
                            <Field
                              name="answersOption"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.answersOption && touched.answersOption
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                                <ErrorMessage
                                  name="answersOption"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="preference">
                              {this.props.t("Preference")}
                            </Label>
                            <Field as="select" name="preference" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="Required">Required</option>
                                <option value="Nice to have">Nice to have</option>
                                <option value="Not Required">Not Required</option>
                            </Field>
                          </FormGroup>
                        </Col>         
                       <Col sm="12">  <div className="clearfix"><button style={{ marginTop: 27}} type="submit" className="btn btn-primary w-md">Update</button></div></Col>
                    </Row>
                  
             
            
                
                </Form>
                  )}
                </Formik>
              </OffcanvasBody>
            </Offcanvas>
            </Container>
            </div>
            </React.Fragment>
        )
    }
}
homePref.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    postHomePref:PropTypes.func,
    onGetHomePref:PropTypes.func,
    homeprefrences:PropTypes.array,
    loading:PropTypes.object,
    onDeleteHomePref:PropTypes.func,
    onUpdateHomePref:PropTypes.func


}

const mapStateToProps = ({ Prefrences }) => (
    {
      homeprefrences : Prefrences.homeprefrences,
      loading:Prefrences.loading

    })

const mapDispatchToProps = dispatch => ({
    postHomePref: (data) => dispatch(postHomePref(data)),
    onGetHomePref: (clientId) => dispatch(getHomePref(clientId))
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(homePref))