import { CREATE_BUY_DESTINATION_FAIL, CREATE_BUY_DESTINATION_SUCCESS, CREATE_HOME_DESTINATION_FAIL, CREATE_HOME_DESTINATION_SUCCESS, DELETE_HOME_DESTINATION_FAIL, DELETE_HOME_DESTINATION_SUCCESS, GET_BUY_PREFRENCES_QUEST_FAIL, GET_BUY_PREFRENCES_QUEST_SUCCESS, GET_HOME_DESTINATION, GET_HOME_DESTINATION_FAIL, GET_HOME_DESTINATION_SUCCESS, GET_IMP_DESTINATION, GET_IMP_DESTINATION_FAIL, GET_IMP_DESTINATION_SUCCESS, UPDATE_HOME_DESTINATION_FAIL, UPDATE_HOME_DESTINATION_SUCCESS } from "./actionTypes"

const initialState = {

  error: "",
  loading: true,
  questions: [],
  homeprefrences: [],
  impdestinations:[]
}

const Prefrences = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUY_PREFRENCES_QUEST_SUCCESS:
      return {
        ...state,
        questions: action.payload,
      }
    case GET_BUY_PREFRENCES_QUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case CREATE_BUY_DESTINATION_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case CREATE_BUY_DESTINATION_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
      case CREATE_HOME_DESTINATION_SUCCESS:
        state = {
          ...state,
          loading: false,
        }
        break
      case CREATE_HOME_DESTINATION_FAIL:
        state = { ...state, error: action.payload, loading: false }
        break
    case GET_HOME_DESTINATION:
      return {
        ...state,
        loading: true,
      }
    case GET_HOME_DESTINATION_SUCCESS:
      return {
        ...state,
        homeprefrences: action.payload,
        loading: false,
      }
    case GET_HOME_DESTINATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_HOME_DESTINATION_SUCCESS:
      return {
        ...state,
        homeprefrence: action.payload,
      }

    case DELETE_HOME_DESTINATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_HOME_DESTINATION_SUCCESS:
      return {
        ...state,
        homeprefrences: state.homeprefrences.map(homeprefrence =>
          homeprefrence.id.toString() === action.payload.id.toString()
            ? { homeprefrence, ...action.payload }
            : homeprefrence
        ),
      }

    case UPDATE_HOME_DESTINATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      case GET_IMP_DESTINATION:
        return {
          ...state,
          loading: true,
        }
      case GET_IMP_DESTINATION_SUCCESS:
        return {
          ...state,
          impdestinations: action.payload,
          loading: false,
        }
      case GET_IMP_DESTINATION_FAIL:
        return {
          ...state,
          error: action.payload,
        }
    default:
      state = { ...state }
      break

  }
  return state
}

export default Prefrences
