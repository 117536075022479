import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table
} from "reactstrap"
import img1 from "../../assets/images/sidebar/img1.jpg"
import primg1 from "../../assets/images/project/img1.jpg"
import primg2 from "../../assets/images/project/img2.jpg"
import primg3 from "../../assets/images/project/img3.jpg"
import primg4 from "../../assets/images/project/img4.jpg"

import appointment from "../../assets/images/rsicons/appointment.png"
import activiti from "../../assets/images/rsicons/activities.png"
import call from "../../assets/images/rsicons/call.png"
import notes from "../../assets/images/rsicons/notes.png"
import todo from "../../assets/images/rsicons/to-do.png"
import Breadcrumbs from "../../components/Common/Breadcrumb"
class myTransection extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Breadcrumbs
              title="Projects"
              breadcrumbItem="Transactions"
            />
            <Row>
                 <Col sm="12">
                   <Card>
                     <CardBody>
                     <div className="table-responsive">
                    <Table className="table align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          
                        <th scope="col"></th>
                         
                          <th scope="col">Project</th>
                         
                          <th scope="col">Suite #</th>
						              <th scope="col">Cost</th>
                          <th scope="col">Closing Date</th>
                          <th scope="col"></th>
                          
                         
                        </tr>
                      </thead>
                      <tbody>
                       <tr>
                       <td><img src={primg1} className="img-fluid" width="100" /></td>
                         <td>Liberty Market Tower</td>
                         <td>10</td>
                         <td>1,60,0000</td>
                         <td>Mar 30, 2022</td>
                         <td><Link to="/project-detail/34/10da1bf6-7e35-4c83-8435-53072057f347" className="btn btn-primary">Detail</Link></td>
                       </tr>
                      </tbody>
                    </Table>
                    
                  </div>

                     </CardBody>
                   </Card>
               

              </Col>
              
            </Row>


           
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default myTransection;
