export const CREATE_CLIENT_APPOINTMENT = "CREATE_CLIENT_APPOINTMENT"
export const CREATE_CLIENT_APPOINTMENT_SUCCESS = "CREATE_CLIENT_APPOINTMENT_SUCCESS"
export const CREATE_CLIENT_APPOINTMENT_FAIL = "CREATE_CLIENT_APPOINTMENT_FAIL"

export const GET_CLIENTS_APPOINTMENT = "GET_CLIENTS_APPOINTMENT"
export const GET_CLIENTS_APPOINTMENT_SUCCESS = "GET_CLIENTS_APPOINTMENT_SUCCESS"
export const GET_CLIENTS_APPOINTMENT_FAIL = "GET_CLIENTS_APPOINTMENT_FAIL"


export const DELETE_CLIENT_APPOINTMENT = "DELETE_CLIENT_APPOINTMENT"
export const DELETE_CLIENT_APPOINTMENT_SUCCESS = "DELETE_CLIENT_APPOINTMENT_SUCCESS"
export const DELETE_CLIENT_APPOINTMENT_FAIL = "DELETE_CLIENT_APPOINTMENT_FAIL"


export const UPDATE_CLIENT_APPOINTMENT = "UPDATE_CLIENT_APPOINTMENT"
export const UPDATE_CLIENT_APPOINTMENT_SUCCESS = "UPDATE_CLIENT_APPOINTMENT_SUCCESS"
export const UPDATE_CLIENT_APPOINTMENT_FAIL = "UPDATE_CLIENT_APPOINTMENT_FAIL"




