import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import 'react-rangeslider/lib/index.css'

import {  Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
const optionGroup = [
  {
    options: [
      { label: "Renting", value: "Renting" },
      { label: "Homeowner", value: "Homeowner" }
    ]
  }
]

const sqrFts = [
  {
    options:[
      { label: "< 1000", value:"< 1000"},
      { label: "1000-1500", value:"1000-1500"},
      { label: "1501-2000", value:"1501-2000"},
      { label: "2001-2500", value:"2001-2500"},
      { label: "2501-3000", value:"2501-3000"},
      { label: "3001-3500", value:"3001-3500"},
    ]
  }
]

const propIns = [
  {
    options:[
      { label: "Residential", value:"Residential"},
      { label: "Commercial", value:"Commercial"}
    ]
  }
]

const propDeal = [
  {
    options:[
      { label: "1", value:"1"},
      { label: "2-3", value:"2-3"},
      { label: "3-4", value:"3-4"},
      { label: "4-5", value:"4-5"},
      { label: "5-7", value:"5-7"},
      { label: "7-10", value:"7-10"},
      { label: "+10", value:"+10"}
    ]
  }
]

const propRisk = [
  {
    options:[
      { label: "Low Risk", value:"Low Risk"},
      { label: "Medium Risk", value:"Medium Risk"},
      { label: "High Risk", value:"High Risk"},
     
    ]
  }
]

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Collapse,
  FormGroup,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  Form,
  Input,
  
} from "reactstrap"
import profileImg from "../../assets/images/profile-img.png"
import classnames from "classnames"
import { AvField, AvForm } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

class myListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
      
    }

    
  }
 
  render() {
   
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>My Listings | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Buyer")}
              breadcrumbItem={this.props.t("My Properties of Interest")}
            />
         <Row className="mb20">
              
              <Col sm="12">
                <div className="pt-2">
                  
                  <div className="">
                    
                  
                   <Link
                      to="/create-listing"
                      className="btn btn-add  btn-md float-end"
                    >
                      Add Listing    {" "}<i className="mdi mdi-plus-box-outline ms-1"/>
                    </Link>

                    
                  </div>
                 
                </div>
              </Col>
            </Row>
          <Row>
              <Col sm="12">
                  <Card>
                      <CardBody>
                      <Link to="/my-compare"><button type="button" className="btn btn-danger w-md">Compare</button></Link>
                      <Form className="row gy-2 gx-3 float-end mb15">
         
         <div className="col-sm-auto">
           <Label className="visually-hidden" htmlFor="autoSizingInput">Name</Label>
           <Input type="text" className="form-control" id="autoSizingInput" placeholder="MLS#" />
         </div>

        
         
         <div className="col-sm-auto">
           <button type="submit" className="btn btn-primary w-md">Search</button>
         </div>
       </Form>
       <div className="clearfix"></div>
<div className="table-responsive">
<table className="table align-middle table-nowrap mb-0">
 <thead className="table-light">
   <tr>
   <th style={{ width: "20px" }}>
                    <div className="form-check font-size-16 align-middle">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheck1"
                      >
                        &nbsp;
                      </label>
                    </div>
                  </th>
     <th className="align-middle">MLS#</th>
     <th className="align-middle">Bathrooms</th>
     <th className="align-middle">Sq ft</th>
     
     <th className="align-middle">Price</th>
     <th className="align-middle">City</th>
     <th className="align-middle">Status</th>

     <th className="align-middle">Action</th>
     
   </tr>
 </thead>
 
</table>
</div>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
myListing.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(myListing))
