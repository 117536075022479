import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ProjectSlide from "./projectslide"
import { Carousel, CarouselControl, CarouselItem } from "reactstrap"
import { getProjectDetail, getProjectGallary, postProjectInterest, getProjectFeatureAction, getProjectInterest } from "../../store/projects/actions"
import {
  Container,
  Row,
  Col,
  Card, 
  CardBody
} from "reactstrap"

class ProjectDetail extends Component {
  constructor(props) {
    super(props)
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
    this.handleInterestSubmit = this.handleInterestSubmit.bind(this)
  }
  handleInterestSubmit(value){
    
    this.props.postProjectInterest(value, this.props.history);

  }
  
componentDidMount() {
  const { match: { params }, onGetProjectDetail, onGetProjectGallery,onGetProjectFeatureAction }= this.props;
  if (params && params.id && params.projectId) {
    onGetProjectDetail(params.projectId);
    //onGetProjectUUIDAction()
    onGetProjectGallery(params.id);
    onGetProjectFeatureAction(params.id);
  }else{
    //this.props.history.push('/my-project')
  }
}
onExiting() {
  this.animating = true
}

onExited() {
  this.animating = false
}

next() {
  if (this.animating) return
  const nextIndex =
    this.state.activeIndex === length - 1
      ? 0
      : this.state.activeIndex + 1
  this.setState({ activeIndex: nextIndex })
}

previous() {
  if (this.animating) return
  const nextIndex =
    this.state.activeIndex === 0
      ? length - 1
      : this.state.activeIndex - 1
  this.setState({ activeIndex: nextIndex })
}

goToIndex(newIndex) {
  if (this.animating) return
  this.setState({ activeIndex: newIndex })
}
  render() {
    const { projectDetails, interestDetail, images, projectFeature} = this.props;
    //console.log(projectDetails);
    //console.log("keys array = ", Object.keys(projectDetails.projectFeatures));
    const { activeIndex } = this.state

    const slides = images.map(image => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={"https://rscoreapi.azurewebsites.net/" + image.projectGalleryUrl}
        >
          <img
            src={"https://rscoreapi.azurewebsites.net/" + image.projectGalleryUrl}
            className="d-block img-fluid"
            alt=''
          />
        </CarouselItem>
      )
    })
    return (
      <React.Fragment>
         <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Breadcrumbs
              title={this.props.t("Project")}
              breadcrumbItem={this.props.t(projectDetails.projectName)}
            />
            <Row>
                <Col lg="8">
                <Card>
                <div className="mb-4 card-title-realty">Gallery</div>
                    <CardBody>
                   
                    <Row className="mt20">
                          <Col lg="12">
                          <Carousel
                          activeIndex={activeIndex}
                          next={this.next}
                          previous={this.previous}
                        >
                          {slides}
                          <CarouselControl
                            direction="prev"
                            directionText="Previous"
                            onClickHandler={this.previous}
                          />
                          <CarouselControl
                            direction="next"
                            directionText="Next"
                            onClickHandler={this.next}
                          />
        </Carousel>
                            
                          </Col>
                        
                      </Row>
                    </CardBody>
                </Card>
                <Card>
                <div className="mb-4 card-title-realty">{projectDetails.projectName}</div>
      <CardBody>
      
        <div className="d-flex">
          

          <div className="flex-grow-1 overflow-hidden">
            
            <p className="text-muted">  <i className="bx  bxs-map me-1 text-primary" /> 171 East Liberty Street, Toronto, ON</p>
            
          </div>
        </div>

        <h5 className="font-size-20 mt-4">Project Details :</h5>
        
        
       <div dangerouslySetInnerHTML={{__html:projectFeature.projectSummary}}></div>
        

        <Row className="task-dates">
          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx  bx-dollar-circle me-1 text-primary" /> Price From
              </h5>
              <p className="text-muted mb-0">$
              {projectDetails.minPriceRange != null
                    ? projectDetails.minPriceRange
                    : ' N/A'
                }
              
             </p>
            </div>
          </Col>

          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-dollar-circle me-1 text-primary" /> to over
                Date
              </h5>
              <p className="text-muted mb-0"> $
              {projectDetails.maxPriceRange != null
                    ? projectDetails.maxPriceRange
                    : ' N/A'
                }
              </p>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
                <Card className="ownbg">
                    <CardBody>
                       <Row className="mb20">
                                <Col sm="4">
                                    <h5><i className="bx bxs-building"></i> Condo </h5>
                                </Col>
                                <Col sm="4">
                                    <h5><i className="bx bx-exclude"></i> 
                                    {projectDetails.noOfUnits != null
                                        ? projectDetails.noOfUnits
                                        : ' N/A '
                                    } Units 
                                {projectDetails.noOfStories != null
                                        ?  " " + projectDetails.noOfStories
                                        : ' N/A '
                                    } Stories </h5>
                                </Col>
                                <Col sm="4">
                                    <h5><i className="bx bx-bed"></i> 
                                    {projectDetails.minBed != null
                                        ? projectDetails.minBed
                                        : ' N/A '
                                    }- {projectDetails.maxBed != null
                                      ? projectDetails.maxBed
                                      : ' N/A '
                                  } Bedrooms </h5>
                                </Col>

                                

                       </Row>
                       <Row>
                                <Col sm="4">
                                    <h5><i className=" bx bxs-calendar-plus"></i> 
Completed {projectDetails.complitionMonth != null
                                      ? projectDetails.complitionMonth
                                      : ' N/A '

                                  } {projectDetails.complitionYear != null
                                      ? projectDetails.complitionYear
                                      : ' N/A '
                                  } 
 </h5>
                                </Col>
                                <Col sm="4">
                                    <h5><i className="bx bx-area"></i> {projectDetails.minArea != null
                                      ? projectDetails.minArea
                                      : ' N/A '
                                  }  - {projectDetails.maxArea != null
                                    ? projectDetails.maxArea
                                    : ' N/A '
                                } SqFt </h5>
                                </Col>
                                <Col sm="4">
                                    <h5><i className=" bx bx-dollar"></i> {projectDetails.costPerSqFt != null
                                    ? projectDetails.costPerSqFt
                                    : ' N/A '
                                } per SqFt </h5>
                                </Col>

                                

                       </Row>
                    </CardBody>

                </Card>
                <Row>
                  <Col lg="6">
                <Card>
                <div className="mb-4 card-title-realty">Pricing & Fees</div>
                    <CardBody>
                    <div dangerouslySetInnerHTML={{__html:projectFeature.projectPriceFee}}></div>
                   
                    
                    </CardBody>
                </Card>
                </Col>
                <Col lg="6">
                <Card>
                <div className="mb-4 card-title-realty">Deposit Structure</div>
                    <CardBody>
                    <div dangerouslySetInnerHTML={{__html:projectFeature.projectDepositStructure}}></div>
                  
                    
 
                    </CardBody>
                </Card>
                </Col>
                </Row>    
                <Card>
                <div className="mb-4 card-title-realty">Current Incentive</div>
                    <CardBody>
                    <div dangerouslySetInnerHTML={{__html:projectFeature.projectCurrentIncentive}}></div>
                   
                    
 
                    </CardBody>
                </Card>

               
                </Col>
                <Col lg="4">
                            <Card>
                            <div className="mb-4 card-title-realty">Documents Links</div>
                                <CardBody>
                             
                                <div className="text-muted mt-4">
                               {projectDetails.projectLinks &&
                                  projectDetails.projectLinks.map((item, i) => (
                                 <p key={i}><a href={item.projectLinkUrl}><i className="mdi mdi-chevron-right text-primary me-1"></i> {item.projectLinkTitle}</a></p>
                                 ))} 
                                </div>
                                </CardBody>
                            </Card>


                                <div className="d-grid">
                                <Formik
                  enableReinitialize={true}
                  initialValues={{
                   
                    projectId: projectDetails.id,
                    contactId: 5,
                  }}
                  validationSchema={Yup.object().shape({
                    
                  })}

                  onSubmit={this.handleInterestSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                                <button type="submit" className="btn-lg btn btn-label btn-danger"><i className="bx bx-heart label-icon"></i>I AM INTERESTED</button>

                                </Form>
                  )}
                </Formik>
                                </div>
                        </Col>
            </Row>
            
           
            
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ProjectDetail.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match:PropTypes.object,
    onGetProjectDetail:PropTypes.func,
    projectDetails:PropTypes.object,
    postProjectInterest:PropTypes.func,
    interestDetail:PropTypes.object,
    onGetProjectInterest:PropTypes.func,
    images:PropTypes.array,
    onGetProjectGallery:PropTypes.func,
    projectFeature:PropTypes.object,
    onGetProjectFeatureAction:PropTypes.func,
  
  }
  const mapStateToProps = ({ projects }) => (
    {
      projectDetails:projects.projectDetails,
      interestDetail:projects.interestDetail,
      images: projects.images,
      projectFeature:projects.projectFeature
  
    })

    const mapDispatchToProps = dispatch => ({
      onGetProjectDetail:(projectId) => dispatch(getProjectDetail(projectId)),
      postProjectInterest: (data) => dispatch(postProjectInterest(data)),
      onGetProjectGallery:(projectId) => dispatch(getProjectGallary(projectId)),
      onGetProjectFeatureAction:(projectId) => dispatch(getProjectFeatureAction(projectId)),
      onGetProjectInterest:(contactId) => dispatch(getProjectInterest(contactId)),
       
      });
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectDetail))
