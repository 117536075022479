import { DELETE_CLIENTS_DOCUMENT_FAIL, DELETE_CLIENTS_DOCUMENT_SUCCESS, GET_CLIENTS_DOCUMENT, GET_CLIENTS_DOCUMENT_FAIL, GET_CLIENTS_DOCUMENT_SUCCESS, POST_CLIENT_DOCUMENT_FAIL, POST_CLIENT_DOCUMENT_SUCCESS, UPDATE_CLIENTS_DOCUMENT_FAIL, UPDATE_CLIENTS_DOCUMENT_SUCCESS } from "./actionTypes"

const initialState = {

  error: "",
  loading: true,
  documents: [],
}

const Documents = (state = initialState, action) => {
  switch (action.type) {

    case POST_CLIENT_DOCUMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case POST_CLIENT_DOCUMENT_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
      case GET_CLIENTS_DOCUMENT:
        return {
          ...state,
          loading: true,
        }
    case GET_CLIENTS_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: action.payload,
        loading: false,
      }
    case GET_CLIENTS_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      case UPDATE_CLIENTS_DOCUMENT_SUCCESS:
        return {
          ...state,
          documents: state.documents.map(document =>
            document.id.toString() === action.payload.id.toString()
              ? { document, ...action.payload }
              : document
          ),
        }
  
      case UPDATE_CLIENTS_DOCUMENT_FAIL:
        return {
          ...state,
          error: action.payload,
        }
        case DELETE_CLIENTS_DOCUMENT_SUCCESS:
          return {
            ...state,
            document: action.payload,
          }
    
        case DELETE_CLIENTS_DOCUMENT_FAIL:
          return {
            ...state,
            error: action.payload,
          }
    default:
      state = { ...state }
      break

  }
  return state
}

export default Documents
