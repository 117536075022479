import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify";

import { CREATE_CLIENT_ADD_LISTING, GET_CLIENT_ADD_LISTING } from "./actionTypes"

import { getClientAddListingUrl, postClientAddListing } from '../../helpers/backend_helper'

import { addClientAddListingFail, addClientAddListingSuccess, getClientAddListingFail, getClientAddListingSuccess } from "./actions"
import { take } from "lodash";
import { getClientAppointment } from "store/actions";

function* submitClientAddListing({ payload: { listing, history } }) {
  try {

    toast.loading("Please wait...")
    const response = yield call(postClientAddListing, {
      mlsId: listing.mlsId,
      mlsurl: listing.mlsurl,
      listingStatusId: 0,
      isInterested: true,
      clientId: parseInt(listing.clientId),
      agentId: parseInt(listing.agentId),
      addedBy: "client",
      listedPrice: parseInt(listing.listedPrice),
      listingCity: listing.listingCity,
      listingDate: new Date()

    })
    yield put(addClientAddListingSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Listing Added");
      const response = yield call(getClientAddListingUrl, listing.clientId, listing.agentId)
    yield put(getClientAddListingSuccess(response))
    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientAddListingFail(error))
  }
}


function* fetchClientAddListing({ payload: { clientId, agentId } }) {
  try {
    const response = yield call(getClientAddListingUrl, clientId, agentId)
    yield put(getClientAddListingSuccess(response))
  } catch (error) {
    yield put(getClientAddListingFail(error))
  }
}





function* addListingSaga() {
  
  yield takeEvery(CREATE_CLIENT_ADD_LISTING, submitClientAddListing)
  yield takeEvery(GET_CLIENT_ADD_LISTING, fetchClientAddListing)
 

}


export default addListingSaga
