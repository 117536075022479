import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty, map, size } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import { postClientDocument, getClientDocument, deleteClientDocument, updateClientDocument} from "../../store/document/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
class ClientDocument extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileName: '',
      selectedImage: '',
      fname: '',
      document:''
    }
    this.onFileChange = this.onFileChange.bind(this);
    this.handleDocumentSubmit = this.handleDocumentSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (document) => {
    this.setState({ document: document });
    this.setState({ deleteModal: true });
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  onFileChange(e) {
    let files = e.target.files;
    let fileNames = files[0];
    let fname = fileNames['name'];
    
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    
    fileReader.onload = (event) => {
      
      this.setState({
        selectedImage: event.target.result,
       }),
       this.setState({
        fname: fname,
       })
       

    }
  }
  handleDeleteDocument = () => {
    const { onDeleteDocument } = this.props;
    const { document } = this.state;
    
    if (document.id !== undefined) {
      onDeleteDocument(document);
      this.setState({ deleteModal: false });
    }
  };
  handleDocumentSubmit(value){
    //console.log(value)
    this.props.postClientDocument(value, this.props.history);

  }
  componentDidMount() {
    const { onGetClientDocuments } = this.props;
    onGetClientDocuments(localStorage.getItem('userId'));
}
componentDidUpdate(prevProps, prevState, snapshot) {
  const { documents } = this.props;
  if (!isEmpty(documents) && size(prevProps.documents) !== size(documents)) {
    this.setState({ documents: {}, isEdit: false });
  }
}
toggleRightCanvas() {
  this.setState({ document: "", isEdit: false, isRight: !this.state.isRight });
  this.toggle();
}
toggleRightCanvas = arg => {
  const document = arg;

  this.setState({
    document: {
  
      id:document.id,
      documentId: document.documentId,
      documentFromId:document.documentFromId,
      documentToId: document.documentToId,
      documentTitle: document.documentTitle,
      documentFrom: document.documentFrom,
      documentTo: document.documentTo,
      documentLink: document.documentLink,
      documentContent: document.documentContent,
      documentTypeId: document.documentTypeId,
      documentStorageType: document.documentStorageType,
      agentId: document.agentId,
      isActive: document.isActive,
      isUrgent: document.isUrgent,
      createdDate:document.createdDate,
      documentUrl:document.documentUrl
    },
    isEdit: true,
  });
  this.toggle();
};
  render() {
    const { documents, onUpdateClientDocument } = this.props;
    const { filename } = this.state;
    const document = this.state.document;
    const { isEdit, deleteModal } = this.state;
    return (
      <React.Fragment>
         {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
         <ToastContainer autoClose={2000} />
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteDocument}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>Client Documents | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Documents")}
              breadcrumbItem={this.props.t("Documents")}
            />
            <Row>
              
              
              <Col xl="12">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    documentTitle: (this.state && this.state.documentTitle) || "",
                    documentFromId: localStorage.getItem('userId'),
                    documentToId: localStorage.getItem('userId'),
                    documentFrom: "client",
                    documentTo: "realtor",
                    agentId: localStorage.getItem('agentId'),
                    documentContent: this.state.selectedImage,
                    documentLink: this.state.fname,
                    isUrgent: (this.state && this.state.isUrgent) || "",

                  }}
                  validationSchema={Yup.object().shape({
                    documentTitle: Yup.string().max(50, "Title must not exceed 50").required("This is Required")
                  })}

                  onSubmit={this.handleDocumentSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                   <Row>
                       
                       
                        <Col sm="3">
                        <div className="form-group mb-3">
                          <Label htmlFor="file">File upload</Label>
                          <input id="file" name="file" type="file" onChange={this.onFileChange} className="form-control" />
                          
                        </div>
                       
                        </Col>
                        <Col sm="5">
                        <FormGroup className="mb-3">
                            <Label htmlFor="documentTitle">
                              {this.props.t("Title")}
                            </Label>
                            <Field
                              name="documentTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.documentTitle && touched.documentTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="documentTitle"
                            />
                             <ErrorMessage
                              name="documentTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="isUrgent">
                              {this.props.t("Is Urgent")}
                            </Label>
                            <Field as="select" name="isUrgent" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </Field>
                          </FormGroup>
                        </Col>   
                        <Col sm="12">
                        <div className="form-check">
                          <Field type="checkbox" name="checked" id="formrow-customCheck" value="One" />
                          <Label
                            className="form-check-Label"
                            htmlFor="formrow-customCheck"
                          >
                            Send text notification 
                          </Label>
                        </div>
                        </Col>
                        <Col sm="12">
                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md mt-27">Send Document</button></div>
                        </Col>
                    </Row>

                
             
             </CardBody>
                </Card>
                
            </Form>
                  )}
			 </Formik>
			 
       <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Document History</h5>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								
								<th>Date</th>
							
								<th>Title</th>
								<th>Urgent</th>
               
                <th>Link</th>
                <th>Action</th>
							  </tr>
							</thead>
							<tbody>
              {map(documents, (document, dockey) => (
               <tr key={"_doc_" + dockey}>
                <td><Moment format="D MMM YY">{document.createdDate}</Moment></td>
               
                <td>{document.documentTitle}</td>
                
                <td>{document.isUrgent == true
                    ? "Yes"
                    : "No"
                }</td>
                <td>{document.documentFrom == 'client' ? <a target="_blank" rel="noreferrer" className="btn-u" href={"http://realtyspace-001-site2.itempurl.com/" + document.documentUrl}><i className=' bx bxs-cloud-upload'></i></a> : <a target="_blank" className="btn-d" rel="noreferrer" href={"http://realtyspace-001-site2.itempurl.com/" + document.documentUrl}><i className='bx bxs-cloud-download'></i></a> }</td>
                <td>
                <UncontrolledDropdown>
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(document)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(document)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                
                </td>
               </tr>
              ))}
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>
            </Col>
            
            </Row>
            <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update 
                      </OffcanvasHeader>
                      <OffcanvasBody>
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:document.id,
                    documentId: document.documentId,
                    documentFromId:document.documentFromId,
                    documentToId: document.documentToId,
                    documentTitle: document.documentTitle,
                    documentFrom: document.documentFrom,
                    documentTo: document.documentTo,
                    documentLink: document.documentLink,
                   
                    documentContent: (this.state.selectedImage) || document.documentContent,
                   
                    documentLink:(this.state.fname) || document.documentLink,
                
                    documentTypeId: document.documentTypeId,
                    documentStorageType: document.documentStorageType,
                    agentId: document.agentId,
                    isActive: document.isActive,
                    isUrgent: (document.isUrgent==true) ? true : false,
                    createdDate:document.createdDate,
                    documentUrl:document.documentUrl

                  }}
                  validationSchema={Yup.object().shape({
                    documentTitle: Yup.string().max(50, "Title must not exceed 50").required("This is Required")
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateDocument = {
                        id:values.id,
                        documentId: values.documentId,
                        documentFromId:values.documentFromId,
                        documentToId: values.documentToId,
                        documentTitle: values.documentTitle,
                        documentFrom: values.documentFrom,
                        documentTo: values.documentTo,
                        documentLink: (this.state.fname) || values.documentLink,
                        documentContent: (this.state.selectedImage ? this.state.selectedImage : values.documentContent),
                        documentTypeId: values.documentTypeId,
                        documentStorageType: values.documentStorageType,
                        agentId: values.agentId,
                        isActive: values.isActive,
                        isUrgent: (values.isUrgent == 'true') ? true : false,
                        createdDate:values.createdDate,
                        documentUrl:values.documentUrl
                      };
                      onUpdateClientDocument(updateDocument);
                    } else {
                      
                    }

                    //this.setState({ selectedOrder: null });
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             
              
                   <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="documentTitle">
                              {this.props.t("Title")}
                            </Label>
                            <Field
                              name="documentTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.documentTitle && touched.documentTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="documentTitle"
                            />
                             <ErrorMessage
                              name="documentTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="isUrgent">
                              {this.props.t("Is Urgent")}
                            </Label>
                            <Field as="select" name="isUrgent" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <div className="form-group mb-3">
                          <Label htmlFor="file">File upload</Label>
                          <input id="file" name="file" type="file" onChange={this.onFileChange} className="form-control" />
                          
                        </div>
                       
                        </Col>

                       
                        <Col sm="12">
                        <div className="form-check">
                          <Input
                            type="checkbox"
                            className="form-check-Input"
                            id="formrow-customCheck"
                            style={{marginTop:5}}
                          />
                          <Label
                            className="form-check-Label"
                            htmlFor="formrow-customCheck"
                          >
                            Send text notification 
                          </Label>
                        </div>
                        </Col>
                        <Col sm="12">
                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md mt-27">Update </button></div>
                        </Col>
                    </Row>

                
            </Form>
                  )}
			 </Formik>

                      </OffcanvasBody>
                    </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientDocument.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  postClientDocument:PropTypes.func,
  onGetClientDocuments:PropTypes.func,
  documents:PropTypes.array,
  onDeleteDocument:PropTypes.func,
  onUpdateClientDocument:PropTypes.func,
  loading:PropTypes.object

}
const mapStateToProps = ({ Documents }) => (
  {
      documents: Documents.documents,
      loading:Documents.loading

  })  
const mapDispatchToProps = dispatch => ({
  postClientDocument: (data) => dispatch(postClientDocument(data)),
  onGetClientDocuments:(clientId) => dispatch(getClientDocument(clientId)),
  onDeleteDocument:id => dispatch(deleteClientDocument(id)),
  onUpdateClientDocument:(data) => dispatch(updateClientDocument(data))
  
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientDocument))
