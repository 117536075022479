import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";


import Switch from "react-switch"
import { getClientDetail, addClientImportantDate, addClientChild, getClientImportantDate, getClientChild, deleteClientImportant, updateClientProfile} from "../../store/profile/actions"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
class GeneralSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      rows1: [],
      clientTypes:[],
      titles:[],
      importantdate:'',
      sources:[],
      reports: [
        { title: "Upcoming Closings", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "Upcoming appointments",
          iconClass: "bx-archive-in",
          description: "6",
        },
        {
          title: "Active Clients ",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Messages Received",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Investment interest",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
      ]
    }
    this.handleImportantDateSubmit = this.handleImportantDateSubmit.bind(this)
    this.handleChildSubmit = this.handleChildSubmit.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (importantdate) => {
    this.setState({ importantdate: importantdate });
    this.setState({ deleteModal: true });
  };
  handleDeleteImportantDate = () => {
    const { onDeleteImportantDate } = this.props;
    const { importantdate } = this.state;
    
    if (importantdate.id !== undefined) {
      onDeleteImportantDate(importantdate);
      this.setState({ deleteModal: false });
    }
  };
  handleImportantDateSubmit(value){
    this.props.addClientImportantDate(value, this.props.history);
  }

  handleChildSubmit(value){
    this.props.addClientChild(value, this.props.history);
  }


componentDidMount() {
  const { match: { params }, onGetClientDetail, onGetClientImportantDate, onGetClientChild } = this.props;
  onGetClientDetail(localStorage.getItem('userId'));
  onGetClientImportantDate(localStorage.getItem('userId'));
  onGetClientChild(localStorage.getItem('userId'))
 
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const token = obj.resultData.bearerToken;

  const str = 'Bearer ' + token;
  axios.get('https://rscoreapi.azurewebsites.net/api/LeadType/all', { headers: { Authorization: str } })
    .then(res => {
      const clientTypes = res.data;
      this.setState({ clientTypes });
    }),
    axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Agent:Title',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const titles = res.data;
          this.setState({ titles });
        }),
        axios.get('https://rscoreapi.azurewebsites.net/api/LeadSource/all', { headers: { Authorization: str } })
        .then(res => {
          const sources = res.data;
          this.setState({ sources });
        })
    
}
  render() {
    const { clietDetail, importantdates, childs, loading, onUpdateClientProfile } = this.props;
    const importantdate = this.state.importantdate;
    const {  deleteModal } = this.state;
    //console.log(loading);
    const Offsymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          No
        </div>
      )
    }

    const OnSymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Yes
        </div>
      )
    }

    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Client Profile | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteImportantDate}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
       
          
            <Row>
              
              
              <Col xl="12">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: clietDetail.id,
                    clientId: clietDetail.clientId,
                    agentId:clietDetail.agentId,
                    userLogin:clietDetail.userLogin,
                    title:      clietDetail.title,
                    firstName: clietDetail.firstName,
                    lastName: clietDetail.lastName,
                    clientPropertyTypeId:clietDetail.clientPropertyTypeId,
                    clientTypeId: clietDetail.clientTypeId,
                    clientSourceId: clietDetail.clientSourceId,
                    primaryContactId:clietDetail.primaryContactId,
                    secondaryContactId:clietDetail.secondaryContactId,
                    addressId:clietDetail.addressId,
                    isActive:clietDetail.isActive,
                    referedBy:clietDetail.referedBy,
                    howOffenClient:clietDetail.howOffenClient,
                    notes:clietDetail.notes,

                   
                   

                  }}
                  validationSchema={Yup.object().shape({
                    clientTypeId: Yup.string().required("This is Required"),
                    title: Yup.string().required("This is Required"),
                    contactFName: Yup.string().required("This is Required"),
                    contactLName: Yup.string().required("This is Required"),
                   
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                   
                      const updateClient = {
                    id: values.id,
                    clientId: values.clientId,
                    agentId:values.agentId,
                    //userLogin:values.userLogin,
                    title:      values.title,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    clientPropertyTypeId:values.clientPropertyTypeId,
                    clientTypeId: values.clientTypeId,
                    clientSourceId: values.clientSourceId,
                    primaryContactId:values.primaryContactId,
                    secondaryContactId:values.secondaryContactId,
                    addressId:values.addressId,
                    isActive:values.isActive,
                    referedBy:values.referedBy,
                    howOffenClient:values.howOffenClient,
                    notes:values.notes,

                   
                  };
                      onUpdateClientProfile(updateClient);
                  
                    
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             

        
                <Card> 
                    <CardBody>
                   
              
                   <Row>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="timeZone">
                              {this.props.t("Time zone")}
                            </Label>
                            <Field as="select" name="timeZone" 
                            className={
                              "form-control" +
                              (errors.timeZone && touched.timeZone
                                ? " is-invalid"
                                : "")
                            }
                            
                            onChange={handleChange}>
                                <option value="">Select</option>
                                
                            </Field>
                            <ErrorMessage
                              name="timeZone"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="defaultCurrency">
                              {this.props.t("Default currency")}
                            </Label>
                            <Field as="select" name="defaultCurrency" 
                            className={
                              "form-control" +
                              (errors.defaultCurrency && touched.defaultCurrency
                                ? " is-invalid"
                                : "")
                            }
                            
                            onChange={handleChange}>
                                <option value="">Select</option>
                                
                            </Field>
                            <ErrorMessage
                              name="defaultCurrency"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>       
                        
                  
                        
                        </Row>
                         

                       
             
             </CardBody>
                </Card>

                <Card> 
                    <CardBody>
                    <h5 className="card-title">Password</h5>
                   <Row>
                   <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryOffice">
                              {this.props.t("New Password")}
                            </Label>
                            <Field
                              name="newPassword"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.newPassword && touched.newPassword
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="newPassword"
                            />
                             <ErrorMessage
                              name="newPassword"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>   
                        
                            <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label htmlFor="confirmPassword">
                              {this.props.t("Confirm Password")}
                            </Label>
                            <Field
                              name="confirmPassword"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.confirmPassword && touched.confirmPassword
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="confirmPassword"
                            />
                             <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>   
                  
                        
                        </Row>
                         

                       
             
             </CardBody>
                </Card>

                <Card> 
                    <CardBody>
                    <h5 className="card-title">Security </h5>
                   <Row>
                   
                        
                          <Col sm="12">
                            <FormGroup className="mb-3 float-left">
                            <Label htmlFor="confirmPassword">
                              {this.props.t("Enable 2 factor authentication")}
                            </Label>
                           <Switch
                           uncheckedIcon={<Offsymbol />}
                           className="me-1 mb-sm-8 mb-2 float-end"
                           checkedIcon={<OnSymbol />}
                           onColor="#626ed4"
                           onChange={() =>
                             this.setState({ switch1: !this.state.switch1 })
                           }
                           checked={this.state.switch1}
                         />
                         </FormGroup>
                         </Col>
                         <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-left mt-27">Save</button></div>     
                        </Row>
                         

                       
             
             </CardBody>
                </Card>



           

</Form>
)}
</Formik>


 
            
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
GeneralSetting.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  addClientImportantDate:PropTypes.func,
  addClientChild:PropTypes.func,
  onGetClientImportantDate:PropTypes.func,
  onGetClientChild:PropTypes.func,
  importantdates:PropTypes.array,
  childs:PropTypes.array,
  onDeleteImportantDate:PropTypes.func,
  loading:PropTypes.object,
  onUpdateClientProfile:PropTypes.func

}
const mapStateToProps = ({ Profile }) => (
  {
    loading: Profile.loading,
    clietDetail: Profile.clietDetail,
    importantdates:Profile.importantdates,
    childs:Profile.childs
    

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientImportantDate:(data) => dispatch(addClientImportantDate(data)),
  addClientChild:(data) => dispatch(addClientChild(data)),
  onGetClientImportantDate: clientId => dispatch(getClientImportantDate(clientId)),
  onGetClientChild: clientId => dispatch(getClientChild(clientId)),
  onDeleteImportantDate : id => dispatch(deleteClientImportant(id)),
  onUpdateClientProfile: (data) => dispatch(updateClientProfile(data))

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GeneralSetting))
