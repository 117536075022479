import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {  map, capitalize } from "lodash";
//import Lead from "./Lead";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { addClientAddListing, getClientAddListing } from "../../store/addlisting/actions"
import CurrencyFormat from 'react-currency-format';
import Switch from "react-switch"
import typeView from "../../assets/images/type-view.png"
import uploadView from "../../assets/images/icons/upload.png"
import gridView from "../../assets/images/grid-view.png"
import downloadView from "../../assets/images/icons/down.png"
import compareImg from "../../assets/images/icons/compare.png"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Tooltip
} from "reactstrap"

class addListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    this.toggle = this.toggle.bind(this);
    this.handleAddListingSubmit = this.handleAddListingSubmit.bind(this)
  }
  toggle () {
    this.setState({
      open: !this.state.open
    })
  }
  handleAddListingSubmit(value){
    
    this.props.addClientAddListing(value, this.props.history);

  }
  componentDidMount() {
    const {  onGetClientAddListing } = this.props;
   
    onGetClientAddListing(localStorage.getItem('userId'),localStorage.getItem('agentId'))
   
}
handleChange = (activeStatus, itemId) => {
    
};
  render() {
    const { addListings, loading } = this.props;
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Add Listing  | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Buyer")}
              breadcrumbItem={this.props.t("Listings ")}
            />
            <Row>
              
              
              <Col xl="12">
			  <Row>
				<Col sm="12">
					 <Card className="mini-stats-wid"> 
                    <CardBody>
                    <Row>
                        <Col sm="6">
                           <h5 className="card-title mb20 ">Listings recieved from Agent</h5>
                        </Col>
                        <Col sm="6">
                        <div>
                          <div
                            className="btn-group rl-group-btn float-end mb20"
                            role="group"
                          >
                          <Link to="/add-listing" >
                          <button
                            type="button"
                            className="btn btn-outline-warning  w-xs  "
                          >
                            <img src={gridView} /><br/>
                              <span className="text-black">All</span>
                          </button></Link>
                          <Link to="/sent-add-listing" >
                          <button
                            type="button"
                            className="btn btn-outline-warning   w-xs "
                          >
                            <img className="tab-img" src={uploadView} />
                             <br/>
                             <span className="text-black">Sent</span>
                              
                          </button></Link>
                          <Link to="/received-add-listing" >
                          <button
                            type="button"
                            className="btn btn-outline-warning   w-xs active"
                          >
                            <img className="tab-img" src={downloadView} />
                              <br/>
                              
                              <span className="text-black">Received</span>
                          </button></Link>
                          <Link to="/visited-add-listing" >
                          <button
                            type="button"
                            className="btn btn-outline-warning   w-xs"
                          >
                            <img src={typeView} />
                              <br/>
                              <span className="text-black">Visited</span>
                          </button></Link>
                           
                          {/* <button
                            type="button"
                            className="btn btn-outline-warning  w-xs"
                          >
                            <Link to="/buyer-prefrences" className='text-black'><img src={groupView} />
                            <br/>
                            Manage
                            </Link>
                          </button> */}
                              
                             
                            </div>
                          </div>  
                                    </Col>
                                </Row>
                    <div className="row gy-2 gx-3  mb20">
                      <div className="col-sm-3 float-start">
                        <div className="col-sm-12">
                          {/* <Link href="">
                          <img className="tab-img" src={compareImg}/> <span className="compare-text"> Compare (Max 4 properties)</span>
                          </Link> */}
                          
                        </div>
                      </div>
                      <div className="col-sm-9 ">
                        <div className="row float-end">
                          <div className="col-sm-auto">
                            <Label className="visually-hidden" htmlFor="autoSizingInput">Name</Label>
                            <Input type="text" className="form-control" id="autoSizingInput" placeholder="Search MLS#" />
                          </div>

                          <div className="col-sm-auto">
                            <Label className="visually-hidden" htmlFor="autoSizingInput">Name</Label>
                            <Input type="text" className="form-control" id="autoSizingInput" placeholder="Price" />
                          </div>
                        
                          <div className="col-sm-auto">
                            <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
                            <select defaultValue="0" className="form-select">
                              <option value="0">Status...</option>
                              <option value="1">Avialable </option>
                              <option value="2"> Sold</option>
                              d
                            </select>
                          </div>
                          
                          <div className="col-sm-auto">
                            <button type="submit" className="btn btn-primary w-md">Search</button>
                          </div>
                        </div>
                      </div>
                    </div>
        <div className="clearfix"></div>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
                  {/* <th></th> */}
								<th>Date</th>
								<th>MLS#</th>
								<th>Asking price</th>
								<th>City</th>
                                <th>Status </th>
                                <th>Like / Dislike </th>
                                <th>Showing</th>
                                
                                <th>Agent Note</th>
                                
							  </tr>
							</thead>
              <tbody>
                              {map(addListings, (addListing, lkey) => (addListing.addedBy !== 'client' && (
                                 <tr key={"_alist_" + lkey}>
                                  {/* <td><input type='checkbox' name='checkbox'/></td> */}
                                    <td><img className="listing-img" src={downloadView} /> <Moment format="D MMM YY">{addListing.listingDate}</Moment></td>
                                    <td><a target="_blank" rel="noreferrer"  href={addListing.mlsurl}>{addListing.mlsId} <i className=" bx bx-link-external"></i></a></td>
                                    <td><CurrencyFormat value={addListing.listedPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                    <td>{addListing.listingCity}</td>
                                    <td>{addListing.listingStatusId==0 ? "Sold" : "Available"}</td>
                                    <td><Switch checked={addListing.isActive} onChange={isActive => this.handleChange(isActive, addListing.id)} /></td>
                                    <td>{addListing.showingDate != null ? <Moment format="Do MMMM YYYY">{addListing.showingDate}</Moment>   : "n/a" }  {addListing.startTime != null ?  '-'+<Moment format="hh:mm A">{addListing.startTime}</Moment>   : "" }  {addListing.endTime != null ?  '-'+<Moment format="hh:mm A">{addListing.endTime}</Moment>   : "" }</td>
                                    <td>
                                   
                                    {/* <Tooltip
                                      placement="top"
                                      isOpen={this.state.open} toggle={this.toggle}
                                      target={`movie-${addListing.id}`}
                            
                                    >
                                      Hello world!
                                    </Tooltip>
                                    <i className="bx bx-comment-dots icon-note" id={`movie-${addListing.id}`}></i> */}
                                    {addListing.clientNotes??"n/a"}
                                 
                                    
                                    </td>
                                </tr>
                              )))}

<tr>
                                    <td><img className="listing-img" src={downloadView} /> 12th fab, 2024 </td> 
                                    <td><Link to="/manage-listing">W5687695 <i className=" bx bx-link-external"></i></Link></td>
                                    <td>$1,350,900</td>
                                    <td>Mississauge</td>
                                    <td> Available</td>
                                    <td><Switch checked={false}  /></td>
                                    <td>n/a</td>
                                    
                                    <td>
                                      <b>Fits our needs</b>
                                    </td>
                              </tr>

                              <tr>
                              <td><img className="listing-img" src={downloadView} /> 12th fab, 2024 </td>
                                    <td><Link to="/manage-listing">W154674 <i className=" bx bx-link-external"></i></Link></td>
                                    <td>$1,990,000</td>
                                    <td>Mississauge</td>
                                    <td> Available</td>
                                    <td><Switch checked={false}  /></td>
                                    <td> 24th fab, 2024</td>
                                    
                                    <td>
                                      <b>Nice Location</b>
                                    </td>
                              </tr>

                              </tbody>          
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>
			
			
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
addListing.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientAddListing:PropTypes.func,
  addListings:PropTypes.array,
  onGetClientAddListing:PropTypes.func,
  loading:PropTypes.object

}
const mapStateToProps = ({ addListing }) => (
  {
    addListings:addListing.addListings,
    loading:addListing.loading

  })
const mapDispatchToProps = dispatch => ({
  
  addClientAddListing: (data) => dispatch(addClientAddListing(data)),
  onGetClientAddListing:(clientId, agentId) => dispatch(getClientAddListing(clientId, agentId))
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(addListing))
