import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify";

import { CREATE_CLIENT_SELLER_SHOWING, DELETE_CLIENTS_SELLER_SHOWING, GET_CLIENTS_SELLER_LISTING, GET_CLIENTS_SELLER_SHOWING, UPDATE_CLIENTS_SELLER_SHOWING } from "./actionTypes"

import { deleteClientShowingAPI, getClientSellerListingUrl, getClientSellerShowingUrl, postClientSellerShowing, updateClientSellerShowingAPI } from '../../helpers/backend_helper'

import { addClientSellerShwoingFail, addClientSellerShwoingSuccess, deleteClientShowingFail, deleteClientShowingSuccess, getClientSellerListingFail, getClientSellerListingSuccess, getClientSellerShwoingFail, getClientSellerShwoingSuccess, updateClientSellerShowingError } from "./actions"
import { take } from "lodash";

function* submitClientSellerShwoing({ payload: { showing, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postClientSellerShowing, {
      clientListingId: parseInt(showing.clientListingId),
      showingDate: showing.showingDate,
      startTime: showing.startTime,
      endTime: showing.endTime,
      agentName: '',
      agentOrganization: '',
      agentContact: '',
      agentEmail: '',
      notificationMinutes: parseInt(showing.notification),
      clientId: parseInt(showing.clientId),
      agentId: parseInt(showing.agentId)

    })
    yield put(addClientSellerShwoingSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Seller Showing Added");
      const response = yield call(getClientSellerShowingUrl, showing.clientId)
      yield put(getClientSellerShwoingSuccess(response))
    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientSellerShwoingFail(error))
  }
}

function* fetchClientSellerShowing({ clientId }) {
  try {
    const response = yield call(getClientSellerShowingUrl, clientId)
    yield put(getClientSellerShwoingSuccess(response))
  } catch (error) {
    yield put(getClientSellerShwoingFail(error))
  }
}


function* fetchClientSellerListing({ clientId }) {
  try {
    const response = yield call(getClientSellerListingUrl, clientId)
    yield put(getClientSellerListingSuccess(response))
  } catch (error) {
    yield put(getClientSellerListingFail(error))
  }
}


function* onDeleteClientShowing({ payload: showing }) {
  //console.log(document);
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteClientShowingAPI, showing.id)
    yield put(deleteClientShowingSuccess(response))
    toast.dismiss();
    toast.success("Showing Deleted");
    const res = yield call(getClientSellerShowingUrl, showing.clientId)
    yield put(getClientSellerShwoingSuccess(res))
  } catch (error) {
    yield put(deleteClientShowingFail(error))
  }
}

function* onClientSellerShwoingUpdate({ payload: showing }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updateClientSellerShowingAPI, showing)
    toast.dismiss();
    toast.success("Showing Updated");
    const res = yield call(getClientSellerShowingUrl, showing.clientId)
    yield put(getClientSellerShwoingSuccess(res))
    
  } catch (error) {
    yield put(updateClientSellerShowingError(error))
  }
}







function* showingSaga() {
  
  yield takeEvery(CREATE_CLIENT_SELLER_SHOWING, submitClientSellerShwoing)
  yield takeEvery(GET_CLIENTS_SELLER_SHOWING, fetchClientSellerShowing)
  yield takeEvery(GET_CLIENTS_SELLER_LISTING, fetchClientSellerListing)
  yield takeEvery(DELETE_CLIENTS_SELLER_SHOWING, onDeleteClientShowing)
  yield takeEvery(UPDATE_CLIENTS_SELLER_SHOWING, onClientSellerShwoingUpdate)
 

}


export default showingSaga
