/* CLIENTs */
export const POST_CLIENT_MESSAGE = "POST_CLIENT_MESSAGE"
export const POST_CLIENT_MESSAGE_SUCCESS = "POST_CLIENT_MESSAGE_SUCCESS"
export const POST_CLIENT_MESSAGE_FAIL = "POST_CLIENT_MESSAGE_FAIL"


export const GET_CLIENTS_MESSAGE = "GET_CLIENTS_MESSAGE"
export const GET_CLIENTS_MESSAGE_SUCCESS = "GET_CLIENTS_MESSAGE_SUCCESS"
export const GET_CLIENTS_MESSAGE_FAIL = "GET_CLIENTS_MESSAGE_FAIL"










