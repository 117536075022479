import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import { withTranslation } from "react-i18next"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap"
import img1 from "../../assets/images/sidebar/img1.jpg"
import primg1 from "../../assets/images/no-img.jpg"
import primg2 from "../../assets/images/project/img2.jpg"
import primg3 from "../../assets/images/project/img3.jpg"
import primg4 from "../../assets/images/project/img4.jpg"

import primg5 from "../../assets/images/project/img5.jpg"
import primg6 from "../../assets/images/project/img6.jpg"
import primg7 from "../../assets/images/project/img7.jpg"
import primg8 from "../../assets/images/project/img8.jpg"
import primg9 from "../../assets/images/project/img1.jpg"

import appointment from "../../assets/images/rsicons/appointment.png"
import activiti from "../../assets/images/rsicons/activities.png"
import call from "../../assets/images/rsicons/call.png"
import notes from "../../assets/images/rsicons/notes.png"
import todo from "../../assets/images/rsicons/to-do.png"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getProjects } from "../../store/projects/actions"


class Project extends Component {

  componentDidMount() {
    const { onGetProject } = this.props;
    onGetProject(localStorage.getItem('agentId'));
}

  render() {
    const { projects } = this.props;
    return (
      <React.Fragment>
         {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <div className="page-content">
          <MetaTags>
            <title>Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Breadcrumbs
              title="Projects"
              breadcrumbItem="Projects"
            />
            <h4>Currently Selling</h4>

<Row>
{map(projects, (project, projectkey) => (
  <Col sm="3" key={"_note_" + projectkey}>
    <div className="product">
        <div className="pro-img">
        {project.projectMainImage != null 
           ? <img src={"https://rscoreapi.azurewebsites.net/" +  project.projectMainImage.projectGalleryUrl} className="img-fluid" />
           : <img src={primg1} className="img-fluid" />
        }
        </div>
        <div className="prd-info">
            <h5 className="location">{project.city}</h5>
            <h4 className="prd-title"><Link to={"/project-detail/" + project.id + "/" + project.projectId}> {project.projectName} </Link>  <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link></h4>
        </div>
    </div>

  </Col>
))}
 
</Row>
<Row>
              <Col sm="3">
                <div className="product">
                    <div className="pro-img">
                        {/* <div className="icon-check"><i className=" bx bx-check-shield"></i></div> */}
                        <img src={primg5} className="img-fluid" />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">Toronto, ON</h5>
                        <h4 className="prd-title"><Link to="/project-detail/34/10da1bf6-7e35-4c83-8435-53072057f347">Liberty Market Tower</Link>  <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link></h4>
                    </div>
                </div>

              </Col>

              <Col sm="3">
                <div className="product">
                    <div className="pro-img">
                       
                        <img src={primg2} className="img-fluid" />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">65 Grand Magazine Street, Toronto</h5>
                        <h4 className="prd-title"> Fortune at Fort York <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link></h4>
                    </div>
                </div>

              </Col>

              <Col sm="3">
                <div className="product">
                    <div className="pro-img">
                        {/* <div className="icon-check"><i className=" bx bx-check-shield"></i></div> */}
                        <img src={primg3} className="img-fluid" />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">Toronto, ON</h5>
                        <h4 className="prd-title">Central Park Tower  <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link></h4>
                    </div>
                </div>

              </Col>

              <Col sm="3">
                <div className="product">
                    <div className="pro-img">
                        
                        <img src={primg4} className="img-fluid" />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">Toronto, ON</h5>
                        <h4 className="prd-title">Manhattan View <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link></h4>
                    </div>
                </div>

              </Col>

              <Col sm="3">
                <div className="product">
                    <div className="pro-img">
                        
                        <img src={primg6} className="img-fluid" />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">Toronto, ON</h5>
                        <h4 className="prd-title">Fieldstone <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link></h4>
                    </div>
                </div>
              </Col>

              <Col sm="3">
                <div className="product">
                    <div className="pro-img">
                        
                        <img src={primg7} className="img-fluid" />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">Toronto, ON</h5>
                        <h4 className="prd-title">The Residences at Hunters Pointe <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link></h4>
                    </div>
                </div>
              </Col>

              <Col sm="3">
                <div className="product">
                    <div className="pro-img">
                        
                        <img src={primg8} className="img-fluid" />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">Toronto, ON</h5>
                        <h4 className="prd-title">PC Modern Towns <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link></h4>
                    </div>
                </div>
              </Col>

              <Col sm="3">
                <div className="product">
                    <div className="pro-img">
                        
                        <img src={primg9} className="img-fluid" />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">Toronto, ON</h5>
                        <h4 className="prd-title">36 Birch <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link></h4>
                    </div>
                </div>
              </Col>
            
            </Row>




           
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
Project.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  onGetProject:PropTypes.func,
  projects:PropTypes.array,
  loading:PropTypes.object
}

const mapStateToProps = ({ projects }) => (
    {
        projects:projects.projects,
        loading:projects.loading
  
    })

    const mapDispatchToProps = dispatch => ({
        
        onGetProject:(agetnId) => dispatch(getProjects(agetnId))
       
      });

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Project))
