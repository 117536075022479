import { CREATE_CLIENT_SELLER_SHOWING_FAIL, CREATE_CLIENT_SELLER_SHOWING_SUCCESS, DELETE_CLIENTS_SELLER_SHOWING_FAIL, DELETE_CLIENTS_SELLER_SHOWING_SUCCESS, GET_CLIENTS_SELLER_LISTING_FAIL, GET_CLIENTS_SELLER_LISTING_SUCCESS, GET_CLIENTS_SELLER_SHOWING, GET_CLIENTS_SELLER_SHOWING_FAIL, GET_CLIENTS_SELLER_SHOWING_SUCCESS, UPDATE_CLIENTS_SELLER_SHOWING_FAIL, UPDATE_CLIENTS_SELLER_SHOWING_SUCCESS } from "./actionTypes"

const initialState = {

  error: "",
  loading: true,
  showings: [],
  listings: [],
 

}

const Showings = (state = initialState, action) => {
  switch (action.type) {

    case CREATE_CLIENT_SELLER_SHOWING_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case CREATE_CLIENT_SELLER_SHOWING_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
      case GET_CLIENTS_SELLER_SHOWING:
        return {
          ...state,
          loading:true
        }
    case GET_CLIENTS_SELLER_SHOWING_SUCCESS:
      return {
        ...state,
        showings: action.payload,
        loading:false
      }
    case GET_CLIENTS_SELLER_SHOWING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CLIENTS_SELLER_LISTING_SUCCESS:
      return {
        ...state,
        listings: action.payload,
      }
    case GET_CLIENTS_SELLER_LISTING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      case DELETE_CLIENTS_SELLER_SHOWING_SUCCESS:
        return {
          ...state,
          showing: action.payload,
        }
  
      case DELETE_CLIENTS_SELLER_SHOWING_FAIL:
        return {
          ...state,
          error: action.payload,
        }
        case UPDATE_CLIENTS_SELLER_SHOWING_SUCCESS:
          return {
            ...state,
            showings: state.showings.map(showing =>
              showing.id.toString() === action.payload.id.toString()
                ? { showing, ...action.payload }
                : showing
            ),
          }
    
        case UPDATE_CLIENTS_SELLER_SHOWING_FAIL:
          return {
            ...state,
            error: action.payload,
          }
    default:
      state = { ...state }
      break

  }
  return state
}

export default Showings
