import { combineReducers } from "redux"
// Front
import Layout from "./layout/reducer"
// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
//import Profile from "./auth/profile/reducer"
//Message
import Messages from "./message/reducer"
import Documents from "./document/reducer"
import Prefrences from "./prefrences/reducer"
import Showings from "./showing/reducer"
import Appointments from "./appointment/reducer"

import addListing from "./addlisting/reducer"
//Calendar
import calendar from "./calendar/reducer"

//chat
//import chat from "./chat/reducer"

//crypto
//import crypto from "./crypto/reducer"

//invoices
//import invoices from "./invoices/reducer"

//projects
import projects from "./projects/reducer"

//tasks
//import tasks from "./tasks/reducer"

//contacts
//import contacts from "./contacts/reducer"

//mails
//import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

import Profile from "./profile/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Messages,
  Documents,
  Prefrences,
  Showings,
  Appointments,
  addListing,
  calendar,
  projects,
  Dashboard,
  DashboardSaas
})
export default rootReducer
