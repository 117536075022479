/* PROJECTS */
export const GET_PROJECTS = "GET_PROJECTS"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL"

/* PROJECTS DETAIL*/
export const GET_PROJECT_DETAIL = "GET_PROJECT_DETAIL"
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS"
export const GET_PROJECT_DETAIL_FAIL = "GET_PROJECT_DETAIL_FAIL"


export const POST_PROJECT_INTEREST = "POST_PROJECT_INTEREST"
export const POST_PROJECT_INTEREST_SUCCESS = "POST_PROJECT_INTEREST_SUCCESS"
export const POST_PROJECT_INTEREST_FAIL = "POST_PROJECT_INTEREST_FAIL"


export const GET_PROJECT_INTEREST = "GET_PROJECT_INTEREST"
export const GET_PROJECT_INTEREST_SUCCESS = "GET_PROJECT_INTEREST_SUCCESS"
export const GET_PROJECT_INTEREST_FAIL = "GET_PROJECT_INTEREST_FAIL"


export const GET_PROJECT_FEATURE = "GET_PROJECT_FEATURE"
export const GET_PROJECT_FEATURE_SUCCESS = "GET_PROJECT_FEATURE_SUCCESS"
export const GET_PROJECT_FEATURE_ERROR = "GET_PROJECT_FEATURE_ERROR"

export const GET_PROJECT_LINK = "GET_PROJECT_LINK"
export const GET_PROJECT_LINK_SUCCESS = "GET_PROJECT_LINK_SUCCESS"
export const GET_PROJECT_LINK_ERROR = "GET_PROJECT_LINK_ERROR"


export const GET_PROJECT_GALLERY = "GET_PROJECT_GALLERY"
export const GET_PROJECT_GALLERY_SUCCESS = "GET_PROJECT_GALLERY_SUCCESS"
export const GET_PROJECT_GALLERY_ERROR = "GET_PROJECT_GALLERY_ERROR"

