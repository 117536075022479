import { CREATE_CLIENT_CHILD_FAIL, CREATE_CLIENT_CHILD_SUCCESS, CREATE_CLIENT_IMPORTANT_DATE_FAIL, CREATE_CLIENT_IMPORTANT_DATE_SUCCESS, DELETE_CLIENT_IMPORTANT_DATE_FAIL, DELETE_CLIENT_IMPORTANT_DATE_SUCCESS, GET_CLIENTS_DETAIL, GET_CLIENTS_DETAIL_FAIL, GET_CLIENTS_DETAIL_SUCCESS, GET_CLIENT_CHILD_FAIL, GET_CLIENT_CHILD_SUCCESS, GET_CLIENT_IMPORTANT_DATE_FAIL, GET_CLIENT_IMPORTANT_DATE_SUCCESS, UPDATE_CLIENT_PROFILE_FAIL, UPDATE_CLIENT_PROFILE_SUCCESS,UPDATE_CLIENT_PRIMARY_CONTACT_SUCCESS,UPDATE_CLIENT_PRIMARY_CONTACT_FAIL,UPDATE_CLIENT_SECONDARY_CONTACT_SUCCESS,UPDATE_CLIENT_SECONDARY_CONTACT_FAIL } from "./actionTypes"

const initialState = {

  error: "",
  loading: true,
  importantdates:[],
  childs:[],
  clietDetail:{}

}

const Profile = (state = initialState, action) => {
  switch (action.type) {

    case GET_CLIENTS_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case GET_CLIENTS_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        clietDetail: action.payload,
      }
    case GET_CLIENTS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload
      }
    
    case CREATE_CLIENT_IMPORTANT_DATE_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case CREATE_CLIENT_IMPORTANT_DATE_FAIL:

      state = { ...state, error: action.payload, loading: false }
      break
    case GET_CLIENT_IMPORTANT_DATE_SUCCESS:
      return {
        ...state,
        importantdates: action.payload,
      }
    case GET_CLIENT_IMPORTANT_DATE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case CREATE_CLIENT_CHILD_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case CREATE_CLIENT_CHILD_FAIL:

      state = { ...state, error: action.payload, loading: false }
      break
    case GET_CLIENT_CHILD_SUCCESS:
      return {
        ...state,
        childs: action.payload,
      }
    case GET_CLIENT_CHILD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CLIENT_IMPORTANT_DATE_SUCCESS:
      return {
        ...state,
        importantdate: action.payload,
      }

    case DELETE_CLIENT_IMPORTANT_DATE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        clietDetail: action.payload,
      }

    case UPDATE_CLIENT_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case UPDATE_CLIENT_PRIMARY_CONTACT_SUCCESS:
      return {
        ...state,
        clietPrimaryContact: action.payload,
      }

    case UPDATE_CLIENT_PRIMARY_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case UPDATE_CLIENT_SECONDARY_CONTACT_SUCCESS:
        return {
          ...state,
          clietSecondaryContact: action.payload,
        }
  
      case UPDATE_CLIENT_SECONDARY_CONTACT_FAIL:
        return {
          ...state,
          error: action.payload,
        }
    
    default:
      state = { ...state }
      break

  }
  return state
}

export default Profile
