import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify";
import { take } from "lodash";
import { getClientRecentActivitiesFail, getClientRecentActivitiesSuccess, getClientUpcomingActivitiesFail } from "./actions";
import { getClientRecentActivitiesAPI, getClientUpcompingActivitiesAPI } from "helpers/backend_helper";
import { GET_CLIENT_RECENT_ACTIVITIES, GET_CLIENT_UPCOMING_ACTIVITIES } from "./actionTypes";

function* fetchClientUpcomingActivities({ payload: { clientId, agentId }  }) {
    try {
      const response = yield call(getClientUpcompingActivitiesAPI, clientId, agentId)
      yield put(getClientRecentActivitiesSuccess(response))
    } catch (error) {
      yield put(getClientUpcomingActivitiesFail(error))
    }
  }


  function* fetchClientRecentActivities({ payload: { clientId, agentId }  }) {
    try {
      const response = yield call(getClientRecentActivitiesAPI, clientId, agentId)
      yield put(getClientRecentActivitiesSuccess(response))
    } catch (error) {
      yield put(getClientRecentActivitiesFail(error))
    }
  }

  function* dashboardSaga() {
    yield takeEvery(GET_CLIENT_UPCOMING_ACTIVITIES, fetchClientUpcomingActivities)
    yield takeEvery(GET_CLIENT_RECENT_ACTIVITIES, fetchClientRecentActivities)
  }

  
export default dashboardSaga
