import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import img1 from "../../assets/images/project/img1.jpg"
import img2 from "../../assets/images/project/img2.jpg"
import img3 from "../../assets/images/project/img3.jpg"

import {
  Container,
  Row,
  Col,
  Card, 
  CardBody,
  
} from "reactstrap"

class MlsComapre extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Breadcrumbs
              title={this.props.t("Project")}
              breadcrumbItem={this.props.t("Project Compare")}
            />
            <Row>
                <Col lg="12">
                <Card>
                <CardBody>
                <div className="keyDifferences">
                <table data-gsco-table="true" data-gsco-wrap="true" className="container" data-track-component="CompareBox">
  <thead className="gsc_co_open">
    <tr>
   
      <th className="headingOverview comparefixedO"> Basic Information</th>
      <th className="headingOverview comparefixedO mypref"> My Prefrences</th>
      <th className=" headingOverview hidden-sm hidden-xs compareThreeBlock">
      W4628810 </th>
      <th className=" headingOverview hidden-sm hidden-xs compareThreeBlock">
      W46283411 </th>
      <th className=" headingOverview hidden-sm hidden-xs compareThreeBlock">
      W46283412 </th>
    </tr>
  </thead>
  <tbody data-gsco-content="true" className="differencesPanel" data-track-section="overview">
    <tr className="  " data-common-row="false">

      <td className="comparefixedO leftPanel" data-responsivecell="true">Bedroom</td>
      <td className="compareThreeBlock rightPanel mypref" data-responsivecelltwo="true"><div className="onlytext">3.5</div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">3</div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">3.5</div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">4</div></td>
    </tr>

    <tr className="  " data-common-row="false">
 
      <td className="comparefixedO leftPanel" data-responsivecell="true">Bathroom</td>
      <td className="compareThreeBlock rightPanel mypref" data-responsivecelltwo="true">4</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">2</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">3</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">4</td>
    </tr>
    <tr className="  " data-common-row="false">
 
      <td className="comparefixedO leftPanel" data-responsivecell="true">Price</td>
      <td className="compareThreeBlock rightPanel mypref" data-responsivecelltwo="true"> $800,00                                            </td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"> $699,995                                            </td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">$7,450,000 </td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">$1,500,000</td>
    </tr>
   

    
    
    <tr className="  " data-common-row="false">
   
      <td className="comparefixedO leftPanel" data-responsivecell="true"> Down Payment </td>
      <td className="compareThreeBlock rightPanel mypref" data-responsivecelltwo="true"><div className="onlytext">Fixed</div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">Fixed</div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">%</div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">%</div></td>
    </tr>
    <tr className="  " data-common-row="false">
 
 <td className="comparefixedO leftPanel" data-responsivecell="true">Gym</td>
 <td className="compareThreeBlock rightPanel mypref" data-responsivecelltwo="true"></td>
 <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">0.1KM</td>
 <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">0.5KM</td>
 <td className="compareThreeBlock rightPanel" data-responsivecelltwo="true"><i className="icon-deletearrow">200M</i></td>
</tr>
    <tr className="  " data-common-row="false">
      <td className="comparefixedO leftPanel" data-responsivecell="true">School</td>
      <td className="compareThreeBlock rightPanel mypref" data-responsivecelltwo="true"></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">3KM</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">6KM</td>
      <td className="compareThreeBlock rightPanel" data-responsivecelltwo="true">9KM</td>
    </tr>
    <tr className="  " data-common-row="false">
      <td className="comparefixedO leftPanel" data-responsivecell="true">My Office</td>
      <td className="compareThreeBlock rightPanel mypref" data-responsivecelltwo="true"></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">15KM</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">21KM</td>
      <td className="compareThreeBlock rightPanel" data-responsivecelltwo="true">24KM</td>
    </tr>
    <tr className="  " data-common-row="false">
      <td className="comparefixedO leftPanel" data-responsivecell="true">Wife Office</td>
      <td className="compareThreeBlock rightPanel mypref" data-responsivecelltwo="true"></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">19KM</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">24KM</td>
      <td className="compareThreeBlock rightPanel" data-responsivecelltwo="true">34KM</td>
    </tr>
    
   
  </tbody>
</table>
</div>

                    </CardBody>
                </Card>
                </Col>
                
            </Row>
            
           
            
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
MlsComapre.propTypes = {
    t: PropTypes.any
  
  }

export default connect()(withTranslation()(MlsComapre))
