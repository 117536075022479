import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Calender from "./calander"
import { addClientAppointment, getClientAppointment, deleteClientAppointment, updateClientAppointment} from "../../store/appointment/actions"
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import { isEmpty, map, size } from "lodash";
import axios from "axios"
import Switch from "react-switch"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
class ClientApp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      modal_standard: false,
      modal_fullscreen: false,
      modal_large: false,
      modal_xlarge: false,
      modal_small: false,
      modal_center: false,
      modal_scroll: false,
      modal_backdrop: false,
      appointment:'',
      appTypes: [],
      appPriories: [],
      appLocTypes: [],
      checked: false

    }
    this.tog_standard = this.tog_standard.bind(this)
    this.tog_fullscreen = this.tog_fullscreen.bind(this)
    this.tog_xlarge = this.tog_xlarge.bind(this)
    this.tog_large = this.tog_large.bind(this)
    this.tog_small = this.tog_small.bind(this)
    this.tog_center = this.tog_center.bind(this)
    this.tog_scroll = this.tog_scroll.bind(this)
    this.tog_backdrop = this.tog_backdrop.bind(this)
    this.handelValidAppointment = this.handelValidAppointment.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
   
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  onClickDelete = (appointment) => {
    this.setState({ appointment: appointment });
    this.setState({ deleteModal: true });
  };
  handleDeleteAppointment = () => {
    const { onDeleteAppointment } = this.props;
    const { appointment } = this.state;
    
    if (appointment.id !== undefined) {
      onDeleteAppointment(appointment);
      this.setState({ deleteModal: false });
    }
  };
  handelValidAppointment(value){
    
    this.props.addClientAppointment(value, this.props.history);

  }
  componentDidMount() {
    const { onGetClientApp } = this.props;
    onGetClientApp(localStorage.getItem('agentId'),localStorage.getItem('userId'));

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentType/all', {
      headers: { Authorization: str }
    })
      .then(res => {
        const appTypes = res.data;
        this.setState({ appTypes });
      }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentPriority/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appPriories = res.data;
          this.setState({ appPriories });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentLocationType/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appLocTypes = res.data;
          this.setState({ appLocTypes });
        })
}
  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }))
    this.removeBodyCss()
  }

  tog_backdrop() {
    this.setState(prevState => ({
      modal_backdrop: !prevState.modal_backdrop,
    }))
    this.removeBodyCss()
  }

  tog_large() {
    this.setState(prevState => ({
      modal_large: !prevState.modal_large,
    }))
    this.removeBodyCss()
  }

  tog_xlarge() {
    this.setState(prevState => ({
      modal_xlarge: !prevState.modal_xlarge,
    }))
    this.removeBodyCss()
  }

  tog_small() {
    this.setState(prevState => ({
      modal_small: !prevState.modal_small,
    }))
    this.removeBodyCss()
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center,
    }))
    this.removeBodyCss()
  }

  tog_scroll() {
    this.setState(prevState => ({
      modal_scroll: !prevState.modal_scroll,
    }))
    this.removeBodyCss()
  }

  show() {
    this.setState({ visible: true })
  }

  hide() {
    this.setState({ visible: false })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { appointments } = this.props;
    if (!isEmpty(appointments) && size(prevProps.appointments) !== size(appointments)) {
      this.setState({ appointments: {}, isEdit: false, changeReject: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ appointment: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const appointment = arg;
  
    this.setState({
      appointment: {
    
        id:appointment.id,
        appointmentId: appointment.appointmentId,
        appointmentTitle:appointment.appointmentTitle,
        contactName: appointment.contactName,
        contactPhone: appointment.contactPhone,
        contactEmail: appointment.contactEmail,
        appointmentStartDate: appointment.appointmentStartDate,
        startTime: appointment.startTime,
        endTime: appointment.endTime,
        appointmentType: appointment.appointmentType,
        appointmentStatus: appointment.appointmentStatus,
        appointmentPriority: appointment.appointmentPriority,
        appointmentReminder: appointment.appointmentReminder,
        clientId: appointment.clientId,
        appointmentDescription:appointment.appointmentDescription,
        appointmentLocationType:appointment.appointmentLocationType,
        appointmentLocation:appointment.appointmentLocation,
        locationLatitude:appointment.locationLatitude,
        locationLongitude:appointment.locationLongitude,
        isRepeat:appointment.isRepeat,
        repeatEveryType:appointment.repeatEveryType,
        repeatStep:appointment.repeatStep,
        repeatEndDate:appointment.repeatEndDate,
        agentId:appointment.agentId,
        isActive:appointment.isActive,
        contactNotes: appointment.contactNotes,
        addedBy: appointment.addedBy
      },
      isEdit: true,
    });
    this.toggle();
  };
  handleChange = arg =>  {
    const appointment = arg;
    const { onUpdateStatus } = this.props;
    const updateReg = {
      id:appointment.id,
      appointmentId: appointment.appointmentId,
      rejected: (appointment.rejected==true) ? false : true,
      appointmentTitle:appointment.appointmentTitle,
        contactName: appointment.contactName,
        contactPhone: appointment.contactPhone,
        contactEmail: appointment.contactEmail,
        appointmentStartDate: appointment.appointmentStartDate,
        startTime: appointment.startTime,
        endTime: appointment.endTime,
        appointmentType: appointment.appointmentType,
        appointmentStatus: appointment.appointmentStatus,
        appointmentPriority: appointment.appointmentPriority,
        appointmentReminder: appointment.appointmentReminder,
        clientId: appointment.clientId,
        appointmentDescription:appointment.appointmentDescription,
        appointmentLocationType:appointment.appointmentLocationType,
        appointmentLocation:appointment.appointmentLocation,
        locationLatitude:appointment.locationLatitude,
        locationLongitude:appointment.locationLongitude,
        isRepeat:appointment.isRepeat,
        repeatEveryType:appointment.repeatEveryType,
        repeatStep:appointment.repeatStep,
        repeatEndDate:appointment.repeatEndDate,
        agentId:appointment.agentId,
        isActive:appointment.isActive,
        contactNotes: appointment.contactNotes,
        addedBy: appointment.addedBy
    }

    onUpdateStatus(updateReg);
  };
  render() {
    const { appointments, onUpdateClientAppointment } = this.props;
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const edTime = new Date(now);
    const selectedValue = '';
    const { isEdit, deleteModal } = this.state;
    const appointment = this.state.appointment;
    return (
      <React.Fragment>
         {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteAppointment}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>Client Appointment | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Appointment")}
              breadcrumbItem={this.props.t("Appointment")}
            />
            <Row>
              
              
              <Col xl="12">
              <Card> 
                    <CardBody>
              <Formik
                    enableReinitialize={true}
                    initialValues={{
                      appointmentTitle: (this.state && this.state.appointmentTitle) || "",
                      appointmentStartDate: (this.state && this.state.appointmentStartDate) || "",
                      startTime: (this.state && this.state.startTime) || "",
                      endTime: (this.state && this.state.endTime) || "",
                      contactName: "Mohit Grover",
                      contactPhone: '1234567890',
                      contactEmail: 'mohit@esimplified.ca',
                      clientId: localStorage.getItem('userId'),
                      agentId: localStorage.getItem('agentId')

                      
                    }}


                    validationSchema={Yup.object().shape({
                      appointmentTitle: Yup.string().required("This is Required"),
                      appointmentStartDate: Yup.string().required("This is Required"),
                      startTime: Yup.string().required("This is Required"),
                      endTime: Yup.string().required("This is Required")
                    })}

                    onSubmit={this.handelValidAppointment}
                  >
                    {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <Row>
                          <Col md="5">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Agenda")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                value={values.appointmentTitle}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentStartDate">
                                {this.props.t("Date")}
                              </Label>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.appointmentStartDate && touched.appointmentStartDate
                                    ? " is-invalid"
                                    : "")
                                }
                                
                                
                                onChange={(value) => setFieldValue('appointmentStartDate', value[0])}
                                options={{
                                  altInput: false,
                                  minDate: "today",
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}
                              />
                              <ErrorMessage
                                name="appointmentStartDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup className="mb15">

                              <Label htmlFor="startTime">
                                {this.props.t("Start Time")}
                              </Label>
                              <InputGroup>
                                <Flatpickr
                                  className={
                                    "form-control" +
                                    (errors.startTime && touched.startTime
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Select time"
                                 
                                  onChange={(value) => setFieldValue('startTime', value[0])}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "h:i"
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                  </span>
                                </div>
                                <ErrorMessage
                                name="startTime"
                                component="div"
                                className="invalid-feedback"
                              />   
                              </InputGroup>
                              
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup className="mb15">

                              <Label htmlFor="endTime">
                                {this.props.t("End Time")}
                              </Label>
                              <InputGroup>
                                <Flatpickr
                               className={
                                "form-control" +
                                (errors.endTime && touched.endTime
                                  ? " is-invalid"
                                  : "")
                              }
                                  placeholder="Select time"
                                  onChange={(value) => setFieldValue('endTime', value[0])}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "h:i"
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                  </span>
                                </div>
                                <ErrorMessage
                                name="endTime"
                                component="div"
                                className="invalid-feedback"
                              />   
                              </InputGroup>

                            </FormGroup>
                          </Col>

                          <Col md="2">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentType">
                                {this.props.t("Appointment Type")}
                              </Label>
                              <Field as="select" name="appointmentType" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appTypes
                                    .map(appType =>
                                      <option key={appType.id} value={appType.id}>{appType.appointmentTypeTitle}</option>
                                    )
                                }
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="2">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentPriority">
                                {this.props.t("Priority")}
                              </Label>
                              <Field as="select" name="appointmentPriority" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appPriories
                                    .map(appPriorie =>
                                      <option key={appPriorie.id} value={appPriorie.id}>{appPriorie.appointmentPriorityTitle}</option>
                                    )
                                }
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="3">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentLocationType">
                                {this.props.t("Location Type")}
                              </Label>
                              <Field as="select" name="appointmentLocationType" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appLocTypes
                                    .map(appLocType =>
                                      <option key={appLocType.id} value={appLocType.id}>{appLocType.appointmentLocationTypeTitle}</option>
                                    )
                                }
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="3">
                            <FormGroup className="mb15">
                              {(() => {
                                if (selectedValue == 3) {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Location")}
                                      </Label>
                                     
                                    </>
                                  )
                                } else if (selectedValue == 2) {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Phone")}
                                      </Label>
                                     
                                    </>
                                  )
                                } else {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Online Link / Address")}
                                      </Label>
                                      
                                    </>
                                  )
                                }
                              })()}
                              <Field
                              name="appointmentLocation"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.appointmentLocation && touched.appointmentLocation
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="appointmentLocation"
                            />
                               


                            </FormGroup>

                          </Col>
                        </Row>




                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>
                      </Form>
                    )}
                  </Formik>
            </CardBody>
            </Card>
		<Row>
				<Col sm="12">
					 <Card className="mini-stats-wid"> 
                    <CardBody>
                    <h5 className="card-title">Appointment History</h5>
					 <div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								
								<th>Agenda</th>
								<th>Date</th>
								<th>Start Time</th>
                <th>End Time</th>
                <th>Accept</th>
                <th>Action</th>
							  </tr>
							</thead>
							<tbody>
              {map(appointments, (appointment, appoint) => (
                  <tr key={"_app_" + appoint} className={appointment.rejected == false ? 'trreject' : '' } >
                     <td>{appointment.addedBy == 'client' ? <i className='bx bxs-cloud-upload icon-upload'></i> : <i className='bx bxs-cloud-download icon-download'></i> }  {appointment.appointmentTitle}</td>
                    <td><Moment format="D MMM YY">{appointment.appointmentStartDate}</Moment></td>
                    <td><Moment format="hh:mm A">{appointment.startTime}</Moment></td>
                    <td><Moment format="hh:mm A">{appointment.endTime}</Moment></td>
                    <td>{appointment.addedBy == 'agent' ? <Switch onChange={isActive => this.handleChange(appointment)} checked={appointment.rejected} /> : '' }</td>
                    <td>
                    <UncontrolledDropdown>
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(appointment)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(appointment)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                    
                    </td>
                  </tr>            
               ))}
							  
							</tbody>
						  </table>

            
						</div> 
					</CardBody>
					</Card>
				</Col>
			</Row>
      <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                    enableReinitialize={true}
                    initialValues={{
                      id:appointment.id,
                      appointmentId: appointment.appointmentId,
                      appointmentTitle:appointment.appointmentTitle,
                      contactName: appointment.contactName,
                      contactPhone: appointment.contactPhone,
                      contactEmail: appointment.contactEmail,
                      appointmentStartDate: appointment.appointmentStartDate,
                      startTime: appointment.startTime,
                      endTime: appointment.endTime,
                      appointmentType: appointment.appointmentType,
                      appointmentStatus: appointment.appointmentStatus,
                      appointmentPriority: appointment.appointmentPriority,
                      appointmentReminder: appointment.appointmentReminder,
                      clientId: appointment.clientId,
                      appointmentDescription:appointment.appointmentDescription,
                      appointmentLocationType:appointment.appointmentLocationType,
                      appointmentLocation:appointment.appointmentLocation,
                      locationLatitude:appointment.locationLatitude,
                      locationLongitude:appointment.locationLongitude,
                      isRepeat:appointment.isRepeat,
                      repeatEveryType:appointment.repeatEveryType,
                      repeatStep:appointment.repeatStep,
                      repeatEndDate:appointment.repeatEndDate,
                      agentId:appointment.agentId,
                      isActive:appointment.isActive,
                      contactNotes: appointment.contactNotes,
                      addedBy: appointment.addedBy

                      
                    }}


                    validationSchema={Yup.object().shape({
                      appointmentTitle: Yup.string().required(
                        "Please Enter Meeting Agenda"
                      )
                    })}

                    onSubmit={values => {
                      //console.log(isEdit);
                      if (isEdit) {
                        const updateApp = {
                          id:values.id,
                          appointmentId: values.appointmentId,
                          appointmentTitle:values.appointmentTitle,
                          contactName: values.contactName,
                          contactPhone: values.contactPhone,
                          contactEmail: values.contactEmail,
                          appointmentStartDate: values.appointmentStartDate,
                          startTime: values.startTime,
                          endTime: values.endTime,
                          appointmentType: (values.appointmentType) ? parseInt(values.appointmentType) : 0,
                          appointmentStatus: (values.appointmentStatus) ? parseInt(values.appointmentStatus) : 0,
                          appointmentPriority: (values.appointmentPriority) ? parseInt(values.appointmentPriority) : 0,
                          appointmentReminder: values.appointmentReminder,
                          clientId: values.clientId,
                          appointmentDescription:values.appointmentDescription,
                          appointmentLocationType:values.appointmentLocationType,
                          appointmentLocation:values.appointmentLocation,
                          locationLatitude:values.locationLatitude,
                          locationLongitude:values.locationLongitude,
                          isRepeat:values.isRepeat,
                          repeatEveryType:values.repeatEveryType,
                          repeatStep:values.repeatStep,
                          repeatEndDate:values.repeatEndDate,
                          agentId:values.agentId,
                          isActive:values.isActive,
                          contactNotes: values.contactNotes,
                          addedBy: values.addedBy
                        };
  
                        onUpdateClientAppointment(updateApp);
                      } else {
                        
                      }
                      this.toggle();
                    }}
                  >
                    {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Agenda")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                value={values.appointmentTitle}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentStartDate">
                                {this.props.t("Date")}
                              </Label>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.appointmentStartDate && touched.appointmentStartDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                value={values.appointmentStartDate}
                                onChange={(value) => setFieldValue('appointmentStartDate', value[0])}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}
                              />
                              <ErrorMessage
                                name="appointmentStartDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="startTime">
                                {this.props.t("Start Time")}
                              </Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select time"
                                  value={values.startTime}
                                  onChange={(value) => setFieldValue('startTime', value[0])}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "h:i"
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                  </span>
                                </div>
                              </InputGroup>

                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="endTime">
                                {this.props.t("End Time")}
                              </Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select time"
                                  value={values.endTime}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "h:i"
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                  </span>
                                </div>
                              </InputGroup>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentType">
                                {this.props.t("Appointment Type")}
                              </Label>
                              <Field as="select" name="appointmentType" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appTypes
                                    .map(appType =>
                                      <option key={appType.id} value={appType.id}>{appType.appointmentTypeTitle}</option>
                                    )
                                }
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentPriority">
                                {this.props.t("Appointment Priority")}
                              </Label>
                              <Field as="select" name="appointmentPriority" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appPriories
                                    .map(appPriorie =>
                                      <option key={appPriorie.id} value={appPriorie.id}>{appPriorie.appointmentPriorityTitle}</option>
                                    )
                                }
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentLocationType">
                                {this.props.t("Location Type")}
                              </Label>
                              <Field as="select" name="appointmentLocationType" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appLocTypes
                                    .map(appLocType =>
                                      <option key={appLocType.id} value={appLocType.id}>{appLocType.appointmentLocationTypeTitle}</option>
                                    )
                                }
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">
                              {(() => {
                                if (selectedValue == 3) {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Location")}
                                      </Label>
                                     
                                    </>
                                  )
                                } else if (selectedValue == 2) {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Phone")}
                                      </Label>
                                     
                                    </>
                                  )
                                } else {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Online Link / Address")}
                                      </Label>
                                    
                                    </>
                                  )
                                }
                              })()}

<Field
                              name="appointmentLocation"
                              onChange={handleChange}                            
                              type="text"
                              
                              className="form-control"
                             
                              id="appointmentLocation"
                            />

                            </FormGroup>

                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">
                              <Label htmlFor="note">
                                {this.props.t("Note")}
                              </Label>
                              <Field as="textarea" name="contactNotes" className="form-control" onChange={handleChange}>
                               
                              </Field>

                            </FormGroup>
                          </Col>
                        </Row>




                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Update")}</button></div>
                      </Form>
                    )}
                  </Formik>
                      </OffcanvasBody>
                      </Offcanvas>   
			{/* <Calender /> */}
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientApp.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientAppointment:PropTypes.func,
  onGetClientApp:PropTypes.func,
  appointments:PropTypes.array,
  onDeleteAppointment:PropTypes.func,
  onUpdateClientAppointment: PropTypes.func,
  loading:PropTypes.object,
  onRejectedChange:PropTypes.func,
  onUpdateStatus:PropTypes.func

}
const mapStateToProps = ({ Appointments }) => (
  {
    appointments:Appointments.appointments,
    loading:Appointments.loading

  })
const mapDispatchToProps = dispatch => ({
  addClientAppointment: (data) => dispatch(addClientAppointment(data)),
  onGetClientApp:(agentId,clientId) => dispatch(getClientAppointment(agentId,clientId)),
  onDeleteAppointment:(id) => dispatch(deleteClientAppointment(id)),
  onUpdateClientAppointment: (data) => dispatch(updateClientAppointment(data)),
  onUpdateStatus:(data) =>  dispatch(updateClientAppointment(data)),
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientApp))
