import { CREATE_CLIENT_SELLER_SHOWING, CREATE_CLIENT_SELLER_SHOWING_FAIL, CREATE_CLIENT_SELLER_SHOWING_SUCCESS, 
  DELETE_CLIENTS_SELLER_SHOWING, 
  DELETE_CLIENTS_SELLER_SHOWING_FAIL, 
  DELETE_CLIENTS_SELLER_SHOWING_SUCCESS, 
  GET_CLIENTS_SELLER_LISTING, GET_CLIENTS_SELLER_LISTING_FAIL, GET_CLIENTS_SELLER_LISTING_SUCCESS, 
  GET_CLIENTS_SELLER_SHOWING, GET_CLIENTS_SELLER_SHOWING_FAIL, 
  GET_CLIENTS_SELLER_SHOWING_SUCCESS, 
  UPDATE_CLIENTS_SELLER_SHOWING,
  UPDATE_CLIENTS_SELLER_SHOWING_FAIL,
  UPDATE_CLIENTS_SELLER_SHOWING_SUCCESS} from "./actionTypes"

export const getClientSellerListing = (clientId) => (
  {
    type: GET_CLIENTS_SELLER_LISTING,
    clientId
  })

export const getClientSellerListingSuccess = listings => ({
  type: GET_CLIENTS_SELLER_LISTING_SUCCESS,
  payload: listings,
})

export const getClientSellerListingFail = error => ({
  type: GET_CLIENTS_SELLER_LISTING_FAIL,
  payload: error,
})

export const addClientSellerShwoing = (showing, history) => ({
  type: CREATE_CLIENT_SELLER_SHOWING,
  payload: { showing, history }
})

export const addClientSellerShwoingSuccess = showing => ({
  type: CREATE_CLIENT_SELLER_SHOWING_SUCCESS,
  payload: showing
})

export const addClientSellerShwoingFail = error => ({
  type: CREATE_CLIENT_SELLER_SHOWING_FAIL,
  payload: error
})


export const getClientSellerShwoing = (clientId) => (
  {
    type: GET_CLIENTS_SELLER_SHOWING,
    clientId
  })

export const getClientSellerShwoingSuccess = showings => ({
  type: GET_CLIENTS_SELLER_SHOWING_SUCCESS,
  payload: showings,
})

export const getClientSellerShwoingFail = error => ({
  type: GET_CLIENTS_SELLER_SHOWING_FAIL,
  payload: error,
})


export const deleteClientShowing = id => ({
  type: DELETE_CLIENTS_SELLER_SHOWING,
  payload: id,
})

export const deleteClientShowingSuccess = showing => ({
  type: DELETE_CLIENTS_SELLER_SHOWING_SUCCESS,
  payload: showing,
})

export const deleteClientShowingFail = error => ({
  type: DELETE_CLIENTS_SELLER_SHOWING_FAIL,
  payload: error,
})


export const updateClientSellerShowing = showing => {

  return {
    type: UPDATE_CLIENTS_SELLER_SHOWING,
    payload: showing,
  }
}

export const updateClientSellerShowingSuccess = showing => {
  return {
    type: UPDATE_CLIENTS_SELLER_SHOWING_SUCCESS,
    payload: showing,
  }
}

export const updateClientSellerShowingError = error => {
  return {
    type: UPDATE_CLIENTS_SELLER_SHOWING_FAIL,
    payload: error,
  }
}