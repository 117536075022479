import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"

import {
  Breadcrumb, BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button
} from "reactstrap"
import img1 from "../../assets/images/sidebar/img1.jpg"
import primg1 from "../../assets/images/project/img1.jpg"
import primg2 from "../../assets/images/project/img2.jpg"
import primg3 from "../../assets/images/project/img3.jpg"
import primg4 from "../../assets/images/project/img4.jpg"

import primg5 from "../../assets/images/project/img5.jpg"
import primg6 from "../../assets/images/project/img6.jpg"
import primg7 from "../../assets/images/project/img7.jpg"
import primg8 from "../../assets/images/project/img8.jpg"

import appointment from "../../assets/images/rsicons/appointment.png"
import activiti from "../../assets/images/rsicons/activities.png"
import call from "../../assets/images/rsicons/call.png"
import notes from "../../assets/images/rsicons/notes.png"
import todo from "../../assets/images/rsicons/to-do.png"
import Breadcrumbs from "../../components/Common/Breadcrumb"
class myProject extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Row>
          <Col xs="12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <div className=" d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18"> My Interest </h4><Link to="/project-compare"> <Button className="btn btn-primary ms-4">Compare properties</Button></Link>
              </div>
              <div className="page-title-right">
                <Breadcrumb listClassName="m-0">
                  <BreadcrumbItem>
                    <Link to="#">Project</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">My Interest</Link>
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>
          </Col>
        </Row>


           

            

            <Row>
              <Col sm="3">
                <div className="product">
                    <div className="pro-img">
                        <div className="icon-check"><i className=" bx bx-check-shield"></i></div>
                        <img src={primg1} className="img-fluid" />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">Toronto, ON</h5>
                        <h4 className="prd-title"><Link to="/project-detail/34/10da1bf6-7e35-4c83-8435-53072057f347">Liberty Market Tower</Link>  <span className="float-end"><input type="checkbox" className="project-checkbox" /><Link to="/project-compare"><i className="compare-ico bx bx-git-compare "></i></Link></span></h4>
                    </div>
                </div>

              </Col>

              

              <Col sm="3">
                <div className="product">
                    <div className="pro-img">
                        <div className="icon-check"><i className=" bx bx-check-shield"></i></div>
                        <img src={primg3} className="img-fluid" />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">Toronto, ON</h5>
                        <h4 className="prd-title">Central Park Tower  <span className="float-end"><input type="checkbox" className="project-checkbox" /><Link to="/project-compare"><i className="compare-ico bx bx-git-compare "></i></Link></span></h4>
                    </div>
                </div>

              </Col>
              <Col sm="3">
                <div className="product">
                    <div className="pro-img">
                        <div className="icon-check"><i className=" bx bx-check-shield"></i></div>
                        <img src={primg7} className="img-fluid" />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">Toronto, ON</h5>
                        <h4 className="prd-title"> The West   <span className="float-end"><input type="checkbox" className="project-checkbox" /><Link to="/project-compare"><i className="compare-ico bx bx-git-compare "></i></Link></span></h4>
                    </div>
                </div>

              </Col>
              <Col sm="3">
                <div className="product">
                    <div className="pro-img">
                        <div className="icon-check"><i className=" bx bx-check-shield"></i></div>
                        <img src={primg5} className="img-fluid" />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">Toronto, ON</h5>
                        <h4 className="prd-title"><Link to="/project-detail">Country Pointe Meadows at Yaphank </Link>  <span className="float-end"><input type="checkbox" className="project-checkbox" /><Link to="/project-compare"><i className="compare-ico bx bx-git-compare "></i></Link></span></h4>
                    </div>
                </div>

              </Col>
            </Row>


           
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default myProject;
