import { GET_CLIENT_ADD_LISTING, GET_CLIENT_ADD_LISTING_FAIL, GET_CLIENT_ADD_LISTING_SUCCESS } from "./actionTypes"

const initialState = {

  error: "",
  loading: true,
  addListings: []


}

const addListing = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_ADD_LISTING:
      return {
        ...state,
        loading: true,
      }
    case GET_CLIENT_ADD_LISTING_SUCCESS:
      return {
        ...state,
        addListings: action.payload,
        loading: false
      }
    case GET_CLIENT_ADD_LISTING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      state = { ...state }
      break

  }
  return state
}

export default addListing
