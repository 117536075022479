import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import 'react-rangeslider/lib/index.css'

import {  Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Collapse,
  FormGroup,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  Form,
  Input,
  
} from "reactstrap"
import profileImg from "../../assets/images/profile-img.png"
import classnames from "classnames"
import { AvField, AvForm } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

class mySource extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
      
    }

    
  }
 
  render() {
   
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Professional Services | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Buyer")}
              breadcrumbItem={this.props.t("Professional Services")}
            />
         
          <Row>
              <Col sm="12">
                  <Card>
                      <CardBody>
                     
<div className="table-responsive">
<table className="table align-middle table-nowrap mb-0">
 <thead className="table-light">
   <tr>
     
     <th className="align-middle">Service</th>
     <th className="align-middle">Organization</th>
     <th className="align-middle">Contact</th>
     
     <th className="align-middle">Email</th>
     <th className="align-middle">Phone</th>
     
   </tr>
 </thead>
 <tbody>
   <tr>
       <td>Moving</td>
       <td>S Packer</td>
       <td> Harry Smith </td>
       <td> abc@abc.com </td>
       <td>4161231234</td>
   </tr>
   <tr>
       <td>HandyMan </td>
       <td></td>
       <td> George Kyle </td>
       <td> abc@abc.com </td>
       <td>4161231234</td>
   </tr>
   <tr>
       <td>Painter </td>
       <td></td>
       <td> Paul Man </td>
       <td>  </td>
       <td>4161231234</td>
   </tr>
 </tbody>
</table>
</div>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
mySource.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(mySource))
