import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Login Method
const postLogin = data => post(url.POST_CLIENT_LOGIN, data)
const postClientMessageAPI = data => post(url.POST_CLIENT_MESSAGE_API_URL, data)
export const getClientMessageAPI = ClientId => get(`${url.GET_CLIENT_MESSAGE_API_URL}/`, { params: { ClientId } })


const postClientDocumentAPI = data => post(url.POST_CLIENT_DOCUMENT_API_URL, data)
export const getClientDocumentAPI = ClientId => get(`${url.GET_CLIENT_DOCUMENT_API_URL}/`, { params: { ClientId } })
export const getClientPrefQuestAPI = forType => get(`${url.GET_CLIENT_PREF_QUEST_API_URL}/`, { params: { forType } })
export const getAllProjectAPI = agentId => get(`${url.GET_ALL_PROJECT_API_URL}/`, { params: { agentId } })
export const getAllProjectDetailAPI = projectId => get(`${url.GET_PROJECT_DETAIL_API_URL}/`, { params: { projectId } })
const postProjetInterestAPI = data => post(url.POST_PROJECT_INTEREST_API_URL, data)
export const getProjetInterestAPI = ClientId => get(`${url.GET_PROJECT_INTEREST_API_URL}/`, { params: { ClientId } })

export const postClientSellerShowing = data => post(url.POST_CLIENT_SELLER_SHOWING_API_URL, data)
export const getClientSellerShowingUrl = clientId => get(`${url.GET_CLIENT_SELLER_SHOWING_API}/`, { params: { clientId } })
export const deleteClientShowingAPI   = id =>   del(`${url.DELETE_CLIENT_SHOWING_API}/${id}`)
export const updateClientSellerShowingAPI = data => put(url.UPDATE_CLIENT_SELLER_SHOWING_API_URL, data)

export const getClientSellerListingUrl = clientId => get(`${url.GET_CLIENT_SELLER_LISTING_API}/`, { params: { clientId } })
const postClientAppointment = data => post(url.POST_CLIENT_APPOINTMENT_API, data)
export const getClientAppointment = (agentId, clientId) => get(`${url.GET_CLIENT_APPOINTMENT_API}/`, { params: { agentId, clientId } })
export const deleteClientAppointmentAPI   = id =>   del(`${url.DELETE_CLIENT_APPOINTMENT_API}/${id}`)
const postBuyerDestinationUrl = data => post(url.POST_BUYER_DESTINATION_API, data)

export const getProjectFeatApi = projectId => get(`${url.GET_PROJECT_FEATURE_API_URL}/`, { params: { projectId } })
export const getProjectLinkApi            = projectId => get(`${url.GET_PROJECT_LINK_API_URL}/`, { params: { projectId } })
export const getProjectGalleryApi            = projectId => get(`${url.GET_PROJECT_GALLERY_API_URL}/`, { params: { projectId } })


export const postClientAddListing = data => post(url.POST_CLIENT_ADD_LISTING_API, data)
export const getClientAddListingUrl  = (clientId, agentId ) => get(`${url.GET_CLIENT_ADD_LISTING_API}/`, { params: { clientId, agentId } })
export const deleteClientDocumentAPI   = id =>   del(`${url.DELETE_CLIENT_DOCUMENT_API}/${id}`)
export const updateClientDocumnetAPI   = document => put(url.UPDATE_CLIENT_DOCUMENT_API, document)

export const updateClientAppointmentAPI = data => put(url.UPDATE_CLIENT_APPOINTMENT_API, data)

export const getClientDetailUrl= clientId => get(`${url.GET_CLIENT_DETAIL_API}${clientId}`)
export const updateClientProfileAPI   = data => put(url.UPDATE_CLIENT_PROFILE_API, data)
export const updateClientPrimaryConatctAPI   = data => put(url.UPDATE_CLIENT_PRIMARY_CONTACT_API, data)
export const updateClientSecondaryConatctAPI   = data => put(url.UPDATE_CLIENT_SECONDARY_CONTACT_API, data)
export const postClientImportantDateAPI = data => post(url.CREATE_CLIENT_IMPORTANT_DATE_URL, data)
export const getClientImportantDateAPI = clientId => get(`${url.GET_CLIENT_IMPORTANT_DATE_URL}/`, { params: { clientId } })
export const deleteClientImportantDateAPI   = id =>   del(`${url.DELETE_CLIENT_IMPORTANT_DATE_URL}/${id}`)
export const postClientChildAPI = data => post(url.CREATE_CLIENT_CHILD_URL, data)
export const getClientChildAPI = clientId => get(`${url.GET_CLIENT_CHILD_URL}/`, { params: { clientId } })

export const getClientUpcompingActivitiesAPI =  (clientId, agentId) => get(`${url.GET_CLIENT_UPCOMING_ACTIVITIES_API}/`, { params: { clientId, agentId } })
export const getClientRecentActivitiesAPI = (clientId, agentId) => get(`${url.GET_CLIENT_RECENT_ACTIVITIES_API}/`, { params: { clientId, agentId } })

export const postHomePrefUrl = data => post(url.POST_HOME_DESTINATION_API, data)
export const getHomePrefUrl= clientId => get(`${url.GET_HOME_DESTINATION_API}/`, { params: { clientId } })
export const updateHomePrefUrl = data => put(url.UPDATE_HOME_DESTINATION_API, data)
export const deleteHomePrefUrl = id => del(`${url.DELETE_HOME_DESTINATION_API}/${id}`)



export const postImpDestUrl = data => post(url.POST_IMP_DESTINATION_API, data)
export const getImpDestUrl= clientId => get(`${url.GET_IMP_DESTINATION_API}`, { params: { clientId } })

export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  postClientMessageAPI,
  postClientDocumentAPI,
  postProjetInterestAPI,
  postClientAppointment,
  postBuyerDestinationUrl

}