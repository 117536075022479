import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import img1 from "../../assets/images/project/img1.jpg"
import img2 from "../../assets/images/project/img2.jpg"
import img3 from "../../assets/images/project/img3.jpg"

import {
  Container,
  Row,
  Col,
  Card, 
  CardBody,
  
} from "reactstrap"

class ProjectComapre extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Breadcrumbs
              title={this.props.t("Project")}
              breadcrumbItem={this.props.t("Project Compare")}
            />
            <Row>
                <Col lg="12">
                <Card>
                <CardBody>
                <div className="keyDifferences">
                <table data-gsco-table="true" data-gsco-wrap="true" className="container" data-track-component="CompareBox">
  <thead className="gsc_co_open">
    <tr>
   
      <th className="headingOverview comparefixedO"> Basic Information</th>
      <th className=" headingOverview hidden-sm hidden-xs compareThreeBlock"> <img src={img1} className="img-fuild" /><br />Liberty Market Tower </th>
      <th className=" headingOverview hidden-sm hidden-xs compareThreeBlock"> <img src={img2} className="img-fuild" /><br />Central Park Tower </th>
      <th className=" headingOverview hidden-sm hidden-xs compareThreeBlock"> <img src={img3} className="img-fuild" /><br />Manhattan View </th>
    </tr>
  </thead>
  <tbody data-gsco-content="true" className="differencesPanel" data-track-section="overview">
    <tr className="  " data-common-row="false">
   
      <td className="comparefixedO leftPanel" data-responsivecell="true">Builder</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">Lifetime Developments</div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext"> Extell Development Company</div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext"><span className="link hover">SCG America</span></div></td>
    </tr>
    <tr className="  " data-common-row="false">

      <td className="comparefixedO leftPanel" data-responsivecell="true">Type</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">Condo</div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">Condo</div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">Condo</div></td>
    </tr>

    <tr className="  " data-common-row="false">
 
      <td className="comparefixedO leftPanel" data-responsivecell="true">Completed</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">Nov 2021</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">2020</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">2017</td>
    </tr>
    <tr className="  " data-common-row="false">

<td className="comparefixedO leftPanel" data-responsivecell="true">Price/SqFt</td>
<td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">$1264</td>
<td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">$6598</td>
<td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">$2812 </td>
</tr>
<tr className="  " data-common-row="false">
  
      <td className="comparefixedO leftPanel" data-responsivecell="true">Units</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">281 Units | 
28 Stories
          <div>
            <div className=" buttonHolder"></div>
          </div>
        </div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">179 Units |
131 Stories
        <div>
            <div className=" buttonHolder"></div>
          </div>
        </div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext"><span className="">147 Units |
63 Stories</span>
          <div>
            <div className=" buttonHolder"></div>
          </div>
        </div></td>
    </tr>
    <tr className="  " data-common-row="false">
 
      <td className="comparefixedO leftPanel" data-responsivecell="true">Price</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">From                                                $699,995                                            to over                                                $1,689,900</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">From                                                                                                                                              $7,450,000                                            to                                                $63,750,000</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">From                                                                                                                                              $1,500,000                                            to                                                $3,500,000</td>
    </tr>
   

    
    
    <tr className="  " data-common-row="false">
   
      <td className="comparefixedO leftPanel" data-responsivecell="true">Area</td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">605 - 1195 SqFt</div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">1435 - 7074 SqFt</div></td>
      <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">568 - 1266 SqFt</div></td>
    </tr>
    <tr className="  " data-common-row="false">
 
 <td className="comparefixedO leftPanel" data-responsivecell="true">Bedrooms</td>
 <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">1 - 3.5</td>
 <td className="compareThreeBlock rightPanel " data-responsivecelltwo="true">2 - 8</td>
 <td className="compareThreeBlock rightPanel" data-responsivecelltwo="true"><i className="icon-deletearrow">0 - 4</i></td>
</tr>
    
   
  </tbody>
</table>
</div>

                    </CardBody>
                </Card>
                </Col>
                
            </Row>
            
           
            
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ProjectComapre.propTypes = {
    t: PropTypes.any
  
  }

export default connect()(withTranslation()(ProjectComapre))
