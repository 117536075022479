import { CREATE_CLIENT_APPOINTMENT_FAIL, CREATE_CLIENT_APPOINTMENT_SUCCESS, DELETE_CLIENT_APPOINTMENT_FAIL, DELETE_CLIENT_APPOINTMENT_SUCCESS, GET_CLIENTS_APPOINTMENT, GET_CLIENTS_APPOINTMENT_FAIL, GET_CLIENTS_APPOINTMENT_SUCCESS, UPDATE_CLIENT_APPOINTMENT_FAIL, UPDATE_CLIENT_APPOINTMENT_SUCCESS } from "./actionTypes"

const initialState = {

  error: "",
  loading: true,
  appointments: [],
}

const Appointments = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CLIENT_APPOINTMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case CREATE_CLIENT_APPOINTMENT_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
      case GET_CLIENTS_APPOINTMENT:
        return {
          ...state,
          loading:true
        }
    case GET_CLIENTS_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointments: action.payload,
        loading:false
      }
    case GET_CLIENTS_APPOINTMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      case DELETE_CLIENT_APPOINTMENT_SUCCESS:
        return {
          ...state,
          appointment: action.payload,
        }
  
      case DELETE_CLIENT_APPOINTMENT_FAIL:
        return {
          ...state,
          error: action.payload,
        }
        case UPDATE_CLIENT_APPOINTMENT_SUCCESS:
          return {
            ...state,
            appointments: state.appointments.map(appointment =>
              appointment.id.toString() === action.payload.id.toString()
                ? { appointment, ...action.payload }
                : appointment
            ),
          }
    
        case UPDATE_CLIENT_APPOINTMENT_FAIL:
          return {
            ...state,
            error: action.payload,
          }
    default:
      state = { ...state }
      break

  }
  return state
}

export default Appointments
