import { CREATE_BUY_DESTINATION, CREATE_BUY_DESTINATION_FAIL, CREATE_BUY_DESTINATION_SUCCESS, CREATE_HOME_DESTINATION, CREATE_HOME_DESTINATION_FAIL, CREATE_HOME_DESTINATION_SUCCESS, CREATE_IMP_DESTINATION, CREATE_IMP_DESTINATION_FAIL, CREATE_IMP_DESTINATION_SUCCESS, DELETE_HOME_DESTINATION, DELETE_HOME_DESTINATION_FAIL, DELETE_HOME_DESTINATION_SUCCESS, GET_BUY_PREFRENCES_QUEST, GET_BUY_PREFRENCES_QUEST_FAIL, GET_BUY_PREFRENCES_QUEST_SUCCESS, GET_HOME_DESTINATION, GET_HOME_DESTINATION_FAIL, GET_HOME_DESTINATION_SUCCESS, GET_IMP_DESTINATION, GET_IMP_DESTINATION_FAIL, GET_IMP_DESTINATION_SUCCESS, UPDATE_HOME_DESTINATION, UPDATE_HOME_DESTINATION_FAIL, UPDATE_HOME_DESTINATION_SUCCESS } from "./actionTypes"

export const getClientPrefrencesQuest = forType => (
  {
    type: GET_BUY_PREFRENCES_QUEST,
    forType,
  })

export const getClientPrefrencesQuestSuccess = questions => ({
  type: GET_BUY_PREFRENCES_QUEST_SUCCESS,
  payload: questions,
})

export const getClientPrefrencesQuestFail = error => ({
  type: GET_BUY_PREFRENCES_QUEST_FAIL,
  payload: error,
})


export const postBuyerDestination = (destination, history) => ({
  type: CREATE_BUY_DESTINATION,
  payload: { destination, history }
})

export const postBuyerDestinationSuccess = document => ({
  type: CREATE_BUY_DESTINATION_SUCCESS,
  payload: document
})

export const postBuyerDestinationFail = error => ({
  type: CREATE_BUY_DESTINATION_FAIL,
  payload: error
})


export const postHomePref = (homepref, history) => ({
  type: CREATE_HOME_DESTINATION,
  payload: { homepref, history }
})

export const postHomePrefSuccess = homepref => ({
  type: CREATE_HOME_DESTINATION_SUCCESS,
  payload: homepref
})

export const postHomePrefFail = error => ({
  type: CREATE_HOME_DESTINATION_FAIL,
  payload: error
})


export const getHomePref = ClientId => (
  {
    type: GET_HOME_DESTINATION,
    ClientId,
  })

export const getHomePrefSuccess = homeprefrences => ({
  type: GET_HOME_DESTINATION_SUCCESS,
  payload: homeprefrences,
})

export const getHomePrefFail = error => ({
  type: GET_HOME_DESTINATION_FAIL,
  payload: error,
})


export const updateHomePref = homepref => (
  {

    type: UPDATE_HOME_DESTINATION,
    payload: homepref,
  })

export const updateHomePrefSuccess = homeprefrence => ({
  type: UPDATE_HOME_DESTINATION_SUCCESS,
  payload: homeprefrence,
})

export const updateHomePrefFail = error => ({
  type: UPDATE_HOME_DESTINATION_FAIL,
  payload: error,
})


export const deleteHomePref = id => ({
  type: DELETE_HOME_DESTINATION,
  payload: id,
})

export const deleteHomePrefSuccess = homeprefrence => ({
  type: DELETE_HOME_DESTINATION_SUCCESS,
  payload: homeprefrence,
})

export const deleteLeadStageFail = error => ({
  type: DELETE_HOME_DESTINATION_FAIL,
  payload: error,
})


export const postImpDestination = (impdes, history) => ({
  type: CREATE_IMP_DESTINATION,
  payload: { impdes, history }
})

export const postImpDestinationSuccess = impdes => ({
  type: CREATE_IMP_DESTINATION_SUCCESS,
  payload: impdes
})

export const postImpDestinationFail = error => ({
  type: CREATE_IMP_DESTINATION_FAIL,
  payload: error
})


export const getImpDestination = clientId => (
  {
    type: GET_IMP_DESTINATION,
    clientId,
  })

export const getImpDestinationSuccess = impdestinations => ({
  type: GET_IMP_DESTINATION_SUCCESS,
  payload: impdestinations,
})

export const getImpDestinationFail = error => ({
  type: GET_IMP_DESTINATION_FAIL,
  payload: error,
})
