/* CLIENTs */
export const POST_CLIENT_DOCUMENT = "POST_CLIENT_DOCUMENT"
export const POST_CLIENT_DOCUMENT_SUCCESS = "POST_CLIENT_DOCUMENT_SUCCESS"
export const POST_CLIENT_DOCUMENT_FAIL = "POST_CLIENT_DOCUMENT_FAIL"


export const GET_CLIENTS_DOCUMENT = "GET_CLIENTS_DOCUMENT"
export const GET_CLIENTS_DOCUMENT_SUCCESS = "GET_CLIENTS_DOCUMENT_SUCCESS"
export const GET_CLIENTS_DOCUMENT_FAIL = "GET_CLIENTS_DOCUMENT_FAIL"


export const DELETE_CLIENTS_DOCUMENT = "DELETE_CLIENTS_DOCUMENT"
export const DELETE_CLIENTS_DOCUMENT_SUCCESS = "DELETE_CLIENTS_DOCUMENT_SUCCESS"
export const DELETE_CLIENTS_DOCUMENT_FAIL = "DELETE_CLIENTS_DOCUMENT_FAIL"


export const UPDATE_CLIENTS_DOCUMENT = "UPDATE_CLIENTS_DOCUMENT"
export const UPDATE_CLIENTS_DOCUMENT_SUCCESS = "UPDATE_CLIENTS_DOCUMENT_SUCCESS"
export const UPDATE_CLIENTS_DOCUMENT_FAIL = "UPDATE_CLIENTS_DOCUMENT_FAIL"










