import { DELETE_CLIENTS_DOCUMENT, DELETE_CLIENTS_DOCUMENT_FAIL, DELETE_CLIENTS_DOCUMENT_SUCCESS, GET_CLIENTS_DOCUMENT, GET_CLIENTS_DOCUMENT_FAIL, GET_CLIENTS_DOCUMENT_SUCCESS, POST_CLIENT_DOCUMENT, POST_CLIENT_DOCUMENT_FAIL, POST_CLIENT_DOCUMENT_SUCCESS, UPDATE_CLIENTS_DOCUMENT, UPDATE_CLIENTS_DOCUMENT_FAIL, UPDATE_CLIENTS_DOCUMENT_SUCCESS } from "./actionTypes"


export const postClientDocument = (document, history) => ({
  type: POST_CLIENT_DOCUMENT,
  payload: { document, history }
})

export const postClientDocumentSuccess = document => ({
  type: POST_CLIENT_DOCUMENT_SUCCESS,
  payload: document
})

export const postClientDocumentFail = error => ({
  type: POST_CLIENT_DOCUMENT_FAIL,
  payload: error
})


export const getClientDocument = clientId => (
  {
    type: GET_CLIENTS_DOCUMENT,
    clientId,
  })

export const getClientDocumentSuccess = documents => ({
  type: GET_CLIENTS_DOCUMENT_SUCCESS,
  payload: documents,
})

export const getClientDocumentFail = error => ({
  type: GET_CLIENTS_DOCUMENT_FAIL,
  payload: error,
})


export const deleteClientDocument = id => ({
  type: DELETE_CLIENTS_DOCUMENT,
  payload: id,
})

export const deleteClientDocumentSuccess = document => ({
  type: DELETE_CLIENTS_DOCUMENT_SUCCESS,
  payload: document,
})

export const deleteClientDocumentFail = error => ({
  type: DELETE_CLIENTS_DOCUMENT_FAIL,
  payload: error,
})

export const updateClientDocument = document => {
  //console.log(appointment)
  return {
    type: UPDATE_CLIENTS_DOCUMENT,
    payload: document,
  }
}

export const updateClientDocumentSuccess = document => {
  return {
    type: UPDATE_CLIENTS_DOCUMENT_SUCCESS,
    payload: document,
  }
}

export const updateClientDocumentError = error => {
  return {
    type: UPDATE_CLIENTS_DOCUMENT_FAIL,
    payload: error,
  }
}