import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";


import Switch from "react-switch"
import { getClientDetail, addClientImportantDate, addClientChild, getClientImportantDate, getClientChild, deleteClientImportant, updateClientProfile} from "../../store/profile/actions"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
class ClientProfileNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      rows1: [],
      clientTypes:[],
      titles:[],
      importantdate:'',
      sources:[],
      reports: [
        { title: "Upcoming Closings", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "Upcoming appointments",
          iconClass: "bx-archive-in",
          description: "6",
        },
        {
          title: "Active Clients ",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Messages Received",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Investment interest",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
      ]
    }
    this.handleImportantDateSubmit = this.handleImportantDateSubmit.bind(this)
    this.handleChildSubmit = this.handleChildSubmit.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (importantdate) => {
    this.setState({ importantdate: importantdate });
    this.setState({ deleteModal: true });
  };
  handleDeleteImportantDate = () => {
    const { onDeleteImportantDate } = this.props;
    const { importantdate } = this.state;
    
    if (importantdate.id !== undefined) {
      onDeleteImportantDate(importantdate);
      this.setState({ deleteModal: false });
    }
  };
  handleImportantDateSubmit(value){
    this.props.addClientImportantDate(value, this.props.history);
  }

  handleChildSubmit(value){
    this.props.addClientChild(value, this.props.history);
  }


componentDidMount() {
  const { match: { params }, onGetClientDetail, onGetClientImportantDate, onGetClientChild } = this.props;
  onGetClientDetail(localStorage.getItem('userId'));
  onGetClientImportantDate(localStorage.getItem('userId'));
  onGetClientChild(localStorage.getItem('userId'))
 
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const token = obj.resultData.bearerToken;

  const str = 'Bearer ' + token;
  axios.get('https://rscoreapi.azurewebsites.net/api/LeadType/all', { headers: { Authorization: str } })
    .then(res => {
      const clientTypes = res.data;
      this.setState({ clientTypes });
    }),
    axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Agent:Title',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const titles = res.data;
          this.setState({ titles });
        }),
        axios.get('https://rscoreapi.azurewebsites.net/api/LeadSource/all', { headers: { Authorization: str } })
        .then(res => {
          const sources = res.data;
          this.setState({ sources });
        })
    
}
  render() {
    const { clietDetail, importantdates, childs, loading, onUpdateClientProfile } = this.props;
    const importantdate = this.state.importantdate;
    const {  deleteModal } = this.state;
    //console.log(loading);
    const Offsymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Inactive
        </div>
      )
    }

    const OnSymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Active
        </div>
      )
    }

    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Client Profile | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteImportantDate}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
       
          
            <Row>
              
              
              <Col xl="12">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: clietDetail.id,
                    clientId: clietDetail.clientId,
                    agentId:clietDetail.agentId,
                    userLogin:clietDetail.userLogin,
                    title:      clietDetail.title,
                    firstName: clietDetail.firstName,
                    lastName: clietDetail.lastName,
                    clientPropertyTypeId:clietDetail.clientPropertyTypeId,
                    clientTypeId: clietDetail.clientTypeId,
                    clientSourceId: clietDetail.clientSourceId,
                    primaryContactId:clietDetail.primaryContactId,
                    secondaryContactId:clietDetail.secondaryContactId,
                    addressId:clietDetail.addressId,
                    isActive:clietDetail.isActive,
                    referedBy:clietDetail.referedBy,
                    howOffenClient:clietDetail.howOffenClient,
                    notes:clietDetail.notes,

                   
                   

                  }}
                  validationSchema={Yup.object().shape({
                    clientTypeId: Yup.string().required("This is Required"),
                    title: Yup.string().required("This is Required"),
                    contactFName: Yup.string().required("This is Required"),
                    contactLName: Yup.string().required("This is Required"),
                   
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                   
                      const updateClient = {
                    id: values.id,
                    clientId: values.clientId,
                    agentId:values.agentId,
                    //userLogin:values.userLogin,
                    title:      values.title,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    clientPropertyTypeId:values.clientPropertyTypeId,
                    clientTypeId: values.clientTypeId,
                    clientSourceId: values.clientSourceId,
                    primaryContactId:values.primaryContactId,
                    secondaryContactId:values.secondaryContactId,
                    addressId:values.addressId,
                    isActive:values.isActive,
                    referedBy:values.referedBy,
                    howOffenClient:values.howOffenClient,
                    notes:values.notes,

                   
                  };
                      onUpdateClientProfile(updateClient);
                  
                    
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             

        
                <Card> 
                    <CardBody>
                   
              
                   <Row>
                        <Col sm="1">
                        <FormGroup className="mb-3">
                            <Label htmlFor="primaryTitle">
                              {this.props.t("Title")}
                            </Label>
                            <Field as="select" name="primaryTitle" 
                            className={
                              "form-control" +
                              (errors.primaryTitle && touched.primaryTitle
                                ? " is-invalid"
                                : "")
                            }
                            
                            onChange={handleChange}>
                                <option value="">Select</option>
                                    {
                                      this.state.titles
                                        .map(title =>
                                          <option key={title.id} value={title.id}>{title.fieldValueName}</option>
                                        )
                                    }
                            </Field>
                            <ErrorMessage
                              name="primaryTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="contactFName">
                              {this.props.t("First Name")}
                            </Label>
                            <Field
                              name="contactFName"
                              onChange={handleChange}  
                                 
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.contactFName && touched.contactFName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="contactFName"
                            />
                             <ErrorMessage
                              name="contactFName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="contactLName">
                              {this.props.t("Last Name")}
                            </Label>
                            <Field
                              name="contactLName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.contactLName && touched.contactLName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="contactLName"
                            />
                             <ErrorMessage
                              name="contactLName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>     
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mobile">
                              {this.props.t("Mobile")}
                            </Label>
                            <Field
                              name="mobile"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.mobile && touched.mobile
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mobile"
                            />
                             <ErrorMessage
                              name="mobile"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                            <FormGroup className="mb-3">
                            <Label htmlFor="office">
                              {this.props.t("Office Phone")}
                            </Label>
                            <Field
                              name="office"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.office && touched.office
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="office"
                            />
                             <ErrorMessage
                              name="office"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>
                            <Col sm="1">
                            <FormGroup className="mb-3">
                            <Label htmlFor="phoneExt">
                              {this.props.t("Ext")}
                            </Label>
                            <Field
                              name="phoneExt"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.phoneExt && touched.phoneExt
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="phoneExt"
                            />
                             <ErrorMessage
                              name="phoneExt"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>    
                  
                        
                        </Row>
                            <Row>
                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="organization">
                              {this.props.t("Office Name")}
                            </Label>
                            <Field
                              name="organization"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.organization && touched.organization
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="organization"
                            />
                             <ErrorMessage
                              name="organization"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label htmlFor="address">
                              {this.props.t("Office Address")}
                            </Label>
                            <Field
                              name="address"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.address && touched.address
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="address"
                            />
                             <ErrorMessage
                              name="address"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>
                            <Col sm="3">
                            <div className="form-group mb-3">
                          <Label htmlFor="file">Upload logo</Label>
                          <input id="file" name="file" type="file" className="form-control" />
                          
                        </div>
                        </Col>

                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-left mt-27">Save</button></div>
                            </Row>

                       
             
             </CardBody>
                </Card>



           

</Form>
)}
</Formik>


 
            
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientProfileNew.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  addClientImportantDate:PropTypes.func,
  addClientChild:PropTypes.func,
  onGetClientImportantDate:PropTypes.func,
  onGetClientChild:PropTypes.func,
  importantdates:PropTypes.array,
  childs:PropTypes.array,
  onDeleteImportantDate:PropTypes.func,
  loading:PropTypes.object,
  onUpdateClientProfile:PropTypes.func

}
const mapStateToProps = ({ Profile }) => (
  {
    loading: Profile.loading,
    clietDetail: Profile.clietDetail,
    importantdates:Profile.importantdates,
    childs:Profile.childs
    

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientImportantDate:(data) => dispatch(addClientImportantDate(data)),
  addClientChild:(data) => dispatch(addClientChild(data)),
  onGetClientImportantDate: clientId => dispatch(getClientImportantDate(clientId)),
  onGetClientChild: clientId => dispatch(getClientChild(clientId)),
  onDeleteImportantDate : id => dispatch(deleteClientImportant(id)),
  onUpdateClientProfile: (data) => dispatch(updateClientProfile(data))

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientProfileNew))
