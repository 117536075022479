import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {  map, capitalize } from "lodash";
//import Lead from "./Lead";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { addClientAddListing, getClientAddListing } from "../../store/addlisting/actions"
import CurrencyFormat from 'react-currency-format';
import Switch from "react-switch"
import typeView from "../../assets/images/type-view.png"
import uploadView from "../../assets/images/icons/upload.png"
import gridView from "../../assets/images/grid-view.png"
import downloadView from "../../assets/images/icons/down.png"
import compareImg from "../../assets/images/icons/compare.png"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Tooltip
} from "reactstrap"

class addListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      mlsFields: [{ mlsId: "", mlsLink: "", mlsCity: "", mlsNote: "", mlsPrice: "" }]
    }
    this.toggle = this.toggle.bind(this);
    this.handleAddListingSubmit = this.handleAddListingSubmit.bind(this)
  }
  toggle () {
    this.setState({
      open: !this.state.open
    })
  }
  handleAddListingSubmit(value){
    
    this.props.addClientAddListing(value, this.props.history);

  }
  componentDidMount() {
    const {  onGetClientAddListing } = this.props;
   
    onGetClientAddListing(localStorage.getItem('userId'),localStorage.getItem('agentId'))
   
}
handleChange = (activeStatus, itemId) => {
    
};
  render() {
    const { addListings, loading } = this.props;
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Add Listing  | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Buyer")}
              breadcrumbItem={this.props.t("Listings ")}
            />
            <Row>
              
              
              <Col xl="12">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    mlsFields: this.state.mlsFields,
                   
                    agentId: localStorage.getItem('agentId'),
                    clientId: localStorage.getItem('userId')

                  }}
                  validationSchema={Yup.object().shape({
                    mlsFields: Yup.array().of(
                      Yup.object().shape({
                        mlsId: Yup.string().required('This is required'),
                        mlsLink: Yup.string().required('This is required'),
                        // mlsCity: Yup.string().required('This is required'),
                        mlsPrice:Yup.number().typeError('Price should be a number')
                      })
                    )
                    
                  })}

                  onSubmit={(values, { setSubmitting }) => {
                    console.log(values.mlsFields);
                  }}
                >
                  {({ errors, touched, values, handleChange, handleBlur }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card className="mini-stats-wid"> 
                    <CardBody>
                    <h5 className=" pb-2">Add new Listing</h5>
                    <FieldArray
                name="mlsFields"
                render={arrayHelpers => (
                  <div>
                    {values.mlsFields.map((mlsField, index) => (
                      <div key={index}>
              
                   <Row>
                        <Col sm="1">
                        <FormGroup className="mb-3">
                        <Label htmlFor="Notes" className="label-text mt-3">MLS# </Label>
                            <input
                              type="text"
                              name={`mlsFields.${index}.mlsId`}
                              value={mlsField.mlsId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.mlsFields &&
                                errors.mlsFields[index] &&
                                errors.mlsFields[index].mlsId &&
                                touched.mlsFields &&
                                touched.mlsFields[index] &&
                                touched.mlsFields[index].mlsId
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                            <ErrorMessage
                              name={`mlsFields.${index}.mlsId`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="2">
                        <FormGroup className="mb-3">
                        <Label htmlFor="Notes" className="label-text mt-3">MLS URL </Label>
                            <input
                              type="text"
                              name={`mlsFields.${index}.mlsLink`}
                              value={mlsField.mlsLink}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.mlsFields &&
                                errors.mlsFields[index] &&
                                errors.mlsFields[index].mlsLink &&
                                touched.mlsFields &&
                                touched.mlsFields[index] &&
                                touched.mlsFields[index].mlsLink
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                            <ErrorMessage
                              name={`mlsFields.${index}.mlsLink`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="2">
                        <FormGroup className="mb-3">
                        <Label htmlFor="Notes" className="label-text mt-3">Listed Price </Label>
                            <input
                              type="text"
                              name={`mlsFields.${index}.mlsPrice`}
                              value={mlsField.mlsPrice}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.mlsFields &&
                                errors.mlsFields[index] &&
                                errors.mlsFields[index].mlsPrice &&
                                touched.mlsFields &&
                                touched.mlsFields[index] &&
                                touched.mlsFields[index].mlsPrice
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                            <ErrorMessage
                              name={`mlsFields.${index}.mlsPrice`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="2">
                        <FormGroup className="mb-3">
                        <Label htmlFor="Notes" className="label-text mt-3">City </Label>
                            <input
                              type="text"
                              name={`mlsFields.${index}.mlsCity`}
                              value={mlsField.mlsCity}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.mlsFields &&
                                errors.mlsFields[index] &&
                                errors.mlsFields[index].mlsCity &&
                                touched.mlsFields &&
                                touched.mlsFields[index] &&
                                touched.mlsFields[index].mlsCity
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                            <ErrorMessage
                              name={`mlsFields.${index}.mlsCity`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                        <Label htmlFor="Notes" className="label-text mt-3">Notes </Label>
                            <input
                              type="text"
                              name={`mlsFields.${index}.mlsNote`}
                              value={mlsField.mlsNote}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.mlsFields &&
                                errors.mlsFields[index] &&
                                errors.mlsFields[index].mlsNote &&
                                touched.mlsFields &&
                                touched.mlsFields[index] &&
                                touched.mlsFields[index].mlsNote
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                            <ErrorMessage
                              name={`mlsFields.${index}.mlsNote`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                       
                        </Col>
                        {index === 0 && (
                            
                            <Col sm="2">  <div className="clearfix">
                              
                              <button
                                type="button"
                                onClick={() => arrayHelpers.push({ mlsId: '', mlsLink: '' })}
                                style={{ marginTop: 42}} className="btn btn-primary "
                              >
                                <i className=" bx bx-plus-medical" />
                              </button>
                              </div>
                            </Col>
                          )}
                           {index !== 0 && (
                            
                            <Col sm="2">  <div className="clearfix">
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                style={{ marginTop: 42}} className="btn btn-primary "
                              >
                               <i className=" bx bx-minus" />

                              </button>
                            </div>
                            </Col>
                          )}

                        {/* <Col sm="2">  <div className="clearfix"><button style={{ marginTop: 27}} className="btn btn-primary w-md" onClick={(e)=>{e.preventDefault()}}>Add More</button></div></Col> */}

                       
                    </Row>
                    </div>
                     ))}
                     <Col sm="2">  <div className="clearfix"><button style={{ marginTop: 2}} type="submit" className="btn btn-primary w-md">Save</button></div></Col>
                     </div>
                      )}
                      />

             </CardBody>
                </Card>
             
                
                </Form>
                  )}
                </Formik>
			  <Row>
				<Col sm="12">
					 <Card className="mini-stats-wid"> 
                    <CardBody>
                      <Row>
                        <Col sm="6">
                           <h5 className="card-title mb20">Listing History</h5>
                        </Col>
                        <Col sm="6">
                        <div>
                          <div
                            className="btn-group rl-group-btn float-end mb20"
                            role="group"
                          >
                          <Link to="/add-listing" >
                          <button
                            type="button"
                            className="btn btn-outline-warning  w-xs active"
                          >
                            <img src={gridView} /><br/>
                              <span className="text-black">All</span>
                          </button></Link>
                          <Link to="/sent-add-listing" >
                          <button
                            type="button"
                            className="btn btn-outline-warning   w-xs"
                          >
                            <img className="tab-img" src={uploadView} />
                             <br/>
                             <span className="text-black">Sent</span>
                              
                          </button></Link>
                          <Link to="/received-add-listing" >
                          <button
                            type="button"
                            className="btn btn-outline-warning   w-xs"
                          >
                            <img className="tab-img" src={downloadView} />
                              <br/>
                              
                              <span className="text-black">Received</span>
                          </button></Link>
                          <Link to="/visited-add-listing" >
                          <button
                            type="button"
                            className="btn btn-outline-warning   w-xs"
                          >
                            <img src={typeView} />
                              <br/>
                              <span className="text-black">Visited</span>
                          </button></Link>
                          {/* <button
                            type="button"
                            className="btn btn-outline-warning  w-xs"
                          >
                            <Link to="/buyer-prefrences" className='text-black'><img src={groupView} />
                            <br/>
                            Manage
                            </Link>
                          </button> */}
                              
                             
                            </div>
                          </div>  
                                    </Col>
                                </Row>
                    <div className="row gy-2 gx-3  mb20">
                      <div className="col-sm-3 float-start">
                        <div className="col-sm-12">
                          <Link to="/compare-match">
                          <img className="tab-img" src={compareImg}/> <span className="compare-text"> Compare (Max 4 properties)</span>
                          </Link>
                          
                        </div>
                      </div>
                      <div className="col-sm-9 ">
                        <div className="row float-end">
                          <div className="col-sm-auto">
                            <Label className="visually-hidden" htmlFor="autoSizingInput">Name</Label>
                            <Input type="text" className="form-control" id="autoSizingInput" placeholder="Search MLS#" />
                          </div>

                          <div className="col-sm-auto">
                            <Label className="visually-hidden" htmlFor="autoSizingInput">Name</Label>
                            <Input type="text" className="form-control" id="autoSizingInput" placeholder="Price" />
                          </div>
                        
                          <div className="col-sm-auto">
                            <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
                            <select defaultValue="0" className="form-select">
                              <option value="0">Status...</option>
                              <option value="1">Avialable </option>
                              <option value="2"> Sold</option>
                              d
                            </select>
                          </div>
                          
                          <div className="col-sm-auto">
                            <button type="submit" className="btn btn-primary w-md">Search</button>
                          </div>
                        </div>
                      </div>
                    </div>
        <div className="clearfix"></div>
        
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
                <th></th>
								<th>Date</th>
								<th>MLS#</th>
								<th>Asking price</th>
								<th>City</th>
                                <th>Status </th>
                                <th>Like / Dislike </th>
                                <th>Showing</th>
                                {/* <th>Manage</th> */}
                                <th>Agent Note</th>
                                
							  </tr>
							</thead>
              <tbody>
                              {map(addListings, (addListing, lkey) => (
                                 <tr key={"_alist_" + lkey}>
                                  <td><input type='checkbox' name='checkbox'/></td>
                                    <td>{addListing.addedBy == 'client' ? <img className="listing-img" src={uploadView} /> : <img className="listing-img" src={downloadView} /> } <Moment format="Do MMM, YYYY">{addListing.listingDate}</Moment></td>
                                    <td><a target="_blank" rel="noreferrer"  href={addListing.mlsurl}>{addListing.mlsId} <i className=" bx bx-link-external"></i></a></td>
                                    <td><CurrencyFormat value={addListing.listedPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                    <td>{addListing.listingCity}</td>
                                    <td>{addListing.listingStatusId==0 ? "Sold" : "Available"}</td>
                                    <td><Switch checked={addListing.isActive} onChange={isActive => this.handleChange(isActive, addListing.id)} /></td>
                                    <td>{addListing.showingDate != null ? <Moment format="Do MMMM YYYY">{addListing.showingDate}</Moment>   : "n/a" }  {addListing.startTime != null ?  '-'+<Moment format="hh:mm A">{addListing.startTime}</Moment>   : "" }  {addListing.endTime != null ?  '-'+<Moment format="hh:mm A">{addListing.endTime}</Moment>   : "" }</td>
                                    
                                    <td>
{/*                                    
                                    <Tooltip
                                      placement="top"
                                      isOpen={this.state.open} toggle={this.toggle}
                                      target={`movie-${addListing.id}`}
                            
                                    > */}
                                      {addListing.clientNotes??"n/a"}
                                    {/* </Tooltip>
                                    <i className="bx bx-comment-dots icon-note" id={`movie-${addListing.id}`}></i> */}
                                 
                                    
                                    </td>
                                </tr>
                              ))}
                              <tr>
                              <td><input type='checkbox' name='checkbox'/></td>
                                    <td><img className="listing-img" src={uploadView} /> 20th fab, 2024 </td>
                                    <td> <Link to="/manage-listing">W786164 <i className=" bx bx-link-external"></i></Link></td>
                                    <td>$1,125,900</td>
                                    <td>Mississauge</td>
                                    <td> Sold </td>
                                    <td><Switch checked={true}  /></td>
                                    <td> 24th fab, 2024</td>
                                    
                                    <td>
                                      <b>Within budget</b>
                                    </td>
                              </tr>

                              <tr>
                              <td><input type='checkbox' name='checkbox'/></td>
                                    <td><img className="listing-img" src={uploadView} /> 20th fab, 2024 </td>
                                    <td><Link to="/manage-listing">W423145 <i className=" bx bx-link-external"></i></Link></td>
                                    <td>$1,350,900</td>
                                    <td>Mississauge</td>
                                    <td>  Available</td>
                                    <td><Switch checked={false}  /></td>
                                    <td> 24th fab, 2024</td>
                                    
                                    <td>
                                      <b>Nice Location</b>
                                    </td>
                              </tr>

                              <tr>
                              <td><input type='checkbox' name='checkbox'/></td>
                                    <td><img className="listing-img" src={uploadView} /> 20th fab, 2024 </td>
                                    <td><Link to="/manage-listing">W289654 <i className=" bx bx-link-external"></i></Link></td>
                                    <td>$1,212,000</td>
                                    <td>Mississauge</td>
                                    <td>  Available</td>
                                    <td><Switch checked={false}  /></td>
                                    <td> 24th fab, 2024</td>
                                    
                                    <td>
                                      <b>Within budget</b>
                                    </td>
                              </tr>

                              <tr>
                              <td><input type='checkbox' name='checkbox'/></td>
                                    <td><img className="listing-img" src={uploadView} /> 14th fab, 2024 </td>
                                    <td><Link to="/manage-listing">W7854321 <i className=" bx bx-link-external"></i></Link></td>
                                    <td>$1,097,550</td>
                                    <td>Mississauge</td>
                                    <td>  Available</td>
                                    <td><Switch checked={true}  /></td>
                                    <td> 20th fab, 2024</td>
                                    
                                    <td>
                                      <b>Really Nice</b>
                                    </td>
                              </tr>

                              <tr>
                              <td><input type='checkbox' name='checkbox'/></td>
                                    <td><img className="listing-img" src={downloadView} /> 12th fab, 2024 </td>
                                    <td><Link to="/manage-listing">W5687695 <i className=" bx bx-link-external"></i></Link></td>
                                    <td>$1,350,900</td>
                                    <td>Mississauge</td>
                                    <td> Available</td>
                                    <td><Switch checked={false}  /></td>
                                    <td>n/a</td>
                                    
                                    <td>
                                      <b>Fits our needs</b>
                                    </td>
                              </tr>

                              <tr>
                              <td><input type='checkbox' name='checkbox'/></td>
                                    <td><img className="listing-img" src={downloadView} /> 12th fab, 2024 </td>
                                    <td><Link to="/manage-listing">W154674 <i className=" bx bx-link-external"></i></Link></td>
                                    <td>$1,990,000</td>
                                    <td>Mississauge</td>
                                    <td> Available</td>
                                    <td><Switch checked={false}  /></td>
                                    <td> 24th fab, 2024</td>
                                    
                                    <td>
                                      <b>Nice Location</b>
                                    </td>
                              </tr>
                              
                              </tbody>          
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>
			
			
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
addListing.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientAddListing:PropTypes.func,
  addListings:PropTypes.array,
  onGetClientAddListing:PropTypes.func,
  loading:PropTypes.object

}
const mapStateToProps = ({ addListing }) => (
  {
    addListings:addListing.addListings,
    loading:addListing.loading

  })
const mapDispatchToProps = dispatch => ({
  
  addClientAddListing: (data) => dispatch(addClientAddListing(data)),
  onGetClientAddListing:(clientId, agentId) => dispatch(getClientAddListing(clientId, agentId))
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(addListing))
