import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Finance_img from "../../assets/images/finance.png"
import Map_img from "../../assets/images/map.png"
import Home_pref_img from "../../assets/images/home.png"
import { withTranslation } from "react-i18next"


import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardImg,
  CardFooter
} from "reactstrap"
import homePref from "./home-pref";
class preFrence extends Component {
  constructor(props) {
    super(props)
    this.state = {
    
    }
 
  }
  
  
  
  render() {
   
    return (
      <React.Fragment>
         <div className="page-content">
          <MetaTags>
            <title>Preferences | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Buyer")}
              breadcrumbItem={this.props.t("My Preference")}
              heading={this.props.t("Set all your home preferences and then compare them with selected homes")}
            />
            {/* <h5 className="mb-4 text-center">Set all your home preferences and then compare them with selected homes</h5> */}
            <br/>

            
          <Row className="aquire-list ">
            <Col md='3' className=" p-0"></Col>
           
           <Col md="3" className="me-4 ms-0" style={{maxWidth:'300px'}}>
           <Card style={{backgroundColor :"#ffde5b"}} className="text-light">
             <Link to="/prefrences/home-prefrences">
               <CardBody style={{backgroundColor:'#ffde5b'}} className="p-0">
                 <CardImg src={Home_pref_img} />
                 <CardText className="mb-0 mt-3 text-dark text-center" style={{minHeight:'70px'}}>
                    <p style={{fontSize:'14px'}}> SET YOUR HOME PREFERENCES</p>
                 </CardText>
                 
               </CardBody>
               </Link>
             </Card>
               
             </Col>

             <Col md="3" className="me-4" style={{maxWidth:'300px'}}>
             <Card style={{backgroundColor :"#cfbca1"}} className="text-light">
             <Link to="/prefrences/important-destinations">
               <CardBody style={{backgroundColor:'#cfbca1;'}} className="p-0">
                 <CardImg src={Map_img}  />
                
                 <CardText className="mb-0 mt-3 text-dark text-center " >
                    <p style={{fontSize:'14px'}}>SET REGULAR DESTINATIONS 
                    ( OFFICE, SCHOOL, GYM ETC)</p>
                 </CardText>
               </CardBody>
             </Link>
             </Card>
               
             </Col>
               {/* <Col md="2">
               <Card style={{backgroundColor:'#c2e09e'}} className="text-light">
               <CardBody  className="p-0">
                 <CardImg src={Finance_img}  />
                 <CardText className="mb-0 mt-3 text-dark text-center " style={{minHeight:'70px'}}>
                    <p style={{fontSize:'14px'}}> SET YOUR FINANCIAL PREFERENCES</p>
                 </CardText>
                 
               </CardBody>
             </Card>
               </Col> */}
           </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
preFrence.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
 

}

const mapStateToProps = ({ Prefrences }) => (
  {
  

  })

  const mapDispatchToProps = dispatch => ({
  
     
    });

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(preFrence))
