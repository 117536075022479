import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify";
import { CREATE_BUY_DESTINATION, CREATE_HOME_DESTINATION, CREATE_IMP_DESTINATION, GET_BUY_PREFRENCES_QUEST, GET_HOME_DESTINATION, GET_IMP_DESTINATION, UPDATE_HOME_DESTINATION } from "./actionTypes"
import { getClientPrefQuestAPI, getHomePrefUrl, getImpDestUrl, postBuyerDestinationUrl, postHomePrefUrl, postImpDestUrl } from '../../helpers/backend_helper'
import { getClientPrefrencesQuestFail, getClientPrefrencesQuestSuccess, getHomePrefFail, getHomePrefSuccess, getImpDestinationFail, postBuyerDestinationFail, postBuyerDestinationSuccess, postHomePrefFail, postHomePrefSuccess, postImpDestinationFail, updateHomePref, updateHomePrefSuccess } from "./actions"

function* fetchClientPrefQuest({ forType }) {
  try {
    const response = yield call(getClientPrefQuestAPI, forType)
    yield put(getClientPrefrencesQuestSuccess(response))
  } catch (error) {
    yield put(getClientPrefrencesQuestFail(error))
  }
}

function* submitBuyerDestination({ payload: { destination, history } }) {
  try {
    //console.log(destination);
    toast.loading("Please wait...")
    const response = yield call(postBuyerDestinationUrl, {
      destinationTitle: destination.destinationTitle,
      destinationAddress: destination.destinationAddressss ,
      isValid:true,
      clientId: 5,
      agentId: 4,
      
    })
    yield put(postBuyerDestinationSuccess(response))
  }
  catch (error) {

    yield put(postBuyerDestinationFail(error))
  }
}

function* submitHomePref({ payload: { homepref, history } }) {
  try {
  //console.log(homepref);
    toast.loading("Please wait...")
    const response = yield call(postHomePrefUrl, {
     
      homePreferenceTitle: homepref.homePreferenceTitle,
      answersOption: homepref.answersOption ,
      preference:homepref.preference,
      clientId: parseInt(homepref.clientId),
      
    })
    if (response.success == true) {
      toast.dismiss();
      toast.success("Home Preferences Added");
      const response = yield call(getHomePrefUrl, parseInt(homepref.clientId))
    
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(postHomePrefFail(error))
  }
}

function* fetchHomePref({ ClientId }) {
  // console.log(ClientId);
   try {
     const response = yield call(getHomePrefUrl, ClientId)
     yield put(getHomePrefSuccess(response))
   } catch (error) {
     yield put(getHomePrefFail(error))
   }
 }


 function* onUpdateHomePref({ payload: homepref }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updateHomePref, homepref)
    toast.dismiss();
    toast.success("Home Prefrences Updated");
    const res = yield call(getHomePrefUrl, homepref.clientId)
    yield put(getHomePrefSuccess(res))


  } catch (error) {
    yield put(updateHomePrefSuccess(error))
  }
}


function* submitImpDest({ payload: { impdes, history } }) {
  try {
  //console.log(homepref);
    toast.loading("Please wait...")
    const response = yield call(postImpDestUrl, {
      destinationTitle: impdes.destinationTitle,
      destinationAddress: impdes.destinationAddress ,
      agentId: parseInt(impdes.agentId),
      clientId: parseInt(impdes.agentId),
      isValid:true,
      isActive:true
      
    })
    if (response.success == true) {
      toast.dismiss();
      toast.success("Important Destination Added");
      const response = yield call(getImpDestUrl, parseInt(impdes.clientId))
    
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(postImpDestinationFail(error))
  }
}



function* fetchImpDestination({ clientId }) {
 //console.log(clientId);
  try {
    const response = yield call(getImpDestUrl, clientId)
    yield put(getImpDestinationSuccess(response))
  } catch (error) {
    yield put(getImpDestinationFail(error))
  }
}





function* prefrencesSaga() {
  yield takeEvery(GET_BUY_PREFRENCES_QUEST, fetchClientPrefQuest)
  yield takeEvery(CREATE_BUY_DESTINATION, submitBuyerDestination)
  yield takeEvery(CREATE_HOME_DESTINATION, submitHomePref)
  yield takeEvery(GET_HOME_DESTINATION, fetchHomePref)
  yield takeEvery(UPDATE_HOME_DESTINATION, onUpdateHomePref)
  yield takeEvery(CREATE_IMP_DESTINATION, submitImpDest)
  yield takeEvery(GET_IMP_DESTINATION, fetchImpDestination)

}


export default prefrencesSaga
