import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"

import { take } from "lodash";
import { addClientChildError, addClientChildSuccess, addClientImportantDateError, addClientImportantDateSuccess, deleteClientImportantFail, deleteClientImportantSuccess, getClientChildFail, getClientChildSuccess, getClientDetailFail, getClientDetailSuccess, getClientImportantDateFail, getClientImportantDateSuccess, updateClientProfileError,updateClientProfile,updateClientProfileSuccess,updateClientProfileFail,updateClientPrimaryContactError,updateClientSecondaryContactError } from "./actions";
import { CREATE_CLIENT_CHILD, CREATE_CLIENT_IMPORTANT_DATE, DELETE_CLIENT_IMPORTANT_DATE, GET_CLIENTS_DETAIL, GET_CLIENT_CHILD, GET_CLIENT_IMPORTANT_DATE, UPDATE_CLIENT_PRIMARY_CONTACT, UPDATE_CLIENT_PROFILE,UPDATE_CLIENT_SECONDARY_CONTACT } from "./actionTypes";
import { deleteClientImportantDateAPI, getClientChildAPI, getClientDetailUrl, getClientImportantDateAPI, postClientChildAPI, postClientImportantDateAPI, updateClientProfileAPI,updateClientPrimaryConatctAPI,updateClientSecondaryConatctAPI } from "helpers/backend_helper";

function* fetchClientDetail({ clientId }) {
  try {
    const response = yield call(getClientDetailUrl, clientId)
    yield put(getClientDetailSuccess(response))
  } catch (error) {
    yield put(getClientDetailFail(error))
  }
}

function* onClientProfileUpdate({ payload: client }) {
  try {
    const response = yield call(updateClientProfileAPI, client)
    const res = yield call(getClientDetailUrl, client.id)
    yield put(getClientDetailSuccess(res))
  } catch (error) {
    yield put(updateClientProfileError(error))
  }
}

function* onClientPrimaryContactUpdate({ payload: client }) {
  try {
    const response = yield call(updateClientPrimaryConatctAPI, client)
    // const res = yield call(getClientDetailUrl, client.id)
    // yield put(getClientDetailSuccess(res))
  } catch (error) {
    yield put(updateClientPrimaryContactError(error))
  }
}


function* onClientSecondaryContactUpdate({ payload: client }) {
  try {
    const response = yield call(updateClientSecondaryConatctAPI, client)
    // const res = yield call(getClientDetailUrl, client.id)
    // yield put(getClientDetailSuccess(res))
  } catch (error) {
    yield put(updateClientSecondaryContactError(error))
  }
}

function* submitClienImportantDate({ payload: { importantdate, history } }) {
  try {

    toast.loading("Please wait...")
    const response = yield call(postClientImportantDateAPI, {
      clientId: parseInt(importantdate.clientId),
      agentId: parseInt(importantdate.agentId),
      dateTitle: importantdate.dateTitle,
      dateMonth: parseInt(importantdate.dateMonth),
      dateDay: parseInt(importantdate.dateDay),
      

    })
    yield put(addClientImportantDateSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Important Date Added");
      const response = yield call(getClientImportantDateAPI, importantdate.clientId)
    yield put(getClientImportantDateSuccess(response))
    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientImportantDateError(error))
  }
}

function* fetchClienImportantDate({ clientId }) {
  try {
    const response = yield call(getClientImportantDateAPI, clientId)
    yield put(getClientImportantDateSuccess(response))
  } catch (error) {
    yield put(getClientImportantDateFail(error))
  }
}


function* onDeleteClienImportantDate({ payload: importantdate }) {
  //console.log(document);
  try {
   
    const response = yield call(deleteClientImportantDateAPI, importantdate.id)
    yield put(deleteClientImportantSuccess(response))
    const res = yield call(getClientImportantDateAPI, importantdate.clientId)
    yield put(getClientImportantDateSuccess(res))
   
  } catch (error) {
    yield put(deleteClientImportantFail(error))
  }
}


function* submitClienChild({ payload: { child, history } }) {
  try {

    toast.loading("Please wait...")
    const response = yield call(postClientChildAPI, {
      clientId: parseInt(child.clientId),
      childName: child.childName,
      birthDay: child.birthDay,
      cGender: 0,
      cChildIsActive:true
      

    })
    yield put(addClientChildSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Child Added");
      const response = yield call(getClientChildAPI, child.clientId)
      yield put(getClientChildSuccess(response))
    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientChildError(error))
  }
}

function* fetchClientChild({ clientId }) {
  try {
    const response = yield call(getClientChildAPI, clientId)
    yield put(getClientChildSuccess(response))
  } catch (error) {
    yield put(getClientChildFail(error))
  }
}


function* clientSaga() {
  yield takeEvery(GET_CLIENTS_DETAIL, fetchClientDetail)
  yield takeEvery(UPDATE_CLIENT_PROFILE, onClientProfileUpdate)
  yield takeEvery(UPDATE_CLIENT_PRIMARY_CONTACT, onClientPrimaryContactUpdate)
  yield takeEvery(UPDATE_CLIENT_SECONDARY_CONTACT, onClientSecondaryContactUpdate)


  yield takeEvery(CREATE_CLIENT_IMPORTANT_DATE, submitClienImportantDate)
  yield takeEvery(GET_CLIENT_IMPORTANT_DATE, fetchClienImportantDate)
  yield takeEvery(DELETE_CLIENT_IMPORTANT_DATE, onDeleteClienImportantDate)

  yield takeEvery(CREATE_CLIENT_CHILD, submitClienChild)
  yield takeEvery(GET_CLIENT_CHILD, fetchClientChild)
  
}


export default clientSaga