import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {  map, isEmpty, size } from "lodash";
//import Lead from "./Lead";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import editIcon from "../../assets/images/icons/edit2.png";
import deleteIcon from "../../assets/images/icons/bin.png";
import { addClientSellerShwoing, getClientSellerListing, getClientSellerShwoing, deleteClientShowing, updateClientSellerShowing} from "../../store/showing/actions"
import DeleteModal from "../../components/Common/DeleteModal";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

class SellerShowing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showing:''
    }
    this.handleSellerShowingSubmit = this.handleSellerShowingSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (showing) => {
    this.setState({ showing: showing });
    this.setState({ deleteModal: true });
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  handleDeleteShowing = () => {
    const { onDeleteShowing } = this.props;
    const { showing } = this.state;
    
    if (showing.id !== undefined) {
      onDeleteShowing(showing);
      this.setState({ deleteModal: false });
    }
  };
  handleSellerShowingSubmit(value){
    //console.log(value);
    this.props.addClientSellerShwoing(value, this.props.history);
  }
  componentDidMount() {
    const { onGetClientSellerListing, onGetClientSellerShowing } = this.props;
    onGetClientSellerListing(localStorage.getItem('userId'));
    onGetClientSellerShowing(localStorage.getItem('userId'));
}
componentDidUpdate(prevProps, prevState, snapshot) {
  const { showings } = this.props;
  if (!isEmpty(showings) && size(prevProps.showings) !== size(showings)) {
    this.setState({ showings: {}, isEdit: false });
  }
}
toggleRightCanvas() {
  this.setState({ showing: "", isEdit: false, isRight: !this.state.isRight });
  this.toggle();
}
toggleRightCanvas = arg => {
  const showing = arg;

  this.setState({
    showing: {
      id:showing.id,
      clientSellerShowingId: showing.clientSellerShowingId,
      clientId:showing.clientId,
      agentId: showing.agentId,
      clientListingId: showing.clientListingId,
      showingDate: showing.showingDate,
      startTime: showing.startTime,
      endTime: showing.endTime,
      agentName: showing.agentName,
      agentOrganization: showing.agentName,
      agentContact: showing.agentContact,
      agentEmail: showing.agentEmail,
      feedback:showing.feedback,
      notificationMinutes: showing.notificationMinutes,
      isActive: true
    },
    isEdit: true,
  });
  this.toggle();
};
  render() {
   
    const { listings, showings, onUpdateClientSellerShowing } = this.props;
    const { isEdit, deleteModal } = this.state;
    const showing = this.state.showing;
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteShowing}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>Seller Showings  | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Seller")}
              breadcrumbItem={this.props.t("Seller Showings ")}
            />
            <Row>
              
              
              <Col xl="12">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    clientListingId: (this.state && this.state.clientListingId) || "",
                   
                    agentId: localStorage.getItem('agentId'),
                    clientId: localStorage.getItem('userId')


                  }}
                  validationSchema={Yup.object().shape({
                    clientListingId: Yup.string().required("This is Required"),
                    
                   
                  })}

                  onSubmit={this.handleSellerShowingSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card className="mini-stats-wid"> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                   <Row>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Select Property")}
                            </Label>
                            <Field as="select" name="clientListingId" className={
                                  "form-control" +
                                  (errors.mlsURL && touched.mlsURL
                                    ? " is-invalid"
                                    : "")
                                } onChange={handleChange}>
                                  <option value="">Select</option>
                                  {map(listings, (listing, listingskey) => (
                                     <option key={"_list_" + listingskey} value={listing.id}>{listing.mlsId}</option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="clientListingId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="2">
                        <FormGroup className="mb-3">
                                <Label htmlFor="showingDate">
                                  {this.props.t("Date")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.showingDate && touched.showingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                   
                                    onChange={(value) => setFieldValue('showingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      minDate:"today",
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                </InputGroup>
                                </FormGroup>
                        </Col>

                        <Col sm="2">
                        <FormGroup className="mb15">

                            <Label htmlFor="startTime">
                              {this.props.t("Start Time")}
                            </Label>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="Select time"
                               
                                onChange={(value) => setFieldValue('startTime', value[0])}
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "h:i"
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <i className="mdi mdi-clock-outline" />
                                </span>
                              </div>
                            </InputGroup>

                            </FormGroup>
                        </Col>

                        <Col sm="2">
                        <FormGroup className="mb15">

                              <Label htmlFor="endTime">
                                {this.props.t("End Time")}
                              </Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select time"
                                  
                                  onChange={(value) => setFieldValue('endTime', value[0])}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "h:i"
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                  </span>
                                </div>
                              </InputGroup>

                              </FormGroup>
                        </Col>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Send Text Notification")}
                            </Label>
                            <Field as="select" name="notification" className={
                                  "form-control" +
                                  (errors.notification && touched.notification
                                    ? " is-invalid"
                                    : "")
                                } onChange={handleChange}>
                                <option value="">Please Select</option>
                                <option value="1">1 HR</option>
                                <option value="30">30 MIN</option>
                                <option value="0">No need for notification</option>
                                         
                                        </Field>
                          </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                       
                    </Row>
                    <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>
             
             </CardBody>
                </Card>
                
                </Form>
                  )}
                </Formik>
			  <Row>
				<Col sm="12">
					 <Card className="mini-stats-wid"> 
                    <CardBody>
                    <h5 className="card-title">Showings History</h5>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								<th>#</th>
								<th>Property</th>
								<th>Date</th>
                <th>Start Time</th>
                <th>End Time</th>
								<th>Notification</th>
                <th>Edit | Delete</th>
							  </tr>
							</thead>
							<tbody>
              {map(showings, (showing, showkey) => (
							 <tr key={"_list_" + showkey}>
                  <th>{ showkey + 1 }</th>
                  <td>{showing.clientListingId}</td>
                  <td><Moment format="Do MMMM YYYY">{showing.showingDate}</Moment> </td>
                  <td><Moment format="hh:mm A">{showing.startTime}</Moment></td>
                  <td><Moment format="hh:mm A">{showing.endTime}</Moment></td>
                  <td>{showing.notificationMinutes}</td>
                  {/* <td>
                  <UncontrolledDropdown>
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(showing)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(showing)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                  </td> */}
                  <td> <img onClick={() => this.toggleRightCanvas(showing)}  className='tab-img me-2' src={editIcon}/> <img onClick={() => this.onClickDelete(showing)}  className='tab-img' src={deleteIcon} /></td>
               </tr>
              ))}
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>
			
			
            </Col>
            
            </Row>
            <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update 
                      </OffcanvasHeader>
            <OffcanvasBody>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:showing.id,
                    clientSellerShowingId: showing.clientSellerShowingId,
                    clientId:showing.clientId,
                    agentId: showing.agentId,
                    clientListingId: showing.clientListingId,
                    showingDate: showing.showingDate,
                    startTime: showing.startTime,
                    endTime: showing.endTime,
                    agentName: showing.agentName,
                    agentOrganization: showing.agentName,
                    agentContact: showing.agentContact,
                    agentEmail: showing.agentEmail,
                    feedback:showing.feedback,
                    notificationMinutes: showing.notificationMinutes,
                    isActive: true


                  }}
                  validationSchema={Yup.object().shape({
                    //clientListingId: Yup.string().required("This is Required"),
                    //agentName: Yup.string().required("This is Required"),
                    //agentOrganization: Yup.string().required("This is Required"),
                    //agentOrganization: Yup.string().required("This is Required"),
                   
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateShowing = {
                        id:values.id,
                        clientSellerShowingId: values.clientSellerShowingId,
                        clientId:values.clientId,
                        agentId: values.agentId,
                        clientListingId: parseInt(values.clientListingId),
                        showingDate: values.showingDate,
                        startTime: values.startTime,
                        endTime: values.endTime,
                        agentName: values.agentName,
                        agentOrganization: values.agentName,
                        agentContact: values.agentContact,
                        agentEmail: values.agentEmail,
                        feedback:values.feedback,
                        notificationMinutes: parseInt(values.notificationMinutes),
                        isActive: true
                      };

                      onUpdateClientSellerShowing(updateShowing);
                    } else {
                      
                    }

                    //this.setState({ selectedOrder: null });
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                     
                        

                          <Row>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="clientListingId">
                                  {this.props.t("Select Property")}
                                </Label>
                                <Field as="select" name="clientListingId" className={
                                  "form-control" +
                                  (errors.mlsURL && touched.mlsURL
                                    ? " is-invalid"
                                    : "")
                                } onChange={handleChange}>
                                  <option value="">Select</option>
                                  {map(listings, (listing, listingskey) => (
                                     <option key={"_list_" + listingskey} value={listing.id}>{listing.mlsId}</option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="clientListingId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="showingDate">
                                  {this.props.t("Date")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.showingDate && touched.showingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.showingDate}
                                    onChange={(value) => setFieldValue('showingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>

                            <Col sm="6">
                              <FormGroup className="mb15">

                                <Label htmlFor="startTime">
                                  {this.props.t("Start Time")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    value={values.startTime}
                                    onChange={(value) => setFieldValue('startTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i"
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                </InputGroup>

                              </FormGroup>
                            </Col>

                            <Col sm="6">
                              <FormGroup className="mb15">

                                <Label htmlFor="endTime">
                                  {this.props.t("End Time")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    value={values.endTime}
                                    onChange={(value) => setFieldValue('endTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i"
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                </InputGroup>

                              </FormGroup>
                            </Col>
                            <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Send Text Notification")}
                            </Label>
                            <Field as="select" name="notificationMinutes" className={
                                  "form-control" +
                                  (errors.notificationMinutes && touched.notificationMinutes
                                    ? " is-invalid"
                                    : "")
                                } onChange={handleChange}>
                                <option value="">Please Select</option>
                                <option value="1">1 HR</option>
                                <option value="30">30 MIN</option>
                                <option value="2">No need for notification</option>
                                         
                                        </Field>
                          </FormGroup>
                        </Col>
                          </Row>

                          <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Update</button></div>

                       

                    </Form>
                  )}
                </Formik>

                      </OffcanvasBody>
                    </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
SellerShowing.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientSellerShwoing:PropTypes.func,
  onGetClientSellerListing:PropTypes.func,
  listings:PropTypes.array,
  onGetClientSellerShowing:PropTypes.func,
  showings:PropTypes.array,
  onDeleteShowing:PropTypes.func,
  onUpdateClientSellerShowing:PropTypes.func,
  loading:PropTypes.object

}
const mapStateToProps = ({ Showings }) => (
  {
     listings:Showings.listings,
     showings:Showings.showings,
     loading:Showings.loading

  })
const mapDispatchToProps = dispatch => ({
  addClientSellerShwoing: (data) => dispatch(addClientSellerShwoing(data)),
  onGetClientSellerListing:(clientId) => dispatch(getClientSellerListing(clientId)),
  onGetClientSellerShowing:(clientId) => dispatch(getClientSellerShwoing(clientId)),
  onDeleteShowing:(id) => dispatch(deleteClientShowing(id)),
  onUpdateClientSellerShowing: (data) => dispatch(updateClientSellerShowing(data))
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SellerShowing))
