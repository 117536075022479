export const GET_CLIENTS_SELLER_LISTING = "GET_CLIENTS_SELLER_LISTING"
export const GET_CLIENTS_SELLER_LISTING_SUCCESS = "GET_CLIENTS_SELLER_LISTING_SUCCESS"
export const GET_CLIENTS_SELLER_LISTING_FAIL = "GET_CLIENTS_SELLER_LISTING_FAIL"

export const CREATE_CLIENT_SELLER_SHOWING = "CREATE_CLIENT_SELLER_SHOWING"
export const CREATE_CLIENT_SELLER_SHOWING_SUCCESS = "CREATE_CLIENT_SELLER_SHOWING_SUCCESS"
export const CREATE_CLIENT_SELLER_SHOWING_FAIL = "CREATE_CLIENT_SELLER_SHOWING_FAIL"

export const GET_CLIENTS_SELLER_SHOWING = "GET_CLIENTS_SELLER_SHOWING"
export const GET_CLIENTS_SELLER_SHOWING_SUCCESS = "GET_CLIENTS_SELLER_SHOWING_SUCCESS"
export const GET_CLIENTS_SELLER_SHOWING_FAIL = "GET_CLIENTS_SELLER_SHOWING_FAIL"


export const DELETE_CLIENTS_SELLER_SHOWING = "DELETE_CLIENTS_SELLER_SHOWING"
export const DELETE_CLIENTS_SELLER_SHOWING_SUCCESS = "DELETE_CLIENTS_SELLER_SHOWING_SUCCESS"
export const DELETE_CLIENTS_SELLER_SHOWING_FAIL = "DELETE_CLIENTS_SELLER_SHOWING_FAIL"

export const UPDATE_CLIENTS_SELLER_SHOWING = "UPDATE_CLIENTS_SELLER_SHOWING"
export const UPDATE_CLIENTS_SELLER_SHOWING_SUCCESS = "UPDATE_CLIENTS_SELLER_SHOWING_SUCCESS"
export const UPDATE_CLIENTS_SELLER_SHOWING_FAIL = "UPDATE_CLIENTS_SELLER_SHOWING_FAIL"







