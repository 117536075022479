import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import Select from "react-select"
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import all from "../../assets/images/rsicons/all.png"
import appointment from "../../assets/images/rsicons/appointment.png"
import activiti from "../../assets/images/rsicons/activities.png"
import call from "../../assets/images/rsicons/call.png"
import notes from "../../assets/images/rsicons/notes.png"
import todo from "../../assets/images/rsicons/to-do.png"
import follow from "../../assets/images/rsicons/follow-up.png"
import phonelog from "../../assets/images/rsicons/phone-log.png"
import emaillog from "../../assets/images/rsicons/email-log.png"
import smslog from "../../assets/images/rsicons/log.png"
import listing from "../../assets/images/rsicons/listing.png"
import project from "../../assets/images/rsicons/project.png"
import prefrence from "../../assets/images/rsicons/prefrence.png"
import {  Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
const optionGroup = [
  {
    options: [
      { label: "Renting", value: "Renting" },
      { label: "Homeowner", value: "Homeowner" }
    ]
  }
]

const sqrFts = [
  {
    options:[
      { label: "< 1000", value:"< 1000"},
      { label: "1000-1500", value:"1000-1500"},
      { label: "1501-2000", value:"1501-2000"},
      { label: "2001-2500", value:"2001-2500"},
      { label: "2501-3000", value:"2501-3000"},
      { label: "3001-3500", value:"3001-3500"},
    ]
  }
]

const propIns = [
  {
    options:[
      { label: "Residential", value:"Residential"},
      { label: "Commercial", value:"Commercial"}
    ]
  }
]

const propDeal = [
  {
    options:[
      { label: "1", value:"1"},
      { label: "2-3", value:"2-3"},
      { label: "3-4", value:"3-4"},
      { label: "4-5", value:"4-5"},
      { label: "5-7", value:"5-7"},
      { label: "7-10", value:"7-10"},
      { label: "+10", value:"+10"}
    ]
  }
]

const propRisk = [
  {
    options:[
      { label: "Low Risk", value:"Low Risk"},
      { label: "Medium Risk", value:"Medium Risk"},
      { label: "High Risk", value:"High Risk"},
     
    ]
  }
]

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Collapse,
  FormGroup,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  Form,
  Input,
  
} from "reactstrap"
import profileImg from "../../assets/images/profile-img.png"
import classnames from "classnames"
import { AvField, AvForm } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

class calCulator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
      
    }

    
  }
 
  render() {
   
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Create Listing | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Buyer")}
              breadcrumbItem={this.props.t("Mortgage Calculator")}
            />
            <Row>
                <Col sm="12">
                    <Card>
                        <CardBody>
                        <div><div className="widget" data-widget="calc-payment" data-lang="en"></div><div>  <a href="https://www.ratehub.ca/"><img src="https://www.ratehub.ca/assets/images/widget-logo.png" alt="Ratehub.ca logo" /></a></div></div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
calCulator.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(calCulator))
