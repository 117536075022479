import { GET_CLIENTS_MESSAGE, GET_CLIENTS_MESSAGE_FAIL, GET_CLIENTS_MESSAGE_SUCCESS, POST_CLIENT_MESSAGE_FAIL, POST_CLIENT_MESSAGE_SUCCESS } from "./actionTypes"

const initialState = {

  error: "",
  loading: true,
  messages: [],
}

const Messages = (state = initialState, action) => {
  switch (action.type) {

    case POST_CLIENT_MESSAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case POST_CLIENT_MESSAGE_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
      case GET_CLIENTS_MESSAGE:
        return {
          ...state,
          loading: true,
        }
    case GET_CLIENTS_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: action.payload,
        loading: false,
      }
    case GET_CLIENTS_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      state = { ...state }
      break

  }
  return state
}

export default Messages
