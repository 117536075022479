import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify";

import { DELETE_CLIENTS_DOCUMENT, GET_CLIENTS_DOCUMENT, POST_CLIENT_DOCUMENT, UPDATE_CLIENTS_DOCUMENT } from "./actionTypes"

import { deleteClientDocumentAPI, getClientDocumentAPI, postClientDocumentAPI, updateClientDocumnetAPI } from '../../helpers/backend_helper'

import { deleteClientDocumentFail, deleteClientDocumentSuccess, getClientDocumentFail, getClientDocumentSuccess, postClientDocumentFail, postClientDocumentSuccess, updateClientDocumentError } from "./actions"
import { take } from "lodash";


function* submitClientDocument({ payload: { document, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postClientDocumentAPI, {
      documentFromId: parseInt(document.documentFromId),
      documentToId: parseInt(document.documentToId),
      documentTitle: document.documentTitle,
      documentFrom: document.documentFrom,
      documentTo: document.documentTo,
      documentLink: document.documentLink,
      documentContent: document.documentContent,
      documentTypeId: 1,
      agentId: parseInt(document.agentId),
      isUrgent:(document.isUrgent==1) ? true : false

    })
    yield put(postClientDocumentSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Document Added");
      const response = yield call(getClientDocumentAPI, parseInt(document.documentFromId))
      yield put(getClientDocumentSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(postClientDocumentFail(error))
  }
}

function* fetchClientDocument({ clientId }) {
  try {
    const response = yield call(getClientDocumentAPI, clientId)
    yield put(getClientDocumentSuccess(response))
  } catch (error) {
    yield put(getClientDocumentFail(error))
  }
}

function* onDeleteClientDocumet({ payload: document }) {
  //console.log(document);
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteClientDocumentAPI, document.id)
    yield put(deleteClientDocumentSuccess(response))
    toast.dismiss();
    toast.success("Document Deleted");
    const res = yield call(getClientDocumentAPI, document.documentFromId)
    yield put(getClientDocumentSuccess(res))
  } catch (error) {
    yield put(deleteClientDocumentFail(error))
  }
}

function* onClientDocumentUpdate({ payload: document }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updateClientDocumnetAPI, document)
    toast.dismiss();
    toast.success("Document Updated");
    const res = yield call(getClientDocumentAPI, document.documentFromId)
    yield put(getClientDocumentSuccess(res))   
  } catch (error) {
    yield put(updateClientDocumentError(error))
  }
}



function* documentSaga() {

  yield takeEvery(POST_CLIENT_DOCUMENT, submitClientDocument)
  yield takeEvery(GET_CLIENTS_DOCUMENT, fetchClientDocument)
  yield takeEvery(DELETE_CLIENTS_DOCUMENT, onDeleteClientDocumet)
  yield takeEvery(UPDATE_CLIENTS_DOCUMENT, onClientDocumentUpdate)
 

}


export default documentSaga
