import { CREATE_CLIENT_ADD_LISTING, CREATE_CLIENT_ADD_LISTING_FAIL, CREATE_CLIENT_ADD_LISTING_SUCCESS, GET_CLIENT_ADD_LISTING, GET_CLIENT_ADD_LISTING_FAIL, GET_CLIENT_ADD_LISTING_SUCCESS } from "./actionTypes"

export const addClientAddListing = (listing, history) => ({
  type: CREATE_CLIENT_ADD_LISTING,
  payload: { listing, history }
})

export const addClientAddListingSuccess = listing => ({
  type: CREATE_CLIENT_ADD_LISTING_SUCCESS,
  payload: listing
})

export const addClientAddListingFail = error => ({
  type: CREATE_CLIENT_ADD_LISTING_FAIL,
  payload: error
})


export const getClientAddListing = (clientId,agentId) => (
  {
    type: GET_CLIENT_ADD_LISTING,
    payload: { clientId, agentId }
  })

export const getClientAddListingSuccess = addListings => ({
  type: GET_CLIENT_ADD_LISTING_SUCCESS,
  payload: addListings,
})

export const getClientAddListingFail = error => ({
  type: GET_CLIENT_ADD_LISTING_FAIL,
  payload: error,
})


