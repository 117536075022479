import { GET_CLIENT_RECENT_ACTIVITIES, GET_CLIENT_RECENT_ACTIVITIES_FAIL, GET_CLIENT_RECENT_ACTIVITIES_SUCCESS, GET_CLIENT_UPCOMING_ACTIVITIES, GET_CLIENT_UPCOMING_ACTIVITIES_FAIL, GET_CLIENT_UPCOMING_ACTIVITIES_SUCCESS } from "./actionTypes"


export const getClientRecentActivities = (clientId, agentId) => (
    {
      type: GET_CLIENT_RECENT_ACTIVITIES,
      payload: { clientId, agentId }
    })
  
  export const getClientRecentActivitiesSuccess = ractivities => ({
    type: GET_CLIENT_RECENT_ACTIVITIES_SUCCESS,
    payload: ractivities,
  })
  
  export const getClientRecentActivitiesFail = error => ({
    type: GET_CLIENT_RECENT_ACTIVITIES_FAIL,
    payload: error,
  })


  export const getClientUpcomingActivities = (clientId, agentId) => (
    {
      type: GET_CLIENT_UPCOMING_ACTIVITIES,
      payload: { clientId, agentId }
    })
  
  export const getClientUpcomingActivitiesSuccess = uactivities => ({
    type: GET_CLIENT_UPCOMING_ACTIVITIES_SUCCESS,
    payload: uactivities,
  })
  
  export const getClientUpcomingActivitiesFail = error => ({
    type: GET_CLIENT_UPCOMING_ACTIVITIES_FAIL,
    payload: error,
  })