import { GET_CLIENTS_MESSAGE, GET_CLIENTS_MESSAGE_FAIL, GET_CLIENTS_MESSAGE_SUCCESS, POST_CLIENT_MESSAGE, POST_CLIENT_MESSAGE_FAIL, POST_CLIENT_MESSAGE_SUCCESS } from "./actionTypes"


export const postClientMessage = (message, history) => ({
  type: POST_CLIENT_MESSAGE,
  payload: { message, history }
})

export const postClientMessageSuccess = message => ({
  type: POST_CLIENT_MESSAGE_SUCCESS,
  payload: message
})

export const postClientMessageFail = error => ({
  type: POST_CLIENT_MESSAGE_FAIL,
  payload: error
})


export const getClientMessage = clientId => (
  {
    type: GET_CLIENTS_MESSAGE,
    clientId,
  })

export const getClientMessageSuccess = messages => ({
  type: GET_CLIENTS_MESSAGE_SUCCESS,
  payload: messages,
})

export const getClientMessageFail = error => ({
  type: GET_CLIENTS_MESSAGE_FAIL,
  payload: error,
})
