import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify";

import { CREATE_CLIENT_APPOINTMENT, DELETE_CLIENT_APPOINTMENT, GET_CLIENTS_APPOINTMENT, UPDATE_CLIENT_APPOINTMENT } from "./actionTypes"

import { deleteClientAppointmentAPI, getClientAppointment, postClientAppointment, updateClientAppointmentAPI } from '../../helpers/backend_helper'

import { addClientAppointmentFail, addClientAppointmentSuccess, deleteClientAppointmentFail, deleteClientAppointmentSuccess, getClientAppointmentFail, getClientAppointmentSuccess, updateClientAppointmentError } from "./actions"
import { take } from "lodash";

function* submitClientAppointment({ payload: { appointment, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postClientAppointment, {
      appointmentTitle: appointment.appointmentTitle,
      contactName: 'Mohit',
      contactPhone: '1234567890',
      contactEmail: 'mohit@esimplified.ca',
      appointmentStartDate: appointment.appointmentStartDate,
      startTime: appointment.startTime,
      endTime: appointment.endTime,
      appointmentType: parseInt(appointment.appointmentType),
      appointmentPriority: parseInt(appointment.appointmentPriority),
      appointmentLocationType:parseInt(appointment.appointmentLocationType),
      appointmentLocation:appointment.appointmentLocation,
      clientId: parseInt(appointment.clientId),
      agentId: parseInt(appointment.agentId),
      addedBy: 'client'
      

    })
    yield put(addClientAppointmentSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Appointement Added");
      const response = yield call(getClientAppointment,  appointment.agentId, appointment.clientId )
    yield put(getClientAppointmentSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientAppointmentFail(error))
  }
}

function* onDeleteClientAppointment({ payload: appointment }) {
  //console.log(document);
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteClientAppointmentAPI, appointment.id)
    yield put(deleteClientAppointmentSuccess(response))
    toast.dismiss();
    const res = yield call(getClientAppointment, appointment.agentId, appointment.clientId)
    yield put(getClientAppointmentSuccess(res))
  } catch (error) {
    yield put(deleteClientAppointmentFail(error))
  }
}

function* fetchClientAppointment({ payload: { agentId, clientId }  }) {
  try {
    const response = yield call(getClientAppointment,  agentId, clientId )
    yield put(getClientAppointmentSuccess(response))
  } catch (error) {
    yield put(getClientAppointmentFail(error))
  }
}

function* onClientAppointmentUpdate({ payload: appointment }) {
  try {
    const response = yield call(updateClientAppointmentAPI, appointment)
    const res = yield call(getClientAppointment, appointment.agentId, appointment.clientId)
    yield put(getClientAppointmentSuccess(res))
    //yield put(updateClientAppointmentSuccess(response))
  } catch (error) {
    yield put(updateClientAppointmentError(error))
  }
}







function* appointmentaga() {
  
  yield takeEvery(CREATE_CLIENT_APPOINTMENT, submitClientAppointment)
  yield takeEvery(GET_CLIENTS_APPOINTMENT, fetchClientAppointment)
  yield takeEvery(DELETE_CLIENT_APPOINTMENT, onDeleteClientAppointment)
  yield takeEvery(UPDATE_CLIENT_APPOINTMENT, onClientAppointmentUpdate)

}


export default appointmentaga
