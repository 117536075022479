import { CREATE_CLIENT_APPOINTMENT, CREATE_CLIENT_APPOINTMENT_FAIL, 
  CREATE_CLIENT_APPOINTMENT_SUCCESS, DELETE_CLIENT_APPOINTMENT, DELETE_CLIENT_APPOINTMENT_FAIL, DELETE_CLIENT_APPOINTMENT_SUCCESS, GET_CLIENTS_APPOINTMENT, 
  GET_CLIENTS_APPOINTMENT_FAIL, GET_CLIENTS_APPOINTMENT_SUCCESS, UPDATE_CLIENT_APPOINTMENT, UPDATE_CLIENT_APPOINTMENT_FAIL, UPDATE_CLIENT_APPOINTMENT_SUCCESS } from "./actionTypes"

export const addClientAppointment = (appointment, history) => ({
  type: CREATE_CLIENT_APPOINTMENT,
  payload: { appointment, history }
})

export const addClientAppointmentSuccess = appointment => ({
  type: CREATE_CLIENT_APPOINTMENT_SUCCESS,
  payload: appointment
})

export const addClientAppointmentFail = error => ({
  type: CREATE_CLIENT_APPOINTMENT_FAIL,
  payload: error
})


export const getClientAppointment = (agentId,clientId) => (
  {
    type: GET_CLIENTS_APPOINTMENT,
    payload: { agentId, clientId }
  })

export const getClientAppointmentSuccess = appointments => ({
  type: GET_CLIENTS_APPOINTMENT_SUCCESS,
  payload: appointments,
})

export const getClientAppointmentFail = error => ({
  type: GET_CLIENTS_APPOINTMENT_FAIL,
  payload: error,
})


export const deleteClientAppointment = id => ({
  type: DELETE_CLIENT_APPOINTMENT,
  payload: id,
})

export const deleteClientAppointmentSuccess = appointment => ({
  type: DELETE_CLIENT_APPOINTMENT_SUCCESS,
  payload: appointment,
})

export const deleteClientAppointmentFail = error => ({
  type: DELETE_CLIENT_APPOINTMENT_FAIL,
  payload: error,
})


export const updateClientAppointment = appointment => {
  //console.log(appointment)
  return {
    type: UPDATE_CLIENT_APPOINTMENT,
    payload: appointment,
  }
}

export const updateClientAppointmentSuccess = appointment => {
  return {
    type: UPDATE_CLIENT_APPOINTMENT_SUCCESS,
    payload: appointment,
  }
}

export const updateClientAppointmentError = error => {
  return {
    type: UPDATE_CLIENT_APPOINTMENT_FAIL,
    payload: error,
  }
}