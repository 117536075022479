import React from "react"
import { Redirect } from "react-router-dom"
// User profile
import UserProfile from "../pages/Authentication/UserProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"




// Dashboard
import Dashboard from "../pages/Dashboard/index"

import Project from "../pages/Dashboard/project"
import ProjectDetail from "../pages/Dashboard/project-detail"
import ProjectComapre from "../pages/Dashboard/project-compare"
import MlsComapre from "../pages/Dashboard/project-comparemls"
import myProject from "../pages/Dashboard/myproject"
import myTransection from "../pages/Dashboard/mytransection"


import clientApp from "../pages/Clients/client-app"
import ClientDocument from "../pages/Clients/client-documents"
import ClientMessage from "../pages/Clients/client-message"
import preFrence from "../pages/Prefrences/index"
import myListing from "../pages/Prefrences/mylisings"
import createListing from "../pages/Prefrences/createlisting"
import calCulator from "../pages/Prefrences/calculator"
import mySource from "../pages/Prefrences/mysource"
import SellerShowing from "../pages/Prefrences/sellershowing"

import MyLawyer from "../pages/Clients/mylawyer"

import addListing from "../pages/Prefrences/add-listing"
import VisitedAddListing from "../pages/Prefrences/visited-add-listing"
import receivedAddListing from "../pages/Prefrences/received-add-listing"
import sentAddListing from "../pages/Prefrences/sent-add-listing"

import clientProfile from "../pages/Clients/client-profile"

import clientProfileNew from "../pages/Clients/client-profile-new"

import generalSetting from "../pages/Clients/general-setting"

import memberInfo from "../pages/Clients/member-info"


import homePref from "../pages/Prefrences/home-pref"
import impDates from "../pages/Prefrences/important-destinations"
import sellersChecklist from "pages/Prefrences/sellersChecklist"
import buyersChecklist from "pages/Prefrences/buyersChecklist"
import manageListing from "pages/Prefrences/manage-listing"
import comapreMatch from "pages/Prefrences/comapre-match"
import comapreNotMatch from "pages/Prefrences/comapre-not-match"
import comapreNiceToHave from "pages/Prefrences/comapre-nice-to-have"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
 
  { path:"/appointment", component: clientApp },
  { path:"/documents", component: ClientDocument },
  { path:"/messages", component: ClientMessage },
  { path:"/project", component: Project },
 //{ path:"/project-detail", component: ProjectDetail },
  { path: "/project-detail/:id/:projectId", component: ProjectDetail },
  { path:"/project-compare", component: ProjectComapre },
  { path:"/my-interests", component: myProject },
  { path:"/my-transection", component: myTransection },
  { path:"/my-compare", component: MlsComapre },

  { path:"/seller-showings", component: SellerShowing },

  { path:"/buyer-prefrences", component: preFrence },
  { path:"/prefrences/home-prefrences", component: homePref },
  { path:"/prefrences/important-destinations", component: impDates },
  
  { path:"/my-listing", component: myListing },
  { path:"/create-listing", component: createListing },
  { path:"/professional-services", component: mySource },
  { path:"/calculator", component: calCulator },
  { path:"/my-lawyers", component: MyLawyer },
  { path:"/profile", component: clientProfile },
  { path:"/client-profile", component: clientProfileNew },
  { path:"/general-setting", component: generalSetting },
  { path:"/membership", component: memberInfo },

  { path:"/add-listing", component: addListing },
  { path:"/sent-add-listing", component: sentAddListing },
  { path:"/received-add-listing", component: receivedAddListing },
  { path:"/visited-add-listing", component: VisitedAddListing },

  { path:"/prefrences/sellers-checklist", component: sellersChecklist },
  { path:"/sellers-checklist", component: sellersChecklist },
  { path:"/buyers-checklist", component: buyersChecklist },
  { path:"/manage-listing", component: manageListing },
  { path:"/compare-not-match", component: comapreNotMatch },
  { path:"/compare-match", component: comapreMatch },
  { path:"/compare-nice-to-have", component: comapreNiceToHave },


 
  
 // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
 
]

export { authProtectedRoutes, publicRoutes }
